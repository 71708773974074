import { useLocation } from 'react-router-dom'
import { Card, Typography } from 'antd'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { isNil } from 'lodash'
import styled from 'styled-components'

import { Button } from 'src/common'
import { ExperienceSection } from './ExperienceSection'
import {
  TWorkExperience,
  TWorkExperiences,
} from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'
import { PATH_UPDATE_APPLICATION } from '@configs'

export const WorkExperiences = ({ isViewMode }: TWorkExperiences) => {
  const { pathname } = useLocation()
  const isUpdatingApplication = [PATH_UPDATE_APPLICATION].includes(pathname)

  const { control, getValues, setValue } = useFormContext()

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'specificInformation.workExperience',
    rules: {
      minLength: 1,
      maxLength: 5,
    },
  })
  const removableField = fields?.length > 1 && !isViewMode
  const [isAddableField, setAddableField] = React.useState<boolean>(false)

  const verifyAddableField = () => {
    const maxFieldsLength = 5
    const workExperiences = getValues(`specificInformation.workExperience`)
    const areAllFieldsFilled = workExperiences.every((obj: any) => {
      return Object.values(obj).every((value) => !isNil(value) && value !== '')
    })
    return !!areAllFieldsFilled && workExperiences?.length < maxFieldsLength
  }

  const handleRemoveField = (id?: number) => {
    remove(id)
    setAddableField(verifyAddableField())
  }

  const handleSaveField = (id?: number) => {
    if (isNil(id)) {
      return
    }
    // NOTE:  useFieldArray cannot auto update fields value, you should do it by your hand
    update(id, getValues(`specificInformation.workExperience[${id}]`))
    setAddableField(verifyAddableField())
  }

  const handleAddField = () => {
    const defaultFieldValue: TWorkExperience = {
      companyName: '',
      jobTitle: '',
      description: '',
      startDate: '',
      endDate: '',
      editable: true,
    }
    append(defaultFieldValue)
    setAddableField(false)
  }

  const handleEitField = (id?: number) => {
    if (isNil(id)) {
      return
    }
    setValue(`specificInformation.workExperience[${id}].editable`, true)
    update(id, getValues(`specificInformation.workExperience[${id}]`))
  }

  useEffect(() => {
    if (isUpdatingApplication) {
      setAddableField(true)
    }
  }, [isUpdatingApplication])

  return (
    <WorkExperiencesStyled className="flex items-center justify-center w-full">
      <Typography.Title level={4} className="text-left">
        Organization
      </Typography.Title>
      {fields?.map((field: any, index) => {
        return (
          <ExperienceSection
            key={field.id}
            data={field}
            index={index}
            onRemove={removableField ? handleRemoveField : undefined}
            onSave={handleSaveField}
            onEdit={isViewMode ? undefined : handleEitField}
          />
        )
      })}
      {isAddableField && (
        <div className="flex items-center max-[800px] justify-center my-5">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className="min-[600px] max-[600px]:!w-full"
            onClick={handleAddField}
          >
            {t('common:addExperience')}
          </Button>
        </div>
      )}
    </WorkExperiencesStyled>
  )
}

const WorkExperiencesStyled = styled(Card)`
  .ant-card-body {
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
