import { memo, useEffect, useRef, useState } from 'react'
import { t } from 'i18next'
import { message, Skeleton, Typography } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { AppModal } from '@components'
import { REASON_DETAIL_DEFAULT_VALUES, reasonDetailSchema } from './configs'
import { Button, HookFormTextArea, Input } from 'src/common'
import { ButtonWrapper } from 'src/pages/teacher-request-detail/fill-in-teacher-information/style'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from 'src/components/gallery/SelectMedia'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { IReasonDetailInputParams } from '@interfaces'
import {
  createReasonDetailAction,
  editReasonDetailByIdAction,
  getReasonDetailByIdAction,
  getWhyUsTableAction,
  RootState,
  teacherLandingPageActions,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { CONTENT_MANAGEMENT_VALIDATION } from '@configs'

interface IProps {
  reasonId?: number | string
  open: boolean
  onClose: () => void
}

export const ReasonDetailModal = memo(({ open, onClose, reasonId }: IProps) => {
  const dispatch = useAppDispatch()

  const { reasonDetail, loadings } = useAppSelector(
    (state: RootState) => state.teacherLanding
  )
  const isSubmitting =
    !!loadings['createReasonDetailAction'] ||
    !!loadings['editReasonDetailByIdAction']
  const isGettingReasonDetailById = loadings['getReasonDetailByIdAction']

  const [isModalLoading, setIsModalLoading] = useState(false)
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const [imageSrc, setImageSrc] = useState('')
  const [imageMobileSrc, setImageMobileSrc] = useState('')
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<IReasonDetailInputParams>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      resolver: zodResolver(reasonDetailSchema),
      defaultValues: REASON_DETAIL_DEFAULT_VALUES,
    })

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
      setImageSrc(data?.baseUrl ?? '')
    } else if (selectedField === 'mobile') {
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
      setImageMobileSrc(data?.baseUrl ?? '')
    }

    setShowModalSelectMedia(false)
  }

  const setReasonDetailToForm = async () => {
    if (!!Object.keys(reasonDetail ?? {})?.length) {
      reset({ ...reasonDetail })
      setImageSrc(reasonDetail?.media?.url ?? '')
      setImageMobileSrc(reasonDetail?.mediaMobile?.url ?? '')
    }
  }

  const handleSubmitReasonDetail = async (params: IReasonDetailInputParams) => {
    try {
      const res = !!reasonId
        ? await dispatch(
            editReasonDetailByIdAction({
              id: reasonId,
              payload: params,
            })
          ).unwrap()
        : await dispatch(createReasonDetailAction(params)).unwrap()

      if (res) {
        message.success({
          content: res?.message ?? t('common:save_success'),
        })
        dispatch(getWhyUsTableAction())
      }
    } catch (error: any) {
      message.error(error?.message)
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    if (!reasonId) return

    dispatch(getReasonDetailByIdAction(reasonId))

    return () => {
      dispatch(teacherLandingPageActions.clearReasonDetail())
    }
  }, [reasonId, dispatch])

  useDeepCompareEffect(() => {
    setReasonDetailToForm()
  }, [dispatch, reasonDetail])

  return (
    <>
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.CONTENT}
            onSelectMedia={onSelectMedia}
            modalClassName="!z-[110]"
          />
        </>
      ) : null}
      <AppModal
        open={open}
        onClose={onClose}
        haveCloseIcon
        title={
          <Typography.Title level={3} className="!m-0 !text-[32px]">
            {t('teacher:reason_detail')}
          </Typography.Title>
        }
        contentClassName="!min-w-[900px]"
      >
        {!!isGettingReasonDetailById ? (
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        ) : (
          <ReasonDetailStyled>
            <Controller
              name="no"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    placeholder="No"
                    type="number"
                    value={value}
                    onChange={(e) => {
                      e.target.value
                        ? onChange(parseFloat(e.target.value))
                        : onChange(0)
                    }}
                    label="No"
                    alignment="col"
                    errors={error?.message}
                    required
                    min={1}
                  />
                )
              }}
            />
            <div className="col-span-1"></div>
            <Controller
              name="title"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    placeholder="Title"
                    value={value}
                    onChange={onChange}
                    label="Title"
                    alignment="col"
                    errors={error?.message}
                    required
                  />
                )
              }}
            />
            <Controller
              name="titleInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    placeholder="Title in Netherlands"
                    value={value}
                    onChange={onChange}
                    label="Title in Netherlands"
                    alignment="col"
                    errors={error?.message}
                    required
                  />
                )
              }}
            />

            <HookFormTextArea
              control={control}
              placeholder="Description"
              controlName="mediaDescription"
              label="Description"
              rows={5}
              required
            />

            <HookFormTextArea
              control={control}
              placeholder="Description in Netherlands"
              controlName="mediaDescriptionInDutch"
              label="Description in Netherlands"
              rows={5}
              required
            />

            <MediaUploaderStyled>
              <Controller
                name="mediaId"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => {
                  onChangeDesktopRef.current = onChange
                  return (
                    <CustomDragger
                      label="Image Web"
                      name="mediaId"
                      id="mediaId"
                      containerClassName="!w-full mt-3"
                      labelClassName="!w-full"
                      alignment="col"
                      required
                      onLoadEnd={(data) => {
                        setValue('mediaId', data?.id ?? 0)
                        onChange(data?.id ?? 0)
                        setImageSrc((prev) => data?.original ?? prev)
                      }}
                      initResource={imageSrc}
                      errors={error?.message}
                      reset={getValues('mediaId') === 0}
                      limitFileSize={
                        CONTENT_MANAGEMENT_VALIDATION.MAX_IMAGE_SIZE
                      }
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      changeLoading={setIsModalLoading}
                      uploadType={EMediaType.IMAGE as any}
                      uploadCategory={EMediaCategory.CONTENT}
                    />
                  )
                }}
              />
              <SelectMedia
                isNotGrid
                buttonClassName="!text-blue-500"
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'desktop')
                  setShowModalSelectMedia(true)
                }}
                isLoadingMedia={isModalLoading}
              />
            </MediaUploaderStyled>

            <MediaUploaderStyled>
              <Controller
                name={'mediaMobileId'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  onChangeMobileRef.current = onChange
                  return (
                    <CustomDragger
                      label="Image mobile"
                      name="mediaMobileId"
                      id="mediaMobileId"
                      containerClassName="!w-full mt-3"
                      labelClassName="!w-full"
                      alignment="col"
                      onLoadEnd={(data) => {
                        setValue('mediaMobileId', data?.id ?? 0)
                        onChange(data?.id ?? 0)
                        setImageMobileSrc((prev) => data?.original ?? prev)
                      }}
                      required
                      initResource={imageMobileSrc}
                      errors={error?.message}
                      multiple={false}
                      reset={getValues('mediaMobileId') === 0}
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      limitFileSize={
                        CONTENT_MANAGEMENT_VALIDATION.MAX_IMAGE_SIZE
                      }
                      changeLoading={setIsModalLoading}
                      uploadType={EMediaType.IMAGE as any}
                      uploadCategory={EMediaCategory.CONTENT}
                    />
                  )
                }}
              />
              <SelectMedia
                isNotGrid
                buttonClassName="!text-blue-500"
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'mobile')
                  setShowModalSelectMedia(true)
                }}
                isLoadingMedia={isModalLoading}
              />
            </MediaUploaderStyled>

            <ButtonWrapper className="w-full col-span-2 !justify-center !items-center">
              <Button
                size="small"
                className="!text-[16px] !text-white !bg-gray-400 !border-none"
                onClick={onClose}
              >
                {t('common:cancel')}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                size="small"
                className="!text-[16px] !text-white !border-none"
                onClick={handleSubmit(handleSubmitReasonDetail)}
                loading={isModalLoading || isSubmitting}
              >
                {t('common:save')}
              </Button>
            </ButtonWrapper>
          </ReasonDetailStyled>
        )}
      </AppModal>
    </>
  )
})

const ReasonDetailStyled = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
`
const MediaUploaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
`
