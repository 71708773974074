import { EUploadFileType, HOME_PAGE_VIDEO, thunkActionLoading } from '@configs'
import { RootState, selectContentLoading, useAppDispatch } from '@redux'
import { Card, message } from 'antd'
import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { useSelector } from 'react-redux'
import { Button, Loading } from 'src/common'
import CustomUploadButton from 'src/common/upload/CustomUploadButton'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import {
  getHomepageVideo,
  updateHomepageVideo,
} from 'src/redux/actions/content-management'

interface IHomePageVideoProps {
  activeTab?: string
}

export const HomePageVideo = ({ activeTab }: IHomePageVideoProps) => {
  const { homepageVideo } = useSelector((state: RootState) => state.content)
  const getListRoadMapLoading = useSelector((state: RootState) =>
    selectContentLoading(
      state,
      thunkActionLoading.GET_LIST_HOMEPAGE_VIDEO_LOADING
    )
  )
  const updateHomepageLoading = useSelector((state: RootState) =>
    selectContentLoading(
      state,
      thunkActionLoading.UPDATE_HOMEPAGE_VIDEO_LOADING
    )
  )

  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)

  const [resource, setRource] = useState(homepageVideo?.media?.url ?? '')
  const [resourceMobile, setRourceMobile] = useState(
    homepageVideo?.mediaMobile?.url ?? ''
  )
  const mediaId = useRef(homepageVideo?.mediaId ?? 0)
  const mediaMobileId = useRef(homepageVideo?.mediaMobileId ?? 0)
  const [isChange, setIsChange] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showResetModal, setShowResetModal] = useState(false)

  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [showModalSelectMediaMobile, setShowModalSelectMediaMobile] =
    useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)

  useEffect(() => {
    if (activeTab === HOME_PAGE_VIDEO) {
      dispatch(getHomepageVideo())
    }
  }, [activeTab])

  useEffect(() => {
    setRource(homepageVideo?.media?.url ?? '')
    setRourceMobile(homepageVideo?.mediaMobile?.url ?? '')
    mediaId.current = homepageVideo?.mediaId ?? 0
    mediaMobileId.current = homepageVideo?.mediaMobileId ?? 0
  }, [homepageVideo])

  const onCancelSave = () => {
    setShowConfirmModal(false)
    setShowResetModal(false)
  }

  const onConfirmSave = () => {
    onSave()
  }

  const onConfirmReset = () => {
    setIsChange(false)
    setRource(homepageVideo?.media?.url ?? '')
    setRourceMobile(homepageVideo?.mediaMobile?.url ?? '')
    mediaMobileId.current = homepageVideo?.mediaMobileId ?? 0
    setShowResetModal(false)
  }

  const onSave = async () => {
    try {
      const response = await dispatch(
        updateHomepageVideo({
          mediaId: mediaId.current,
          mediaMobileId: mediaMobileId.current,
        })
      ).unwrap()
      if (response?.homepageVideo) {
        message.success(response.message)
        setIsChange(false)
        setShowConfirmModal(false)
      }
      // dispatch(getListRoadMap())
    } catch (error: any) {
      message.error(error.message)
    }
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onCloseModalSelectMediaMobile = () => {
    setShowModalSelectMediaMobile(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      // setImageSrc(data?.baseUrl ?? '')
      setRource(data?.baseUrl ?? '')
      mediaId.current = data?.id ?? 0
      // setValue('mediaId', data?.id ?? 0)
      // onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      // setImageMobileSrc(data?.baseUrl ?? '')
      // setValue('mediaMobileId', data?.id ?? 0)
      // onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
    setIsChange(true)
  }

  const onSelectMediaMobile = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      // setImageSrc(data?.baseUrl ?? '')
      setRourceMobile(data?.baseUrl ?? '')
      mediaMobileId.current = data?.id ?? 0
      // setValue('mediaId', data?.id ?? 0)
      // onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      // setImageMobileSrc(data?.baseUrl ?? '')
      // setValue('mediaMobileId', data?.id ?? 0)
      // onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMediaMobile(false)
    setIsChange(true)
  }

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          marginTop: 24,
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        />
        <CustomUploadButton
          id="homepage-video"
          name="homepage-video"
          allowFileTypes={[
            'video/mp4',
            'video/webm',
            'video/m4v',
            'video/mov',
            'video/qt',
          ]}
          limitFileSize={500}
          changeLoading={setLoading}
          onLoadEnd={(data) => {
            setRource(data?.url ?? '')
            mediaId.current = data?.dataValues?.id
            setIsChange(true)
          }}
          multiple={false}
          uploadType={EUploadFileType.VIDEO}
          uploadCategory={EMediaCategory.HOME_PAGE}
        />

        <div className="text-right mt-4 font-bold">*Video normal</div>

        <SelectMedia
          onClickSelectMedia={() => {
            setSelectedField((pre) => 'desktop')
            setShowModalSelectMedia(true)
          }}
          isNotGrid={true}
          isLoadingMedia={loading}
        />
      </div>
      <div className="w-full h-[800] items-center justify-center flex rounded-s border-2 py-4 mt-5">
        {loading ? (
          <Loading
            wrapperClassName="h-full py-[50px]"
            style={{
              height: '300px',
              alignSelf: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          />
        ) : (
          <ReactPlayer
            url={resource}
            loop
            controls={true}
            style={{
              width: '100%',
            }}
          />
        )}
      </div>
      {/* --------## Video and Video mobile ##-------- */}
      <div
        style={{
          display: 'flex',
          marginTop: 24,
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        />
        <CustomUploadButton
          id="homepage-video-mobile"
          name="homepage-video-mobile"
          allowFileTypes={[
            'video/mp4',
            'video/webm',
            'video/m4v',
            'video/mov',
            'video/qt',
          ]}
          limitFileSize={500}
          changeLoading={setLoading2}
          onLoadEnd={(data) => {
            setRourceMobile(data?.url ?? '')
            mediaMobileId.current = data?.dataValues?.id
            setIsChange(true)
          }}
          multiple={false}
          uploadType={EUploadFileType.VIDEO}
          uploadCategory={EMediaCategory.HOME_PAGE}
        />

        <div className="text-right mt-4 font-bold">*Video mobile</div>

        <SelectMedia
          onClickSelectMedia={() => {
            setSelectedField((pre) => 'desktop')
            setShowModalSelectMediaMobile(true)
          }}
          isNotGrid={true}
          isLoadingMedia={loading2}
        />
      </div>
      <div className="w-full h-[800] items-center justify-center flex rounded-s border-2 py-4 mt-5">
        {loading2 ? (
          <Loading
            wrapperClassName="h-full py-[50px]"
            style={{
              height: '300px',
              alignSelf: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          />
        ) : (
          <ReactPlayer
            url={resourceMobile}
            loop
            controls={true}
            style={{
              width: '100%',
            }}
          />
        )}
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 24,
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        />
        {/* <Button
          htmlType="submit"
          size="middle"
          className="submit__btn loginselected-btn"
          style={{
            alignSelf: 'flex-end',
            marginRight: 24,
          }}
          disabled={
            loading ||
            updateHomepageLoading ||
            getListRoadMapLoading ||
            !isChange
          }
          onClick={() => {
            setShowResetModal(true)
          }}
        >
          {'Reset'}
        </Button> */}
        <Button
          htmlType="submit"
          type="primary"
          size="middle"
          className="submit__btn loginselected-btn"
          style={{
            alignSelf: 'flex-end',
          }}
          loading={
            loading ||
            updateHomepageLoading ||
            getListRoadMapLoading ||
            loading2
          }
          disabled={
            loading ||
            updateHomepageLoading ||
            getListRoadMapLoading ||
            !isChange
          }
          onClick={() => {
            setShowConfirmModal(true)
          }}
        >
          {'Save'}
        </Button>
      </div>
      <ConfirmDeleteModal
        title="Apply community"
        content="Are you sure you want to apply this video to homepage?"
        open={showConfirmModal}
        isLoading={!!loading}
        onDelete={onConfirmSave}
        onClose={onCancelSave}
      />
      <ConfirmDeleteModal
        title="Reset data"
        content="Are you sure you want to discard all your change?"
        open={showResetModal}
        isLoading={!!loading}
        onDelete={onConfirmReset}
        onClose={onCancelSave}
      />
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.VIDEO}
            category={EMediaCategory.HOME_PAGE}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}

      {showModalSelectMediaMobile ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMediaMobile}
            onClose={onCloseModalSelectMediaMobile}
            mediaType={EMediaType.VIDEO}
            category={EMediaCategory.HOME_PAGE}
            onSelectMedia={onSelectMediaMobile}
          />
        </>
      ) : (
        <></>
      )}
    </Card>
  )
}
