import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { ShareSelectInput } from '@components'
import {
  EUserPostStatus,
  PATH_GROUP_CREATE,
  PATH_GROUP_DETAIL,
  PATH_GROUP_MANAGEMENT,
  PATH_POST_CREATE,
  PATH_POST_MANAGEMENT,
  PATH_SUB_HABIT_QUESTION_DETAIL,
  POST_DISPLAY_NAME,
  enumTableTitleGroupUserManagement,
  thunkActionLoading,
} from '@configs'
import { ICategory } from '@interfaces'
import {
  RootState,
  getAllCategoriesAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Card, Skeleton, Tag, Tooltip, message } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Input, Loading, SharedTable, Text } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import {
  IGroupUser,
  TGetGroupUserParams,
} from 'src/interfaces/group-user-management'
import {
  TGetPostParams,
  TPostDetail,
  TTag,
} from 'src/interfaces/post-management'
import {
  deleteGroupUserAction,
  getGroupUsersAction,
} from 'src/redux/actions/group-user-management'
import {
  deletePostAction,
  getPostsAction,
} from 'src/redux/actions/post-management'

type Props = {}

const GroupUserPage = (props: Props) => {
  const [isModalLoading, setIsModalLoading] = useState(false)

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)

  const { t } = useTranslation(['error', 'common'])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [deletedGroupUser, setDeletedGroupUser] = useState<IGroupUser>()

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState<boolean | undefined>(undefined)

  const debouncedValue = useDebounce<string | null>(searchText, 500)
  const [isSearching, setIsSearching] = useState(false)

  const { groupUser } = useAppSelector((state: RootState) => state.groupUser)

  const getGroupUsersLoading = useAppSelector(
    (state: RootState) =>
      state.groupUser.loadings[thunkActionLoading.GET_GROUP_USERS_LOADING]
  )

  const getGroupUsers = async (params?: TGetGroupUserParams) => {
    dispatch(
      getGroupUsersAction({
        page: page,
        limit: 10,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        status: status,
        ...(params || {}),
      })
    )
  }

  const getGroupUsersBySearchValue = async () => {
    try {
      setIsSearching(true)
      await dispatch(
        getGroupUsersAction({
          page: 1,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
          status: status,
        })
      ).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setIsSearching(false)
    }
  }

  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleGroupUserManagement.NO}</p>
          </div>
        )
      },
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '5%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: false,
    },
    {
      title: enumTableTitleGroupUserManagement.NAME,
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      // width: '35%',
    },
    {
      title: enumTableTitleGroupUserManagement.DESCRIPTION,
      dataIndex: 'description',
      key: 'description',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      // width: '35%',
    },

    {
      title: enumTableTitleGroupUserManagement.STATUS,
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
    {
      title: enumTableTitleGroupUserManagement.ACTION,
      key: 'record',
      dataIndex: 'record',
      width: '15%',
      align: 'center',
      render: (record: IGroupUser) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          {/* <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light cursor-pointer !text-[#184f64] hover:opacity-80"
              onClick={() => {
                navigate(`${PATH_POST_MANAGEMENT}/post-detail/${record?.id}`)
              }}
            />
          </Tooltip> */}

          <Tooltip title={t('common:edit')}>
            <EditOutlined
              className="text-lg font-light mr-2.5 cursor-pointer !text-[#184f64] hover:opacity-80"
              onClick={() => {
                // Edit action
                navigate(`${PATH_GROUP_MANAGEMENT}/group-detail/${record?.id}`)
              }}
            />
          </Tooltip>

          <Tooltip title={t('common:delete')}>
            <DeleteOutlined
              className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
              onClick={() => {
                setDeletedGroupUser(record)
                setOpenConfirmDeleteModal(true)
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  const statusOptions = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'Active',
      value: 1,
    },
    {
      label: 'Inactive',
      value: 0,
    },
  ]

  const onDeletePost = async () => {
    console.log('deletedGroupUser', deletedGroupUser)
    if (!deletedGroupUser || !deletedGroupUser?.id) return
    setIsModalLoading(true)
    try {
      const response = await dispatch(
        deleteGroupUserAction(deletedGroupUser?.id as number)
      ).unwrap()
      if (response?.success) message.success(response.message)
      getGroupUsers()
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setDeletedGroupUser(undefined)
      setOpenConfirmDeleteModal(false)
      setIsModalLoading(false)
    }
  }

  const onCloseDeleteModal = () => {
    setOpenConfirmDeleteModal(false)
    setDeletedGroupUser(undefined)
  }

  useEffect(() => {
    getGroupUsers({ page: 1, status: status })
  }, [])

  useEffect(() => {
    getGroupUsersBySearchValue()
  }, [debouncedValue])

  return (
    <Card>
      <div className="flex items-center justify-between py-[1.25rem] gap-5 w-full">
        <div className="max-w-[800px] flex-1 flex gap-8">
          <Input
            prefix={<SearchOutlined className="pl-2" />}
            placeholder="Search group"
            value={searchText === null ? '' : searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            suffix={debouncedValue && isSearching ? <Loading /> : undefined}
          />

          {/* Start filter */}

          <div className="filter-status flex justify-center items-center gap-1 relative">
            <div className="status-label">Status</div>
            <ShareSelectInput
              placeholder="Status"
              onChange={(value) => {
                if (value === 'All') {
                  setStatus(undefined)
                  getGroupUsers({ page: 1, status: undefined })
                } else {
                  setStatus(!!value)
                  getGroupUsers({
                    page: 1,
                    status: !!value,
                  })
                }
              }}
              showArrow
              data={statusOptions}
              style={{
                width: 105,
              }}
            />
          </div>
        </div>

        {/* ----Filter---- */}
        <div>
          <Button
            type="primary"
            onClick={() => {
              // Create new post action
              navigate(PATH_GROUP_CREATE)
            }}
          >
            New group user
          </Button>
        </div>
      </div>
      {getGroupUsersLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={
            groupUser?.groupUsers &&
            groupUser?.groupUsers?.map((item, index) => {
              return {
                ...item,
                key: item?.id,
                no: index + 1,
                name: item?.name ? item?.name : `--`,
                description: item?.description ? item?.description : `--`,
                status: item?.status === true ? 'Active' : 'Inactive',
                record: item,
              }
            })
          }
          sticky
          paginationProps={{
            total: groupUser?.total ?? 0,
            pageSize: 10,
            showSizeChanger: false,
            current: groupUser?.page ?? 1,
            onChange(page) {
              getGroupUsers({ page: page, status: status })
            },
          }}
        />
      )}

      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete this group user"
          content="Are you sure to delete this group user?"
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={onDeletePost}
          onClose={onCloseDeleteModal}
        />
      )}
    </Card>
  )
}

export default GroupUserPage
