import { EyeOutlined } from '@ant-design/icons'
import {
  CURRENT_TIMEZONE,
  EActivityLogActionType,
  EActivityLogType,
  EUserManagementTabs,
  INITIAL_PAGINATION_SiZE,
  LIST_USER_LOG_ACTION,
  enumTableTitleUserLog,
} from '@configs'
import { RouterParams } from '@interfaces'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Skeleton, Tooltip, message } from 'antd'
import { format } from 'date-fns'
import { t } from 'i18next'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, SharedTable } from 'src/common'
import DetailUserLogModal from 'src/components/user-log/DetailUserLogModal'
import { IFetchUserLogByUserIdParams } from 'src/interfaces/user-log'
import { selectUserLogLoading } from 'src/redux/Slices/user-log'
import { getUserLogByUserIdAction } from 'src/redux/actions/user-log'
import { dowloadFile } from 'src/utils/file'
import styled from 'styled-components'

interface IUserLogTabProps {
  activeTab?: string
}

const UserLogTab = ({ activeTab }: IUserLogTabProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [logId, setLogId] = useState<number | string>('')
  const [isDowloading, setIsDowloading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {
    userLogs,
    userLog,
    userLogTotalPage,
    userLogTotalItems,
    userLogCurrentPage,
    userLogLimit,
  } = useAppSelector((state: RootState) => state.userLog)

  const { userId } = useParams<RouterParams['UserDetail']>()

  const getUserLogByUserIdLoading = useAppSelector((state: RootState) =>
    selectUserLogLoading(state, 'getUserLogByUserIdAction')
  )

  const getUserLogs = async (payload: IFetchUserLogByUserIdParams) => {
    await dispatch(getUserLogByUserIdAction(payload))
  }

  const columns = [
    {
      width: '50%',
      title: enumTableTitleUserLog.TITLE,
      dataIndex: 'title',
      key: 'title',
      // sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      // sortDirections: ['ascend', 'descend'],
      // showSorterTooltip: false,
      ellipsis: true,
    },
    {
      width: '20%',
      title: enumTableTitleUserLog.TYPE,
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      // filters: [
      //   {
      //     text: LIST_USER_LOG_ACTION[EActivityLogType.ACTIVITY],
      //     value: EActivityLogType.ACTIVITY,
      //   },
      //   {
      //     text: LIST_USER_LOG_ACTION[EActivityLogType.SECURITY],
      //     value: EActivityLogType.SECURITY,
      //   },
      //   {
      //     text: LIST_USER_LOG_ACTION[EActivityLogType.TRANSACTION],
      //     value: EActivityLogType.TRANSACTION,
      //   },
      //   {
      //     text: LIST_USER_LOG_ACTION[EActivityLogType.ANALYTICS],
      //     value: EActivityLogType.ANALYTICS,
      //   },
      // ],
      // onFilter: (value: string, record: any) =>
      //   record.type.startsWith(LIST_USER_LOG_ACTION[value as EActivityLogType]),
      // filterSearch: true,
      // showSorterTooltip: false,
      ellipsis: true,
    },
    {
      width: '20%',
      title: enumTableTitleUserLog.TIME,
      dataIndex: 'time',
      key: 'time',
      align: 'center',
      render: (e: any) => {
        return (
          <div className="flex items-center justify-center">
            {moment(e).format('HH:mm:ss - DD MMM YYYY')}
          </div>
        )
      },
      // sorter: (a: any, b: any) => moment(a.time).unix() - moment(b.time).unix(),
      // sortDirections: ['ascend', 'descend'],
      // showSorterTooltip: false,
      ellipsis: true,
    },
    {
      width: '10%',
      title: enumTableTitleUserLog.ACTION,
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      render: (_: any, record: { id: any }) => (
        <div className="flex space-x-4 items-center justify-center">
          <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
              onClick={() => {
                setLogId(record.id)
                setOpenModal(true)
              }}
            />
          </Tooltip>
        </div>
      ),
      ellipsis: true,
    },
  ]

  const onClose = () => {
    setOpenModal(false)
  }

  const onDowloadFile = async () => {
    try {
      setIsDowloading(true)
      await dowloadFile(
        `activity-logs/admin/${userId}/export-excel?page=${
          userLogCurrentPage ?? 1
        }&limit=${userLogLimit ?? INITIAL_PAGINATION_SiZE}&timezone=${CURRENT_TIMEZONE}`
      )
    } catch (error: any) {
      message.error(error.message || 'Oops, something went wrong')
    } finally {
      setIsDowloading(false)
    }
  }

  useEffect(() => {
    activeTab === EUserManagementTabs.USER_LOG &&
      getUserLogs({
        id: Number(userId),
        page: 1,
        limit: INITIAL_PAGINATION_SiZE,
      })
  }, [activeTab])

  return (
    <UserLogTabStyled className="bg-[#fff] py-0">
      <div className="bg-[#fff] px-[1.25rem] rounded-[5px]">
        <div className="flex items-center justify-between py-[1.25rem] w-full flex-row-reverse">
          <div>
            <Button
              type="primary"
              onClick={onDowloadFile}
              loading={isDowloading}
              disabled={!userLogs?.length}
            >
              {t('common:export')}
            </Button>
          </div>
        </div>
        <div className="pb-[1.25rem]">
          {getUserLogByUserIdLoading ? (
            <Skeleton paragraph={{ rows: 4 }} />
          ) : (
            <SharedTable
              sticky
              columns={columns as any}
              dataSource={userLogs?.map((log, index) => {
                let displayName = log?.adminTitle
                if (
                  log.action === EActivityLogActionType.LOGIN_SUCCESS ||
                  log.action === EActivityLogActionType.LOGOUT_SUCCESS
                ) {
                  displayName += ` ${log?.metaData?.data?.deviceName}`
                }
                if (log.action === EActivityLogActionType.FRIEND_REQUEST) {
                  displayName += ` ${log?.metaData?.data?.user?.name}`
                }
                return {
                  ...log,
                  key: log.id,
                  no: index + 1,
                  title: displayName,
                  type:
                    LIST_USER_LOG_ACTION[log.type as EActivityLogType] ?? `--`,
                  time: log?.createdAt ?? `--`,
                }
              })}
              paginationProps={{
                total: !!userLogTotalItems ? +userLogTotalItems : undefined,
                pageSize: +userLogLimit ?? INITIAL_PAGINATION_SiZE,
                current: +userLogCurrentPage ?? 1,
                onChange(page) {
                  getUserLogs({
                    id: Number(userId),
                    page,
                    limit: +userLogLimit ?? INITIAL_PAGINATION_SiZE,
                  })
                },
                showSizeChanger: false,
                // onShowSizeChange(current, size) {},
              }}
              hasIndexColumn={false}
            />
          )}
        </div>
      </div>
      {openModal && (
        <DetailUserLogModal
          open={openModal}
          onClose={onClose}
          logId={logId}
          title="User log detail"
        />
      )}
    </UserLogTabStyled>
  )
}

export default UserLogTab

const UserLogTabStyled = styled('div')(() => {
  return {
    '&>div': {
      borderRight: '1px solid #f0f0f0',
      borderLeft: '1px solid #f0f0f0',
      borderBottom: '1px solid #f0f0f0',
    },

    '& .ant-table-wrapper': {
      margin: 0,
      '& .ant-table-content': {
        marginRight: 0,
      },
    },
  }
})
