import Cookies from 'js-cookie'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import { AuthRoute, PublicRoute } from '@components'
import {
  EPermissions,
  LLC_ACCESS_TOKEN,
  PATH_403,
  PATH_CHAT_CHANNEL,
} from '@configs'
import { IRoute } from '@interfaces'
import { AppLayout, DefaultLayout } from '@layouts'
import { authActions, getProfileAdminAction } from '@redux'
import ChatWelcome from 'src/components/chatChannel/ChatWelcome'
import usePermissions from 'src/hooks/usePermissions'
import ChatLayout from 'src/layouts/ChatLayout'
import { PageError404 } from 'src/pages'
import ChatContent from 'src/pages/chat/ChatContent'
import { PageError403 } from 'src/pages/errors/403'
import { publicRoutes, restrictedRoutes, routes } from './routes'

const Router = () => {
  const dispatch = useDispatch()
  const { permissions, isAdmin } = usePermissions({ moduleName: '' })
  const canAccessChat = useMemo(() => {
    if (isAdmin || !Object.keys(permissions)?.length) return true

    return (permissions as Record<EPermissions, boolean>)?.[
      EPermissions.CHAT_CHANNEL
    ]
  }, [isAdmin, permissions])

  useEffect(() => {
    const accessToken = Cookies.get(LLC_ACCESS_TOKEN)
    if (!accessToken) return

    dispatch(authActions.setAccessToken({ access_token: accessToken }))
    dispatch(getProfileAdminAction())
  }, [dispatch])

  return (
    <Routes>
      <Route
        path={'/*'}
        element={
          <DefaultLayout>
            <PageError404 />
          </DefaultLayout>
        }
      />

      <Route
        path={PATH_403}
        element={
          <DefaultLayout>
            <PageError403 />
          </DefaultLayout>
        }
      />

      {/* Auth Route */}
      <Route element={<AuthRoute />}>
        {routes.map(
          (
            route: IRoute & {
              permissionViewKeys?: EPermissions[]
              isNotAdmin?: boolean
            },
            index: number
          ) => {
            const Element = route.element
            const { permissionViewKeys, isNotAdmin } = route || {}
            const canAccess =
              (!!isNotAdmin ? true : isAdmin) ||
              !Object.keys(permissions)?.length
                ? true
                : permissionViewKeys?.some(
                    (permissionKey) =>
                      !!(permissions as Record<EPermissions, boolean>)?.[
                        permissionKey
                      ]
                  )

            return (
              <Route
                key={index}
                path={route.path}
                element={
                  canAccess ? (
                    <AppLayout>
                      <Element />
                    </AppLayout>
                  ) : (
                    <DefaultLayout>
                      <PageError403 />
                    </DefaultLayout>
                  )
                }
              />
            )
          }
        )}

        <Route
          path={PATH_CHAT_CHANNEL}
          element={
            canAccessChat ? (
              <AppLayout>
                <ChatLayout>
                  <ChatWelcome />
                </ChatLayout>
              </AppLayout>
            ) : (
              <DefaultLayout>
                <PageError403 />
              </DefaultLayout>
            )
          }
        />
        <Route
          path={`${PATH_CHAT_CHANNEL}/:chatId`}
          element={
            canAccessChat ? (
              <AppLayout>
                <ChatLayout>
                  <ChatContent />
                </ChatLayout>
              </AppLayout>
            ) : (
              <DefaultLayout>
                <PageError403 />
              </DefaultLayout>
            )
          }
        />
      </Route>
      {/* Restricted Route */}
      <Route element={<PublicRoute restricted={true} />}>
        {restrictedRoutes.map((route: IRoute, index: number) => {
          const Element = route.element
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <DefaultLayout>
                  <Element />
                </DefaultLayout>
              }
            />
          )
        })}
      </Route>
      {/* public Route */}
      <Route element={<PublicRoute />}>
        {publicRoutes.map((route: IRoute, index: number) => {
          const Element = route.element
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <DefaultLayout>
                  <Element />
                </DefaultLayout>
              }
            />
          )
        })}
      </Route>
    </Routes>
  )
}

export default Router
