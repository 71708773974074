import { useEffect, useRef, useState } from 'react'
import { message, Typography } from 'antd'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { CONTENT_MANAGEMENT_VALIDATION } from '@configs'
import {
  TEACHER_BECOME_INSTRUCTOR_DEFAULT_VALUES,
  teacherBecomeInstructorSchema,
} from '@pages'
import {
  getTeacherBecomeInstructorAction,
  updateTeacherBecomeInstructorAction,
  useAppDispatch,
} from '@redux'
import { Button, Input } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import { ITeacherBecomeInstructorRequest } from 'src/interfaces/teacher'

type TeacherBecomeInstructorProps = {
  activeTab: string
}
export const TeacherBecomeInstructor = (
  props: TeacherBecomeInstructorProps
) => {
  const dispatch = useAppDispatch()

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    setError,
    watch,
    getValues,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues: TEACHER_BECOME_INSTRUCTOR_DEFAULT_VALUES,
    resolver: zodResolver(teacherBecomeInstructorSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [imageSrc, setImageSrc] = useState('')
  const [imageMobileSrc, setImageMobileSrc] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getTeacherBecomeInstructorAction()
      ).unwrap()
      if (response) {
        setValue('title', response?.title)
        setValue('titleInDutch', response?.titleInDutch)
        setValue('subTitle', response?.subTitle)
        setValue('subTitleInDutch', response?.subTitleInDutch)
        setValue('ctaButtonName', response?.metaData?.button)
        setValue('ctaButtonNameInDutch', response?.metaData?.buttonInDutch)
        setValue('mediaId', response?.media?.id)
        setValue('mediaMobileId', response?.mediaMobile?.id)
        setImageSrc(response?.media?.url ?? '')
        setImageMobileSrc(response?.mediaMobile?.url ?? '')
      }
    }
    fetchData()
  }, [dispatch, setValue])

  const handleConfirmSubmit = async (data: any) => {
    const body: ITeacherBecomeInstructorRequest = {
      title: data.title,
      titleInDutch: data.titleInDutch,
      subTitle: data.subTitle,
      subTitleInDutch: data.subTitleInDutch,
      description: data.description,
      descriptionInDutch: data.descriptionInDutch,
      button: data.ctaButtonName,
      buttonInDutch: data.ctaButtonNameInDutch,
      mediaId: data.mediaId,
      mediaMobileId: data.mediaMobileId,
    }
    const response = await dispatch(
      updateTeacherBecomeInstructorAction(body)
    ).unwrap()

    if (response?.commonContent) {
      message.success({
        content: response?.message ?? t('common:save_success'),
      })
    }
  }

  // const onReset = () => {
  //   clearErrors()
  //   setValue('title', '')
  //   setValue('titleInDutch', '')
  //   setValue('subTitle', '')
  //   setValue('subTitleInDutch', '')
  //   setValue('ctaButtonName', '')
  //   setValue('ctaButtonNameInDutch', '')
  // }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
      setImageSrc(data?.baseUrl ?? '')
    } else if (selectedField === 'mobile') {
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
      setImageMobileSrc(data?.baseUrl ?? '')
    }

    setShowModalSelectMedia(false)
  }

  return (
    <div className="blog-content-m-right col-span-12 lg:col-span-4 rounded-lg">
      <div className="intro-y box p-5 bg-white rounded-lg">
        <div className="blog-content-top intro-y mt-2 flex flex-col items-center sm:flex-row">
          <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-between">
            Become Instructor
          </h2>
        </div>

        {/* Content */}

        <div className="blog-content-main intro-y mt-5">
          <div className="grid grid-cols-12 gap-4">
            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <Controller
                  name="title"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        name="title"
                        placeholder="Title"
                        value={value}
                        onChange={(event) => {
                          onChange(event?.target?.value)
                        }}
                        required
                        label="Title"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>

              <div className="col-span-6">
                <Controller
                  name="titleInDutch"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="Title in Netherlands"
                        value={value}
                        onChange={onChange}
                        required
                        label="Title in Netherlands"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>
            </div>

            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <Controller
                  name="subTitle"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="Sub Title"
                        value={value}
                        onChange={(event) => {
                          onChange(event?.target?.value)
                        }}
                        required
                        label="Sub Title"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>

              <div className="col-span-6">
                <Controller
                  name="subTitleInDutch"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="Sub Title in Netherlands"
                        value={value}
                        onChange={onChange}
                        required
                        label="Sub Title in Netherlands"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>
            </div>

            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <Controller
                  name="ctaButtonName"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="CTA Button Name"
                        value={value}
                        onChange={(event) => {
                          onChange(event?.target?.value)
                        }}
                        required
                        label="CTA Button Name"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>

              <div className="col-span-6">
                <Controller
                  name="ctaButtonNameInDutch"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="CTA Button Name NTL"
                        value={value}
                        onChange={onChange}
                        required
                        label="CTA Button Name NTL"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>
              <div className="w-full col-span-6 flex flex-col gap-1 items-end">
                <Controller
                  name="mediaId"
                  control={control}
                  render={({ field: { onChange }, fieldState: { error } }) => {
                    onChangeDesktopRef.current = onChange
                    return (
                      <CustomDragger
                        label="Image"
                        name="mediaId"
                        id="mediaId"
                        containerClassName="w-full mt-3"
                        labelClassName="w-full"
                        alignment="col"
                        required
                        onLoadEnd={(data) => {
                          setValue('mediaId', data?.id ?? 0)
                          onChange(data?.id ?? 0)
                          setImageSrc((prev) => data.original ?? prev)
                        }}
                        errors={error?.message}
                        initResource={imageSrc}
                        reset={getValues('mediaId') === 0}
                        limitFileSize={
                          CONTENT_MANAGEMENT_VALIDATION.MAX_IMAGE_SIZE
                        }
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        changeLoading={setIsModalLoading}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    )
                  }}
                />
                <Typography.Text type="danger">
                  {t('common:imageResolutionStandard')}
                </Typography.Text>
                <SelectMedia
                  isNotGrid
                  buttonClassName="!text-blue-500"
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'desktop')
                    setShowModalSelectMedia(true)
                  }}
                  isLoadingMedia={isModalLoading}
                />
              </div>

              <div className="w-full col-span-6 flex flex-col gap-1 items-end mb-4">
                <Controller
                  name={'mediaMobileId'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    onChangeMobileRef.current = onChange
                    return (
                      <>
                        <CustomDragger
                          label="Image mobile"
                          containerClassName="w-full mt-[10px]"
                          labelClassName="w-full"
                          alignment="col"
                          onLoadEnd={(data) => {
                            setValue('mediaMobileId', data?.id ?? 0)
                            onChange(data?.id ?? 0)
                            setImageMobileSrc((prev) => data.original ?? prev)
                          }}
                          name="mediaMobileId"
                          id="mediaMobileId"
                          required
                          errors={error?.message}
                          multiple={false}
                          initResource={imageMobileSrc}
                          reset={getValues('mediaMobileId') === 0}
                          allowFileTypes={[
                            'image/png',
                            'image/jpeg',
                            'image/jpg',
                          ]}
                          limitFileSize={
                            CONTENT_MANAGEMENT_VALIDATION.MAX_IMAGE_SIZE
                          }
                          changeLoading={setIsModalLoading}
                          uploadType={EMediaType.IMAGE as any}
                          uploadCategory={EMediaCategory.CONTENT}
                        />
                      </>
                    )
                  }}
                />
                <Typography.Text type="danger">
                  {t('common:imageMobileResolutionStandard')}
                </Typography.Text>
                <SelectMedia
                  isNotGrid
                  buttonClassName="!text-blue-500"
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'mobile')
                    setShowModalSelectMedia(true)
                  }}
                  isLoadingMedia={isModalLoading}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="blog-content-top item-right-wrapper flex items-center justify-center gap-2 mt-5 pb-8">
          {/* <Button
            type="default"
            size="small"
            id="blog-form"
            onClick={onReset}
          >
            {t('common:reset')}
          </Button> */}

          <Button
            disabled={!isDirty}
            type="primary"
            size="small"
            id="blog-form"
            onClick={() => {
              handleSubmit(handleConfirmSubmit)()
            }}
            loading={isModalLoading}
          >
            {t('common:save')}
          </Button>
        </div>
      </div>
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.CONTENT}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
