import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  RetweetOutlined,
  SearchOutlined,
  SendOutlined,
} from '@ant-design/icons'
import { ShareSelectInput } from '@components'
import {
  EEmailContentType,
  EMAIL_CONTENT_NAME,
  EUserPostStatus,
  PATH_EMAIL_CREATE,
  PATH_EMAIL_DETAIL,
  PATH_POST_CREATE,
  POST_DISPLAY_NAME,
  enumTableTitleEmailManagement,
  thunkActionLoading,
} from '@configs'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Card, Skeleton, Tooltip, message } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Loading, SharedTable } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import {
  Group,
  IEmailContentItem,
  TGetEmailContentsParams,
} from 'src/interfaces/email-content-management'
import {
  deleteEmailContentAction,
  duplicateEmailContentAction,
  getEmailContentsAction,
  resendEmailContentAction,
} from 'src/redux/actions/email-content-management'
import { getGroupUsersAction } from 'src/redux/actions/group-user-management'

type Props = {}
const MAX_GROUP_LIMIT = 10000

const EmailContentPage = (props: Props) => {
  const [isModalLoading, setIsModalLoading] = useState(false)

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)

  const { t } = useTranslation(['error', 'common', 'emailContent'])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [deletedEmailContent, setDeletedEmailContent] =
    useState<IEmailContentItem>()
  const [duplicateEmailContent, setDuplicateEmailContent] =
    useState<IEmailContentItem>()
  const [resendEmailContent, setResendEmailContent] =
    useState<IEmailContentItem>()

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState<boolean | undefined>(undefined)

  const debouncedValue = useDebounce<string | null>(searchText, 500)
  const [isSearching, setIsSearching] = useState(false)

  const [groupUser, setGroupUser] = useState<Group[]>([])
  const [type, setType] = useState<string | undefined>(undefined)
  const [groupId, setGroupId] = useState<number | undefined>(undefined)

  const { emailContent } = useAppSelector(
    (state: RootState) => state.emailContent
  )

  const getEmailContentsLoading = useAppSelector(
    (state: RootState) =>
      state.emailContent.loadings[thunkActionLoading.GET_EMAIL_CONTENTS_LOADING]
  )

  const deletedEmailContentLoading = useAppSelector(
    (state: RootState) =>
      state.emailContent.loadings[
        thunkActionLoading.DELETE_EMAIL_CONTENT_LOADING
      ]
  )

  const isDuplicateEmailContentLoading = useAppSelector(
    (state: RootState) =>
      state.emailContent.loadings[
        thunkActionLoading.DUPLICATE_EMAIL_CONTENT_LOADING
      ]
  )

  const isResendEmailContentLoading = useAppSelector(
    (state: RootState) =>
      state.emailContent.loadings[
        thunkActionLoading.RESEND_EMAIL_CONTENT_LOADING
      ]
  )

  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleEmailManagement.NO}</p>
          </div>
        )
      },
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '5%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: false,
    },
    {
      title: enumTableTitleEmailManagement.TITLE,
      dataIndex: 'title',
      key: 'title',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '45%',
    },
    {
      title: enumTableTitleEmailManagement.TYPE,
      dataIndex: 'type',
      key: 'type',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
    },
    {
      title: enumTableTitleEmailManagement.GROUP,
      dataIndex: 'group',
      key: 'group',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
    },
    {
      title: enumTableTitleEmailManagement.STATUS,
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
    {
      title: enumTableTitleEmailManagement.ACTION,
      key: 'action',
      dataIndex: 'action',
      width: '15%',
      align: 'center',
      render: (record: IEmailContentItem) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          {/* <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light cursor-pointer !text-[#184f64] hover:opacity-80"
              onClick={() => {
                navigate(`${PATH_POST_MANAGEMENT}/post-detail/${record?.id}`)
              }}
            />
          </Tooltip> */}
          <Tooltip title={t('common:edit')}>
            <EditOutlined
              className="text-lg font-light mr-2.5 cursor-pointer !text-[#184f64] hover:opacity-80"
              onClick={() => {
                // Edit action
                navigate(
                  `${PATH_EMAIL_DETAIL.replace(
                    ':emailContentId',
                    record?.id.toString()
                  )}`
                )
              }}
            />
          </Tooltip>
          <Tooltip title={t('common:delete')}>
            <DeleteOutlined
              className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
              onClick={() => {
                setDeletedEmailContent(record)
                setOpenConfirmDeleteModal(true)
              }}
            />
          </Tooltip>
          {/* Action duplicate */}
          {isDuplicateEmailContentLoading &&
          duplicateEmailContent?.id === record?.id ? (
            <Tooltip title={t('common:duplicate_loading')}>
              <LoadingOutlined
                className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-[#184f64]`}
              />
            </Tooltip>
          ) : (
            <Tooltip title={t('common:duplicate')}>
              <CopyOutlined
                className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-[#184f64]`}
                onClick={() => {
                  // Duplicate action
                  setDuplicateEmailContent((pre) => record)
                  onDuplicateEmailContent(record?.id)
                }}
              />
            </Tooltip>
          )}

          {/* Action resend */}
          {isResendEmailContentLoading &&
          resendEmailContent?.id === record?.id ? (
            <Tooltip title={t('common:sending')}>
              <LoadingOutlined
                className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-[#184f64]`}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={record?.status ? t('common:resend') : t('common:send')}
            >
              {record?.status ? (
                <RetweetOutlined
                  className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-[#184f64]`}
                  onClick={() => {
                    // Resend action
                    setResendEmailContent((pre) => record)
                    onResendEmailContent(record?.id)
                  }}
                />
              ) : (
                <SendOutlined
                  className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-[#184f64]`}
                  onClick={() => {
                    // Resend action
                    setResendEmailContent((pre) => record)
                    onResendEmailContent(record?.id)
                  }}
                />
              )}
            </Tooltip>
          )}
        </div>
      ),
    },
  ]

  const statusOptions = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: POST_DISPLAY_NAME[EUserPostStatus.PUBLIC],
      value: 1,
    },
    {
      label: POST_DISPLAY_NAME[EUserPostStatus.FRIEND],
      value: 0,
    },
  ]

  // const typeOptions = Object.keys(EMAIL_CONTENT_NAME).map((key) => [
  //   key,
  //   EEmailContentType[key],
  // ])

  const typeOptions = Object.entries(EMAIL_CONTENT_NAME).map(
    ([key, value]) => ({
      label: value,
      value: key,
    })
  )

  const groupOptions = groupUser.map((item) => ({
    label: item.name,
    value: item.id.toString(),
  }))

  groupOptions.unshift({
    label: 'All',
    value: 'All',
  })

  const getEmailContents = async (params?: TGetEmailContentsParams) => {
    dispatch(
      getEmailContentsAction({
        page: page,
        limit: 10,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        ...(params || {}),
      })
    )
  }

  const getEmailContentsBySearchValue = async () => {
    try {
      setIsSearching(true)
      await dispatch(
        getEmailContentsAction({
          page: 1,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
          status: status,
          type: type,
          groupId: groupId,
        })
      ).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setIsSearching(false)
    }
  }

  const onDeleteEmailContent = async () => {
    if (!deletedEmailContent || !deletedEmailContent?.id) return

    setIsModalLoading(true)
    try {
      const response = await dispatch(
        deleteEmailContentAction(deletedEmailContent?.id as number)
      ).unwrap()
      if (response?.success) message.success(response.message)
      getEmailContents()
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setDeletedEmailContent(undefined)
      setOpenConfirmDeleteModal(false)
      setIsModalLoading(false)
    }
  }

  const onCloseDeleteModal = () => {
    setOpenConfirmDeleteModal(false)
    setDeletedEmailContent(undefined)
  }

  const onDuplicateEmailContent = async (id: number) => {
    // Duplicate email content action
    if (!id) return
    try {
      const response = await dispatch(duplicateEmailContentAction(id)).unwrap()
      if (response?.success) message.success(response.message)
      getEmailContents()
    } catch (error: any) {
      message.error(error.message)
    }
  }

  const onResendEmailContent = async (id: number) => {
    // Resend email content action
    if (!id) return
    try {
      const response = await dispatch(resendEmailContentAction(id)).unwrap()
      if (response?.success) message.success(response.message)
      getEmailContents()
    } catch (error: any) {
      message.error(error.message)
    }
  }

  useEffect(() => {
    const getAllGroupUser = async () => {
      try {
        const response = await dispatch(
          getGroupUsersAction({
            page: 1,
            limit: MAX_GROUP_LIMIT,
          })
        ).unwrap()

        if (response?.success) {
          setGroupUser(response?.data?.items)
        }
      } catch (error: any) {
        message.error(error.message)
      }
    }
    getAllGroupUser()
  }, [])

  useEffect(() => {
    getEmailContents({ page: 1, status: status })
  }, [])

  useEffect(() => {
    getEmailContentsBySearchValue()
  }, [debouncedValue])

  return (
    <Card>
      <div className="flex items-center justify-between py-[1.25rem] gap-5 w-full">
        <div className="  flex gap-8">
          <Input
            prefix={<SearchOutlined className="pl-2" />}
            placeholder="Search email content"
            value={searchText === null ? '' : searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            suffix={debouncedValue && isSearching ? <Loading /> : undefined}
            className="!w-[200px]"
          />

          {/* Start filter */}
          <div className="filter-status flex justify-center items-center gap-1 relative">
            <div className="status-label">
              {enumTableTitleEmailManagement.STATUS}
            </div>
            <ShareSelectInput
              placeholder="Status"
              onChange={(value) => {
                if (value === 'All') {
                  setStatus(undefined)
                  getEmailContents({ page: 1, type, groupId })
                } else {
                  setStatus(!!value)
                  getEmailContents({
                    page: 1,
                    status: !!value,
                    type,
                    groupId,
                  })
                }
              }}
              data={statusOptions}
              showArrow
              className="!w-[120px]"
            />
          </div>

          <div className="filter-status flex justify-center items-center gap-1 relative">
            <div className="status-label">
              {enumTableTitleEmailManagement.TYPE}
            </div>
            <ShareSelectInput
              placeholder="Type"
              onChange={(value) => {
                if (value === 'All') {
                  setType(undefined)
                  getEmailContents({ page: 1, status, groupId })
                } else {
                  setType(value)
                  getEmailContents({
                    page: 1,
                    status,
                    groupId,
                    type: value,
                  })
                }
              }}
              showArrow
              data={typeOptions}
              className="!w-[140px]"
            />
          </div>

          <div className="filter-status flex justify-center items-center gap-1 relative">
            <div className="status-label">
              {enumTableTitleEmailManagement.GROUP}
            </div>
            <ShareSelectInput
              placeholder="Group"
              onChange={(value) => {
                if (value === 'All') {
                  setGroupId(undefined)
                  getEmailContents({ page: 1, status, type })
                } else {
                  setGroupId(+value)
                  getEmailContents({
                    page: 1,
                    status,
                    type,
                    groupId: +value,
                  })
                }
              }}
              showArrow
              data={groupOptions}
              className="!min-w-[280px]"
            />
          </div>
        </div>

        {/* ----Filter---- */}
        <div>
          <Button
            type="primary"
            onClick={() => {
              // Create new email content action
              navigate(`${PATH_EMAIL_CREATE}`)
            }}
          >
            {t('emailContent:create_new_email_content')}
          </Button>
        </div>
      </div>
      {getEmailContentsLoading ? (
        <Skeleton
          paragraph={{ rows: 10 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={emailContent?.emailContents?.map((item, index) => {
            return {
              ...item,
              key: item?.id,
              no: index + 1,
              title: item?.title ? item?.title : `--`,
              type: item?.type
                ? EMAIL_CONTENT_NAME[item?.type as EEmailContentType]
                : `--`,
              group: item?.group ? item?.group?.name : `--`,
              status:
                item?.status === true
                  ? POST_DISPLAY_NAME[EUserPostStatus.PUBLIC]
                  : POST_DISPLAY_NAME[EUserPostStatus.FRIEND],
              record: item,
              action: item,
            }
          })}
          sticky
          paginationProps={{
            total: emailContent?.total ?? 0,
            pageSize: 10,
            showSizeChanger: false,
            current: emailContent?.page ?? 1,
            onChange(page) {
              getEmailContents({ page: page, status: status })
            },
          }}
        />
      )}

      {openConfirmDeleteModal ? (
        <ConfirmDeleteModal
          title={t('emailContent:delete_email_content_title')}
          content={t('emailContent:delete_email_content_content')}
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading || !!deletedEmailContentLoading}
          onDelete={onDeleteEmailContent}
          onClose={onCloseDeleteModal}
        />
      ) : null}
    </Card>
  )
}

export default EmailContentPage
