import { EPermissions, EUserStatus } from 'src/configs'
import { IUserDetail } from './user-management'

export interface IPermissionModule {
  module: string
  permissions: IPermission[]
}

export interface IPermission {
  id: number
  name: string
  slug: string
  module: string
  createdAt: string
  updatedAt: string
}

export interface IRole {
  id: number | null
  name: string
  isDefault?: boolean
  permissionIds: number[]
  createdAt?: string
  updatedAt?: string
}

export interface IPostRolePayload {
  id?: number
  name: string
  permissionIds: number[]
}

export interface ICreateRoleSuccessData {
  role: IRole
  message: string
}

export interface IGetRolesParams {
  page: number
  limit?: number
  search?: string | null
  sort?: { [key: string]: any }
  isDefault?: boolean
}

export interface IGetRolesSuccessData {
  items: IRole[]
  total: number
  page: string | number
  limit: string | number
}

export interface IGetAdmisSuccessData {
  items: IUserDetail[]
  total: number
  page: string | number
  limit: string | number
}

export interface IStaffPayload {
  email: string
  name: string
  phone: string
  roleId: number | string
  status: EUserStatus
}

export interface IStaffSuccessData {
  admin: IUserDetail
  message: string
}

export interface IStaffDeleteParams {
  id: number | string
  isSoft?: boolean
}
export interface IAddNewRolePayload {
  name: string
  permissions: Record<EPermissions, boolean>
}

export interface ICreateNewRoleResponse {
  code: number
  message: string
  success: boolean
}

export interface IRoleItem {
  id: number
  name: string
  isAdmin: boolean
}

export interface IPermissionRoleItem {
  permission: EPermissions
  roles: Record<string, boolean>
}

export interface IListPermissionRolesData {
  data: {
    listRoles: IRoleItem[]
    permissionWithRoles: IPermissionRoleItem[]
  }
  isLoading: boolean
}

export enum EPermissionAction {
  EDIT_PERMISSION = 'EDIT_PERMISSION',
  EDIT_NAME = 'EDIT_NAME',
  DELETE_ROLE = 'DELETE_ROLE',
  DUPLICATE_ROLE = 'DUPLICATE_ROLE',
}

export interface IChosenRole {
  id: number
  name: string
  actionType: EPermissionAction
}
