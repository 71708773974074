import {
  EMAIL_CONTENT_NAME,
  EUploadFileType,
  LIVE_LIFE_CLEAN,
  MAX_LIMIT_ITEM_PER_PAGE,
  PATH_EMAIL_MANAGEMENT,
  PLACEHOLDER_IMAGE_AVATAR,
  thunkActionLoading,
} from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { RouterParams } from '@interfaces'
import { useAppDispatch, useAppSelector } from '@redux'
import { Card, Skeleton, Typography, message } from 'antd'
import parse from 'html-react-parser'
import { Fragment, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { CaretDownOutlined } from '@ant-design/icons'
import { ShareSelectInput } from '@components'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { t } from 'i18next'
import { Button, Input } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import Editor from 'src/components/editor/Editor'
import { useSettings } from 'src/components/editor/context/SettingsContext'
import PlaygroundNodes from 'src/components/editor/nodes/PlaygroundNodes'
import PlaygroundEditorTheme from 'src/components/editor/themes/PlaygroundEditorTheme'
import {
  Group,
  IEmailContentForm,
} from 'src/interfaces/email-content-management'
import {
  createEmailContentAction,
  getDetailEmailContentAction,
  updateEmailContentAction,
} from 'src/redux/actions/email-content-management'
import { getGroupUsersAction } from 'src/redux/actions/group-user-management'
import { z } from 'zod'
import SaveEmailContentModal from './SaveEmailContentModal'

import '../../index.css'

type Props = {}

const emailContentSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty(t('error:field_required'))
    .max(200, t('error:email_content_title_length_error')),
  content: z.string().optional(),
  // .max(1000, t('error:email_content_content_length_error')),
  type: z.string().trim().nonempty(t('error:field_required')),
  groupId: z.number().min(1, { message: t('error:field_required') as string }),
  mediaId: z.number().optional().nullable(),
  status: z.boolean(),
})

const defaultValue = {
  title: '',
  content: '',
  // type: '',
  // groupId: 0,
  status: false,
  mediaId: null,
}

const typeOptions = Object.entries(EMAIL_CONTENT_NAME).map(([key, value]) => ({
  label: value,
  value: key,
}))

typeOptions.shift()

const CreateEditEmailContentPage = (props: Props) => {
  const { emailContentId } = useParams<RouterParams['EmailContentDetail']>()

  const isEdit = Boolean(emailContentId)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [groupUser, setGroupUser] = useState<Group[]>([])

  const [imageSource, setImageSource] = useState<string | null>(null)

  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const isGetDetailEmailContentLoading = useAppSelector(
    (state) =>
      state?.emailContent?.loadings?.[
        thunkActionLoading.GET_DETAIL_EMAIL_CONTENT_LOADING
      ]
  )

  const isCreateEmailContentLoading = useAppSelector(
    (state) =>
      state?.emailContent?.loadings?.[
        thunkActionLoading.CREATE_EMAIL_CONTENT_LOADING
      ]
  )

  const isUpdateEmailContentLoading = useAppSelector(
    (state) =>
      state?.emailContent?.loadings?.[
        thunkActionLoading.UPDATE_EMAIL_CONTENT_LOADING
      ]
  )

  const isGetAllGroupUserLoading = useAppSelector(
    (state) =>
      state?.groupUser?.loadings?.[thunkActionLoading.GET_GROUP_USERS_LOADING]
  )

  const groupOptions = groupUser.map((item) => ({
    label: item.name,
    value: item.id,
  }))

  const {
    settings: { isCollab, emptyEditor, measureTypingPerf },
  } = useSettings()
  const initialConfig: any = {
    editorState: isCollab ? null : emptyEditor,
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: PlaygroundEditorTheme,
  }

  const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_type) {
      return { message: t('error:field_required') }
    }

    return { message: ctx.defaultError }
  }

  z.setErrorMap(customErrorMap)

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm<IEmailContentForm>({
    defaultValues: defaultValue,
    resolver: zodResolver(emailContentSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onSave = async (data: IEmailContentForm) => {
    if (!data?.type) {
      setError('type', {
        type: 'invalid_type',
        message: t('error:field_required') as string,
      })
    }

    if (!data?.groupId) {
      setError('groupId', {
        type: 'invalid_type',
        message: t('error:field_required') as string,
      })
    }

    if (!data?.content && !data?.mediaId) {
      message.error(t('error:email_content_content_and_media_error'))
      return
    }
    if (!isEdit) {
      setOpenConfirmModal(true)
    } else {
      try {
        if (!emailContentId) return
        const response = await dispatch(
          updateEmailContentAction({
            id: +emailContentId,
            ...data,
          })
        ).unwrap()
        if (response?.success) {
          message.success(response?.message)
          navigate(PATH_EMAIL_MANAGEMENT)
        }
      } catch (error: any) {
        message.error(error && error?.message)
      }
    }
  }

  const onPublish = async (data: IEmailContentForm) => {
    try {
      const response = await dispatch(
        createEmailContentAction({
          ...data,
          status: true,
        })
      ).unwrap()
      if (response?.success) {
        message.success(response?.message)
        navigate(PATH_EMAIL_MANAGEMENT)
      }
    } catch (error: any) {
      message.error(error && error?.message)
    }
  }

  const onDraft = async (data: IEmailContentForm) => {
    try {
      const response = await dispatch(createEmailContentAction(data)).unwrap()
      if (response?.success) {
        message.success(response?.message)
        navigate(PATH_EMAIL_MANAGEMENT)
      }
    } catch (error: any) {
      message.error(error && error?.message)
    }
  }

  useEffect(() => {
    const getDetail = async () => {
      if (!emailContentId) return
      try {
        const response = await dispatch(
          getDetailEmailContentAction(+emailContentId)
        ).unwrap()
        if (response?.success) {
          const data = response?.data
          setImageSource(data?.media?.url ?? '')
          setValue('title', data?.title)
          setValue('content', data?.content)
          setValue('status', data?.status)
          setValue('type', data?.type)
          setValue('groupId', data?.groupId)
          setValue('mediaId', data?.media?.id ?? null)
        }
      } catch (error: any) {
        message.error(error && error?.message)
      }
    }
    if (isEdit) {
      getDetail()
    }
  }, [emailContentId])

  useEffect(() => {
    const getAllGroupUser = async () => {
      try {
        const response = await dispatch(
          getGroupUsersAction({
            page: 1,
            limit: MAX_LIMIT_ITEM_PER_PAGE,
            status: true,
          })
        ).unwrap()

        if (response?.success) {
          setGroupUser(response?.data?.items)
        }
      } catch (error: any) {
        message.error(error.message)
      }
    }
    getAllGroupUser()
  }, [])

  return (
    <Card className="bg-white p-10 min-h-[600px]">
      <Typography className="text-[24px] font-semibold w-full text-center text-black mb-5">
        {isEdit
          ? t('emailContent:header_edit')
          : t('emailContent:header_create')}
      </Typography>

      {isGetDetailEmailContentLoading || isGetAllGroupUserLoading ? (
        <Skeleton
          paragraph={{ rows: 10 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <div>
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <ShareSelectInput
                  placeholder={t('emailContent:form_label_type')}
                  required
                  label={t('emailContent:form_label_type')}
                  onChange={onChange}
                  data={typeOptions}
                  value={value}
                  errors={error?.message}
                  containerClassName="!mb-5"
                  showArrow
                />
              )
            }}
          />
          <Controller
            name={'groupId'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <ShareSelectInput
                  placeholder={t('emailContent:form_label_group')}
                  required
                  label={t('emailContent:form_label_group')}
                  onChange={(value) => {
                    onChange(+value)
                  }}
                  data={groupOptions}
                  value={value}
                  errors={error?.message}
                  containerClassName="!mb-5"
                  showArrow
                />
              )
            }}
          />

          <Controller
            name={'title'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label={t('emailContent:form_label_title')}
                  placeholder={t('emailContent:form_label_title')}
                  name="title"
                  required
                  onChange={onChange}
                  value={value}
                  errors={error?.message}
                  alignment="col"
                />
              )
            }}
          />

          <div className="editor-content mt-5 h-[750px] overflow-hidden">
            <LexicalComposer initialConfig={initialConfig}>
              <div className="editor-shell">
                <Controller
                  name={'content'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Fragment>
                        <div className="border">
                          <Editor
                            setValue={setValue}
                            value={value}
                            clearErrors={clearErrors}
                            setError={setError}
                            contentName={'content'}
                            config={{
                              hasInsert: false,
                              isRequired: false,
                            }}
                          />
                        </div>
                        {error && error.message ? (
                          <p className="text-[#B91C1C] mt-2 text-sm">
                            {error.message}
                          </p>
                        ) : null}
                      </Fragment>
                    )
                  }}
                />
              </div>
            </LexicalComposer>
          </div>

          <Controller
            name="mediaId"
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => {
              return (
                <CustomDragger
                  label={t('emailContent:form_label_upload')}
                  name="mediaId"
                  id="mediaId"
                  containerClassName="mt-5 "
                  onLoadEnd={(data) => {
                    setValue('mediaId', data?.id ?? 0, {
                      shouldDirty: true,
                    })
                    clearErrors('mediaId')
                    setImageSource(data?.url ?? null)
                  }}
                  errors={error?.message}
                  initResource={imageSource as string}
                  changeLoading={(loading) => {
                    //
                  }}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  limitFileSize={5}
                  labelClassName="w-1/2"
                  uploadType={EUploadFileType.IMAGE}
                />
              )
            }}
          />

          {!!watch('title') || !!watch('content') ? (
            <div className="wrapper-preview mt-5">
              <div className="font-bold">{t('emailContent:preview')}</div>

              {/* Container preview */}
              <div className="preview-content p-6 mt-2 border ">
                <div className="preview-content__title font-semibold text-xl mb-3">
                  {watch('title') || t('emailContent:form_label_title')}
                </div>
                <div className="preview-content__body">
                  <div className="preview-content__header flex items-center gap-2">
                    <img
                      src={PLACEHOLDER_IMAGE_AVATAR}
                      alt={t('emailContent:form_label_title')}
                      className="preview-content__header-left object-cover w-9 h-9 rounded-full"
                    />
                    <div
                      className="preview-content__header-right
                  "
                    >
                      <div className="preview-header_right-top font-semibold">
                        {LIVE_LIFE_CLEAN}
                      </div>
                      {watch('groupId') ? (
                        <div className="preview-header_right-bottom flex items-center gap-2">
                          <div className="">{`to ${
                            groupOptions.find(
                              (item) => item.value === watch('groupId')
                            )?.label
                          }`}</div>
                          <CaretDownOutlined />
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* --------- */}
                  <div className="preview-content__text mt-4">
                    {parse(watch('content')) ||
                      t('emailContent:form_label_content')}
                  </div>

                  {/* --------- */}
                  {imageSource ? (
                    <div className="preview-content__image mt-4 flex items-center justify-center">
                      <img
                        src={imageSource}
                        alt={t('emailContent:form_label_title')}
                        className="object-cover w-1/2 h-1/2"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          <div className="flex mt-10 pb-10 items-center gap-[40px] justify-center">
            <Button
              type="primary"
              size="middle"
              className="submit__btn login-btn"
              loading={isUpdateEmailContentLoading}
              disabled={!isDirty}
              onClick={() => {
                handleSubmit(onSave)()
              }}
            >
              {t('emailContent:save')}
            </Button>
          </div>
        </div>
      )}
      <SaveEmailContentModal
        open={openConfirmModal}
        content={t('emailContent:modal_create_content') as string}
        loading={isCreateEmailContentLoading}
        onPublish={() => {
          onPublish({
            ...watch(),
            status: true,
          })
        }}
        onDraft={() => {
          onDraft(watch())
        }}
        onClose={() => {
          setOpenConfirmModal(false)
        }}
      />
    </Card>
  )
}

export default CreateEditEmailContentPage
