export type TFillInTeacherInformation = Partial<{
  personalInformation: TPersonalInformation
  specificInformation: TSpecificInformation
}>

export enum ETeacherRequestStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  UPDATED = 'UPDATED',
  REQUEST_UPDATE = 'REQUEST_UPDATE',
}

export enum ESignUpGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum ETeacherRequestModalType {
  ACCEPTED = ETeacherRequestStatus.ACCEPTED,
  REJECTED = ETeacherRequestStatus.REJECTED,
  REQUEST_UPDATE = ETeacherRequestStatus.REQUEST_UPDATE,
}

export type TSignUpDetailsResponse = Partial<{
  teacherRequest: {
    id: number
    personalInformation: TPersonalInformation
    specificInformation: TSpecificInformation
    userId: number
    status: ETeacherRequestStatus
    createdAt: null | string
    updatedAt: null | string
    deletedAt: null | string
    responseData: TResponseData
  }
  token: {
    accessToken: string
    refreshToken: string
  }
  message: string
}>

export type TResponseData = Partial<{
  reason: string
  updatedBy: { name: string; client: string }
}>

export type TPersonalInformation = Partial<{
  firstname: string
  lastname: string
  fullname: string
  gender: ESignUpGender | string
  dateOfBirth: string
  phone: string
  description: string
  avatarId: number
  email: string
  password: string
  isEditable: boolean
}>

export type TTeacherSignature = Partial<{
  name: string
  url: string
  fileId: number
  editable: boolean
  signaturePhoto?: IMedia
}>

export type TSpecificInformation = Partial<{
  education: TEducation[]
  workExperience: TWorkExperience[]
  courseReference: TCourseReference[]
  teacherSignature: TTeacherSignature
  isNotReference: boolean
}>

export enum EEducationLevel {
  BACHELOR_DEGREE = 'BACHELOR_DEGREE',
  DIPLOMA = 'DIPLOMA',
  MASTER_DEGREE = 'MASTER_DEGREE',
  OTHER = 'OTHER',
}

export type TEducation = Partial<{
  institution: string
  educationLevel: EEducationLevel | string
  qualification: string
  description: string
  startDate: string
  endDate: string
  editable: boolean
  isPresent: boolean
  degree?: TDegree
}>

export type TWorkExperience = Partial<{
  companyName: string
  jobTitle: string
  description: string
  startDate: string
  endDate: string
  editable: boolean
  isPresent: boolean
}>

export interface IMedia {
  id: number
  cloudfrontUrl: string
  url: string
  userId: number
  bucket: string
  path: string
  type: string
  name: string
  category: string
  createdAt: string | null
  updatedAt: string | null
}

export type TDegree = Partial<{
  file: {
    name: string
    fileId: number
  }
  name: string
  fileId: number
  editable: boolean
  mediaFile: IMedia
}>

export type TCourseReference = Partial<{
  name: string
  url: string
  editable: boolean
}>

// Common base type for forms
type TBaseFillInForm<T> = Partial<{
  data: T
  index: number
  onRemove: (id?: number) => void
  onSave: (id?: number) => void
  onEdit: (id?: number) => void
}>

// Work experience types
export type TExperienceForm = TBaseFillInForm<TWorkExperience>
export type TExperienceSection = TExperienceForm
export type TWorkExperiences = Partial<{
  isViewMode: boolean
}>
export type TExperienceView = TExperienceForm

// Course reference types
export type TReferenceForm = TBaseFillInForm<TCourseReference>
export type TReferenceSection = TReferenceForm
export type TCoursesReference = Partial<{
  isViewMode: boolean
}>
export type TReferenceView = TReferenceForm

// Education types
export type TEducationForm = TBaseFillInForm<TEducation>
export type TEducationSection = TEducationForm
export type TEducations = Partial<{
  isViewMode: boolean
}>
export type TEducationView = TEducationForm

// Degree types
export type TDegreeForm = TBaseFillInForm<TDegree>
export type TDegreeSection = TDegreeForm
export type TDegrees = Partial<{
  isViewMode: boolean
}>
export type TDegreeView = TDegreeForm

export type TPersonalInformationFill = Partial<{
  isViewMode: boolean
}>

export type TPersonalInformationForm = TBaseFillInForm<TPersonalInformation> &
  TPersonalInformationFill
