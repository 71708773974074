import {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Skeleton, Tooltip, Typography, message } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { t } from 'i18next'

import { Button, Input, Loading, SharedTable } from 'src/common'
import AddUpdatePackageManagementModal, {
  EPackageBenefits,
  EPackageBenefitsItem,
} from 'src/components/package-management/AddUpdatePackageManagementModal'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import {
  ICreateEditPackageInput,
  IPackage,
  IPackageParams,
} from 'src/interfaces/package-management'
import {
  RootState,
  packageActions,
  selectPackageLoading,
  useAppDispatch,
} from 'src/redux'
import {
  createPackageAction,
  deletePackageAction,
  getListPackageAction,
  getPackageBenefitsAction,
  getPackageByIdAction,
  updatePackageAction,
} from 'src/redux/actions/package-management'
import { formatCurrencyInput } from 'src/utils/priceFormatter'
import { flatten, uniq, uniqBy } from 'lodash'
import { formatDate } from '@utils'
import { EPackagePrivacy, FORMAT_DATE_TABLE, FORMAT_TIME_LONG } from '@configs'
import DeleteUserPackageModal from 'src/pages/package-management/user-package-management/DeleteUserPackageModal'

const UserPackageManagementPage = () => {
  const [open, setOpen] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [isSearching, setIsSearching] = useState(false)
  const debouncedValue = useDebounce<string | null>(searchValue, 800)
  const [sort, setSort] = useState<{ [key: string]: any }>({})
  const [selectedPackageId, setSelectedPackageId] = useState<number | null>(
    null
  )

  const dispatch = useAppDispatch()
  const {
    packages,
    totalPackages,
    pagePackages,
    limitPackages,
    packageBenefits,
    selectedPackage,
    loadings,
  } = useSelector((state: RootState) => state.package)

  const fmtPackageColumn = useMemo(() => {
    if (!packages) return []
    return packages?.map((item, index) => ({
      ...item,
      index: (Number(pagePackages) - 1) * Number(limitPackages) + index + 1,
    }))
  }, [limitPackages, packages, pagePackages])

  const getListPackageActionLoading = useSelector((state: RootState) =>
    selectPackageLoading(state, 'getListPackageAction')
  )

  const createPackageActionLoading = useSelector((state: RootState) =>
    selectPackageLoading(state, 'createPackageAction')
  )

  const updatePackageActionLoading = useSelector((state: RootState) =>
    selectPackageLoading(state, 'updatePackageAction')
  )

  const getPackageLoading = loadings.getPackageByIdActionLoading

  const onClickDeleteItem = (id: number) => {
    setOpenConfirmDeleteModal(true)
    setSelectedPackageId(id)
  }

  const onCancelDelete = () => {
    setOpenConfirmDeleteModal(false)
    setSelectedPackageId(null)
  }

  const getPackages = async (params?: IPackageParams) => {
    dispatch(
      getListPackageAction({
        page: +pagePackages,
        sort,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        ...(params || {}),
      })
    )
  }

  const getSortOrder = (sort: string) => {
    if (sort === 'asc') {
      return 'desc'
    }
    return 'asc'
  }

  const getPackagesBySearchValue = async () => {
    try {
      setIsSearching(true)
      await dispatch(
        getListPackageAction({
          page: 1,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
        })
      ).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error({
          content: error.message,
        })
      }
    } finally {
      setIsSearching(false)
    }
  }

  const getPackageById = async (id: number | string) => {
    dispatch(getPackageByIdAction(id))
  }

  const onEditPackage = async (id: number | string) => {
    getPackageById(id)
    setOpen(true)
  }

  const onCreatePackage = async (data: ICreateEditPackageInput) => {
    try {
      let response: any
      if (!selectedPackage?.id) {
        response = await dispatch(
          createPackageAction({
            ...data,
          })
        ).unwrap()
      } else {
        response = await dispatch(
          updatePackageAction({
            id: selectedPackage.id,
            payload: {
              ...data,
            },
          })
        ).unwrap()
      }

      if (response.success) {
        message.success({
          content: response.message,
        })

        getPackages()
        onClose()
      }
    } catch (error: any) {
      // message.error({
      //   content: error.errors?.length ? error.errors[0] : error.message,
      // })
    }
  }

  const onClose = () => {
    setOpen(false)
    dispatch(packageActions.setSelectedPackage())
    dispatch(packageActions.setSelectedPackage())
  }

  const onDeletePackage = async (replaceId: number) => {
    try {
      if (selectedPackageId) {
        const response = await dispatch(
          deletePackageAction({
            id: selectedPackageId,
            selectedPackageId: replaceId,
          })
        ).unwrap()
        if (response.success) {
          message.success({
            content: response.message,
          })
        }
        getPackages({
          page: packages.length === 1 ? +pagePackages - 1 : +pagePackages,
        })
      }
    } catch (error: any) {
      if (error.message) {
        message.error({
          content: error.message,
        })
      }
    } finally {
      setOpenConfirmDeleteModal(false)
    }
  }

  const columns = [
    {
      width: '8%',
      title: 'No',
      dataIndex: 'index',
      key: 'index',
      ellipsis: true,
      align: 'center',
    },
    {
      width: '20%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Name</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({ name: getSortOrder(sort.name) })
                getPackages({
                  sort: { name: getSortOrder(sort.name) },
                })
              }}
            >
              {sort.name === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      dataIndex: 'name',
      key: 'id',
      render: (e: string) => {
        return (
          <div className="flex items-center max-w-[460px]">
            <Typography.Text
              style={{
                width: 400,
                whiteSpace: 'nowrap',
              }}
              ellipsis={true}
            >
              {e}
            </Typography.Text>
          </div>
        )
      },
    },
    {
      width: '12.5%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Price (EUR)</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({
                  priceEur: getSortOrder(sort.priceEur),
                })
                getPackages({
                  sort: {
                    priceEur: getSortOrder(sort.priceEur),
                  },
                })
              }}
            >
              {sort.priceEur === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      dataIndex: 'priceEur',
      key: 'id',
      render: (e: string, record: { type: EPackagePrivacy }) => {
        return (
          <div className="flex items-center justify-center">
            {record.type === EPackagePrivacy.PUBLIC
              ? e === null
                ? 0
                : formatCurrencyInput(`${e}`)
              : 'N/A'}
          </div>
        )
      },
    },
    {
      width: '12.5%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Price (USD)</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({
                  priceUsd: getSortOrder(sort.priceUsd),
                })
                getPackages({
                  sort: {
                    priceUsd: getSortOrder(sort.priceUsd),
                  },
                })
              }}
            >
              {sort.priceUsd === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      dataIndex: 'priceUsd',
      key: 'id',
      render: (e: string, record: { type: EPackagePrivacy }) => {
        return (
          <div className="flex items-center justify-center">
            {record.type === EPackagePrivacy.PUBLIC
              ? e === null
                ? 0
                : formatCurrencyInput(`${e}`)
              : 'N/A'}
          </div>
        )
      },
    },
    {
      width: '10%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Type</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({ type: getSortOrder(sort.type) })
                getPackages({
                  sort: { type: getSortOrder(sort.type) },
                })
              }}
            >
              {sort.type === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      dataIndex: 'type',
      key: 'id',
      render: (e: string) => {
        return <div className="flex items-center justify-center">{e}</div>
      },
    },
    {
      width: '25%',
      dataIndex: 'createdAt',
      key: 'id',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Creation time</p>
          </div>
        )
      },
      render: (e: string) => {
        return (
          <div className="flex flex-col items-center justify-center">
            <p className="!mb-0 !pb-0">{formatDate(e, FORMAT_TIME_LONG)}</p>
            <p className="!mb-0 !pb-0">{formatDate(e, FORMAT_DATE_TABLE)}</p>
          </div>
        )
      },
    },
    {
      width: '25%',
      dataIndex: 'updatedAt',
      key: 'id',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Modification time</p>
          </div>
        )
      },
      render: (e: string) => {
        return (
          <div className="flex flex-col items-center justify-center">
            {!!e ? (
              <>
                <p className="!mb-0 !pb-0">{formatDate(e, FORMAT_TIME_LONG)}</p>
                <p className="!mb-0 !pb-0">
                  {formatDate(e, FORMAT_DATE_TABLE)}
                </p>
              </>
            ) : (
              'N/A'
            )}
          </div>
        )
      },
    },
    // {
    //   width: '10%',
    //   title: 'Privacy',
    //   dataIndex: 'type',
    //   key: 'id',
    //   render: (e: string) => {
    //     return (
    //       <div className="flex items-center justify-start capitalize">{e}</div>
    //     )
    //   },
    // },
    {
      width: '10%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Status</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({
                  isActive: getSortOrder(sort.isActive),
                })
                getPackages({
                  sort: {
                    isActive: getSortOrder(sort.isActive),
                  },
                })
              }}
            >
              {sort.isActive === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      dataIndex: 'isActive',
      key: 'id',
      render: (e: boolean) => (
        <StatusText $isActive={e}>{e ? 'Active' : 'Inactive'}</StatusText>
      ),
    },
    {
      width: '15%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Action</p>
          </div>
        )
      },
      key: 'id',
      dataIndex: ['id'],
      render: (id: number, record: IPackage, index: number) => (
        <div className="flex space-x-4 items-center justify-center">
          <EditOutlined
            className="text-lg font-light mr-2.5 cursor-pointer !text-[#184f64] hover:opacity-80"
            onClick={() => {
              onEditPackage(id)
            }}
          />
          <DeleteOutlined
            disabled={record.isActive}
            className={`text-lg font-light mr-2.5 ${
              record.isActive && 'opacity-40'
            } !text-[#DE3B40] cursor-pointer'
              }`}
            onClick={() => {
              !record.isActive && onClickDeleteItem(id)
            }}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    getPackages({ page: 1 })
  }, [])

  // useEffect(() => {
  //   dispatch(getPackageBenefitsAction())
  // }, [dispatch])

  useEffect(() => {
    debouncedValue !== null && getPackagesBySearchValue()
  }, [debouncedValue])

  return (
    <PackageManagementStyled>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        <div className="max-w-[500px] flex-1">
          <Input
            prefix={<SearchOutlined className="pl-2" />}
            placeholder={t('teacher:search_package_name_or_price')}
            value={searchValue === null ? '' : searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            suffix={isSearching ? <Loading /> : undefined}
          />
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              setOpen(true)
            }}
          >
            {t('teacher:create_new_package')}
          </Button>
        </div>
      </div>
      <div className="pb-[1.25rem]">
        {getListPackageActionLoading || isSearching ? (
          <Skeleton paragraph={{ rows: 4 }} />
        ) : (
          <SharedTable
            columns={columns as any}
            dataSource={fmtPackageColumn}
            paginationProps={{
              total: !!totalPackages ? +totalPackages : undefined,
              pageSize: +limitPackages || 10,
              current: +pagePackages,
              onChange(page) {
                getPackages({
                  page,
                })
              },
            }}
          />
        )}
      </div>

      {open && (
        <AddUpdatePackageManagementModal
          packageBenefits={packageBenefits}
          selectedPackage={selectedPackage}
          open={open}
          onClose={onClose}
          onSubmit={onCreatePackage}
          isLoading={!!getPackageLoading}
          isSubmitting={
            !!createPackageActionLoading || !!updatePackageActionLoading
          }
          // onSelectPackageId={onSelectPackageBenefitId}
        />
      )}
      {openConfirmDeleteModal && (
        <DeleteUserPackageModal
          open
          onConfirmDelete={onDeletePackage}
          onClose={onCancelDelete}
          currentPackageId={selectedPackageId ?? 0}
        />
      )}
    </PackageManagementStyled>
  )
}

export default UserPackageManagementPage

const PackageManagementStyled = styled('div')(() => {
  return {
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',

    '& .ant-table-wrapper': {
      margin: 0,
      '& .ant-table-content': {
        marginRight: 0,
      },
    },
  }
})

const StatusText = styled.span<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${(props) => (props.$isActive ? '#19ba8c' : '#DE3B40')};
`
