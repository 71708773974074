import { ITeacherRequestDetailData } from 'src/interfaces/teacher-request-management'
import { defaultFormValues, teacherInfoSchema } from './config'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import Skeleton from 'src/components/skeleton/Skeleton'
import { Card } from 'antd'
import styled from 'styled-components'
import { PersonalInformation } from './personal-information'
import { Educations } from './educations'
import { WorkExperiences } from './work-experiences'
import { CoursesReference } from './courses-reference'
import { SignatureSection } from './signature/SignatureSection'

interface IProps {
  teacherDetail: ITeacherRequestDetailData
  isLoadingTeacherDetail?: boolean
  isViewOnly?: boolean
  customHeader?: HTMLElement
  formCustomAction?: HTMLElement
  customFooter?: HTMLElement
}

export default function TeacherInfoForm({
  teacherDetail,
  isLoadingTeacherDetail = false,
  isViewOnly = false,
  customHeader,
  formCustomAction,
  customFooter,
}: IProps) {
  const methods = useForm<any>({
    resolver: zodResolver(teacherInfoSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
  })
  const { reset } = methods

  useEffect(() => {
    if (!Object.keys(teacherDetail)?.length) return

    const { specificInformation, personalInformation } = teacherDetail || {}

    const transformedSpecificInformation = {
      ...specificInformation,
      workExperience: specificInformation?.workExperience?.map(
        (experience) => ({
          ...experience,
          editable: false,
        })
      ),
      courseReference: specificInformation?.courseReference?.map(
        (reference) => ({
          ...reference,
          editable: false,
        })
      ),
      education: specificInformation?.education?.map((education) => ({
        ...education,
        degree: {
          file: {
            fileId: education?.degree?.fileId || 0,
            name: education?.degree?.name || '',
          },
          mediaFile: { ...education?.degree?.file },
        },
        isPresent: education?.isPresent || false,
        editable: false,
      })),
      teacherSignature: {
        ...specificInformation?.teacherSignature,
        editable: !specificInformation?.teacherSignature?.fileId,
        signaturePhoto: {
          ...specificInformation?.teacherSignature?.signaturePhoto,
        },
      },
    }

    const transformedPersonalInformation = {
      ...personalInformation,
      avatarId: personalInformation?.avatar?.id,
      editable: false,
    }

    reset({
      ...defaultFormValues,
      personalInformation: {
        ...defaultFormValues.personalInformation,
        ...transformedPersonalInformation,
      },
      specificInformation: {
        ...defaultFormValues.specificInformation,
        ...transformedSpecificInformation,
      },
    })
  }, [reset, teacherDetail])

  if (isLoadingTeacherDetail)
    return (
      <div className="p-6">
        <Skeleton row={3} column={2} style={{ height: 60 }} />
        <Skeleton row={4} column={1} style={{ height: 120 }} />
      </div>
    )

  return (
    <>
      {customHeader}
      <FormProvider {...methods}>
        <TeacherInfoFormContainerStyled className="!border-none flex items-center justify-center w-full !max-w-unset">
          <PersonalInformation isViewMode={isViewOnly} />
          <Educations isViewMode={isViewOnly} />
          <WorkExperiences isViewMode={isViewOnly} />
          {isViewOnly &&
            !teacherDetail?.specificInformation?.isNotReference && (
              <CoursesReference isViewMode={isViewOnly} />
            )}
          <SignatureSection />
        </TeacherInfoFormContainerStyled>

        {formCustomAction}
      </FormProvider>
      {customFooter}
    </>
  )
}

const TeacherInfoFormContainerStyled = styled(Card)`
  min-width: 70dvw;

  .ant-card-body {
    width: 100%;
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
