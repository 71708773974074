import { EUploadFileType, PATH_SUB_HABIT_CHALLENGE } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAppDispatch, useAppSelector } from '@redux'
import { Radio, Typography, message, Skeleton } from 'antd'
import { t } from 'i18next'
import { omit } from 'lodash'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input, SwitchButton, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { EMediaType } from 'src/interfaces/gallery'
import {
  IChallengeFormCreate,
  IChallengeItem,
} from 'src/interfaces/habit-management'
import {
  createChallengeAction,
  getListChallengerByIdAction,
  getListGoalsAction,
  updateChallengeAction,
} from 'src/redux/actions/habit-management'
import styled from 'styled-components'
import { RefinementCtx, z } from 'zod'
import { AppModal, ShareSelectInput, XCloseIcon } from 'src/components'

type ChallengeHabitModalProps = {
  isLoading: boolean
  title?: string
  content?: ReactNode
  item?: IChallengeItem | null
  changeLoading?: (value: boolean) => void
  noDefault: number
}
// const checkConfirmPassword = (
//   { applyFrom, applyTo }: { applyFrom: number; applyTo: number },
//   ctx: RefinementCtx
// ) => {
//   if (applyFrom >= applyTo) {
//     ctx.addIssue({
//       code: 'custom',
//       message: 'Apply to must be greater than Apply from',
//       path: ['applyTo'],
//     })
//   }
// }

const addCoreValueSchema = z.object({
  challenge: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(255, {
      message: t('error:question_length_error').replace(
        'Question',
        'Challenge'
      ) as string,
    }),
  challengeInDutch: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(255, {
      message: t('error:question_length_error').replace(
        'Question',
        'Challenge'
      ) as string,
    }),
  mediaId: z.number().min(1, { message: t('error:field_required') as string }),
  goalId: z.number().min(1, { message: t('error:field_required') as string }),
  description: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(2200, 'Description should not excess 2200 characters'),
  descriptionInDutch: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(2200, 'Description should not excess 2200 characters'),

  // applyFrom: z
  //   .number()
  //   .min(1, { message: "Apply From 's value must be between 1 and 10" })
  //   .max(10, { message: "Apply From 's value must be between 1 and 10" }),
  // applyTo: z
  //   .number()
  //   .min(1, { message: "Apply to 's value must be between 1 and 10" })
  //   .max(10, { message: "Apply to 's value must be between 1 and 10" }),
  status: z.boolean(),
  isDraft: z.boolean(),
})

const DetailChallenge = ({ changeLoading }: ChallengeHabitModalProps) => {
  const { clId } = useParams<any>()

  const [imageSrc, setImageSrc] = useState('')
  const [videoSrc, setVideoSrc] = useState('')
  const [imageId, setImageId] = useState(0)
  const [videoId, setVideoId] = useState(0)
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [defaultStatus, setDefaultStatus] = useState(false)
  const [isDraft, setIsDraft] = useState(true)
  const [isDraftV2, setIsDraftV2] = useState(false)

  const [isHasActiveMission, setIsHasActiveMission] = useState(false)
  const [basePublish, setBasePublish] = useState(false)

  const cacheData = useRef<any>({})
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  const [mode, setMode] = useState<Omit<EMediaType, 'ALL'>>(EMediaType.IMAGE)
  const originalModeRef = useRef<EMediaType | null>(null)
  const originMediaIdRef = useRef<number | null>(null)

  const handleModeChange = (e: any) => {
    setMode(e.target.value)
    if (e.target.value === originalModeRef.current) {
      clearErrors('mediaId')
      setValue('mediaId', originMediaIdRef.current)
      return
    }
    setValue('mediaId', 0)
  }

  const defaultValue: IChallengeFormCreate = {
    challenge: '',
    challengeInDutch: '',
    mediaId: 0,
    goalId: undefined,
    // applyFrom: 1,
    // applyTo: 2,
    description: '',
    descriptionInDutch: '',
    status: false,
    isDraft: true,
  }
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { isDirty, dirtyFields },
  } = useForm<IChallengeFormCreate>({
    defaultValues: defaultValue,
    // resolver: zodResolver(addCoreValueSchema.superRefine(checkConfirmPassword)),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const navigate = useNavigate()
  const getGoals = async () => {
    dispatch(
      getListGoalsAction({
        page: 1,
        limit: 100000,
        status: true,
        isChallenge: true,
      })
    )
  }
  const { goal } = useAppSelector((state) => state.habit)
  const getChallenge = async () => {
    if (!clId) {
      return
    }
    try {
      setIsLoading(true)
      const response: any = await dispatch(
        getListChallengerByIdAction(+clId)
      ).unwrap()

      if (
        ['image/png', 'image/jpeg', 'image/jpg'].includes(
          response.data?.media?.type
        )
      ) {
        setMode(EMediaType.IMAGE)
        setImageSrc(response.data?.media?.url)
        setImageId(response.data?.media?.id)
        originalModeRef.current = EMediaType.IMAGE
      } else {
        setMode(EMediaType.VIDEO)
        setVideoSrc(response.data?.media?.url)
        setVideoId(response.data?.media?.id)
        originalModeRef.current = EMediaType.VIDEO
      }

      setValue('challenge', response.data?.challenge ?? '')
      setValue('challengeInDutch', response.data?.challengeInDutch ?? '')
      setValue('mediaId', response.data?.mediaId ?? 0)
      setValue('goalId', response.data?.goalId ?? 0)
      // setValue('applyFrom', response.data?.applyFrom ?? 0)
      // setValue('applyTo', response.data?.applyTo ?? 0)
      setValue('status', response.data?.status ?? false)
      setValue('isDraft', response.data?.isDraft ?? false)
      setDefaultStatus(response.data?.status ?? false)
      setIsDraft(response.data?.isDraft ?? true)
      setValue('descriptionInDutch', response.data?.descriptionInDutch ?? '')
      if (!response.data?.isDraft) {
        setIsDraftV2(true)
      }
      if (response.data?.status) {
        setBasePublish(true)
      }
      setValue('description', response.data?.description ?? '')
      setIsHasActiveMission(
        response.data?.activeMissionsNumber > 0 ? true : false
      )
      originMediaIdRef.current = response.data?.media?.id
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onCloseConfirm = () => {
    setOpenConfirm(false)
    cacheData.current = {}
  }

  useEffect(() => {
    if (!goal || !goal?.goals) {
      getGoals()
    }
    if (clId) {
      getChallenge()
    }
  }, [])

  const onSave = async (data: IChallengeFormCreate) => {
    if ((!data.isDraft || basePublish !== data.status) && !openConfirm) {
      cacheData.current = data
      setOpenConfirm(true)
      return
    }
    try {
      setIsLoading(true)
      if (clId) {
        const response = await dispatch(
          updateChallengeAction({
            data: {
              ...data,
              mediaId: mode === EMediaType.VIDEO ? videoId : imageId,
            },
            id: +clId,
          })
        ).unwrap()
        if (response?.success) message.success(response.message)
      } else {
        const tmpData = omit(data, 'isDraft')
        const response = await dispatch(
          createChallengeAction({
            ...tmpData,
            mediaId: mode === EMediaType.VIDEO ? videoId : imageId,
          })
        ).unwrap()
        if (response?.success) message.success(response.message)
      }
      navigate(PATH_SUB_HABIT_CHALLENGE)
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <SingleBlogStyled className="px-20 bg-white">
      {isLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <>
          <div className="flex items-center justify-center ">
            <div>
              <h1 className="mb-5 mt-5 text-[32px] text-center ">
                {clId ? 'Challenge detail' : 'Create new challenge'}
              </h1>
            </div>
          </div>
          <div className="mt-6">
            <form>
              <div className=" flex flex-row gap-2  ">
                <Controller
                  name="challenge"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="Challenge EN"
                        name="challenge"
                        required
                        onChange={onChange}
                        containerClassName="w-[75%]"
                        style={{ marginTop: 10, width: '100%' }}
                        value={value}
                        errors={error?.message}
                        alignment="col"
                      />
                    )
                  }}
                />
                <Controller
                  name={'challengeInDutch'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="Challenge NL"
                        name="challengeInDutch"
                        required
                        onChange={onChange}
                        value={value}
                        containerClassName="w-[75%]"
                        style={{ marginTop: 10, width: '100%' }}
                        errors={error?.message}
                        alignment="col"
                      />
                    )
                  }}
                />
              </div>
              <div
                className=" flex flex-row gap-2 mt-5  "
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <Radio.Group
                    onChange={handleModeChange}
                    value={mode}
                    style={{ marginBottom: 8, borderRadius: 8 }}
                  >
                    <Radio.Button
                      style={{
                        borderRadius: 8,
                        marginRight: 8,
                      }}
                      className="challenge-tabs rounded-[8px]"
                      value={EMediaType.IMAGE}
                    >
                      Image
                    </Radio.Button>
                    <Radio.Button
                      style={{
                        borderRadius: 8,
                        borderWidth: 1,
                      }}
                      className="challenge-tabs"
                      value={EMediaType.VIDEO}
                    >
                      Video
                    </Radio.Button>
                  </Radio.Group>
                  <Typography>(Only image or video)</Typography>
                  {mode === EMediaType.VIDEO ? (
                    <Controller
                      name="mediaId"
                      control={control}
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <CustomDragger
                            label={mode.toLowerCase()}
                            name="mediaId"
                            id="mediaId"
                            containerClassName="mt-3 "
                            required
                            onLoadEnd={(data) => {
                              setValue('mediaId', data?.id ?? 0)
                              setVideoId(data?.id)
                              onChange(data?.id ?? 0)
                            }}
                            errors={error?.message}
                            initResource={videoSrc}
                            changeLoading={changeLoading}
                            allowFileTypes={[
                              'video/webm',
                              'video/mp4',
                              'video/x-m4v',
                              'video/quicktime',
                            ]}
                            limitFileSize={500}
                            labelClassName="w-1/2"
                            uploadType={EUploadFileType.VIDEO}
                          />
                        )
                      }}
                    />
                  ) : (
                    <Controller
                      name="mediaId"
                      control={control}
                      render={({
                        field: { onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <CustomDragger
                            label={mode.toLowerCase()}
                            name="mediaId"
                            id="mediaId"
                            containerClassName="mt-3 "
                            required
                            onLoadEnd={(data) => {
                              setValue('mediaId', data?.id ?? 0)
                              setImageId(data?.id)
                              onChange(data?.id ?? 0)
                            }}
                            errors={error?.message}
                            initResource={imageSrc}
                            changeLoading={changeLoading}
                            allowFileTypes={[
                              'image/png',
                              'image/jpeg',
                              'image/jpg',
                            ]}
                            limitFileSize={5}
                            labelClassName="w-1/2"
                            uploadType={EUploadFileType.IMAGE}
                          />
                        )
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  {goal && goal.goals && (
                    <Controller
                      name={'goalId'}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            data={goal?.goals?.map((item) => {
                              return {
                                value: item.id,
                                label:
                                  item.goal?.length > 30
                                    ? item.goal?.slice(0, 30) + '...'
                                    : item.goal,
                              }
                            })}
                            value={value}
                            label="Goal"
                            placeholder="Select a goal"
                            onChange={onChange}
                            errors={error?.message}
                            showArrow
                            required
                          />
                        )
                      }}
                    />
                  )}
                  <Controller
                    name="status"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <div className="grid  gap-3 mt-4">
                          <div className="col-span-2 flex items-center">
                            <p className="mb-0 mr-4">Status</p>
                          </div>
                          <div className="col-span-5">
                            <SwitchButton
                              size="default"
                              // disabled={defaultStatus}
                              checked={value}
                              onChange={(e) => {
                                onChange(e)
                              }}
                            />
                          </div>
                        </div>
                      )
                    }}
                  />
                  {!isDraftV2 && (
                    <Controller
                      name="isDraft"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <div className="grid  gap-3 mt-4">
                            <div className="col-span-2 flex items-center">
                              <p className="mb-0 mr-4">Draft</p>
                            </div>
                            <div className="col-span-5">
                              <SwitchButton
                                size="default"
                                disabled={
                                  !clId || !isDraft || !isHasActiveMission
                                }
                                checked={!clId || value}
                                onChange={(e) => {
                                  onChange(e)
                                }}
                              />
                            </div>
                          </div>
                        )
                      }}
                    />
                  )}
                </div>
              </div>
              <div className=" flex-row gap-2 mt-10 hidden">
                {/* <Controller
                  name={'applyFrom'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="From"
                        name="applyFrom"
                        required
                        onChange={(e) => {
                          onChange(parseInt(e.target.value) || 0)
                        }}
                        type="number"
                        min="1"
                        max="10"
                        value={value}
                        inputMode={'numeric'}
                        errors={error?.message}
                        alignment="col"
                        containerClassName="w-3/4"
                      />
                    )
                  }}
                />
                <Controller
                  name={'applyTo'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="To"
                        name="applyTo"
                        required
                        type="number"
                        onChange={(e) => {
                          onChange(parseInt(e.target.value) || 0)
                        }}
                        min="1"
                        max="10"
                        value={value}
                        errors={error?.message}
                        inputMode={'numeric'}
                        alignment="col"
                        containerClassName="w-3/4"
                      />
                    )
                  }}
                /> */}
              </div>
              <Controller
                name="description"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextArea
                      label="Description EN"
                      name="description"
                      required
                      onChange={onChange}
                      value={value}
                      errors={error?.message}
                      containerClassName="my-10"
                    />
                  )
                }}
              />
              <Controller
                name="descriptionInDutch"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextArea
                      label="Description NL"
                      name="descriptionInDutch"
                      required
                      onChange={onChange}
                      value={value}
                      errors={error?.message}
                      containerClassName="mt-3"
                    />
                  )
                }}
              />

              <div className="mt-10 pb-10">
                <div className="flex items-center mt-5 justify-center">
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="middle"
                    className="submit__btn login-btn"
                    loading={isLoading}
                    disabled={!isDirty}
                    onClick={handleSubmit(onSave)}
                  >
                    {clId ? 'Save' : 'Save as Draft'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
      <AppModal open={openConfirm} onClose={onCloseConfirm}>
        <div className="flex items-center justify-between ">
          <div>
            <h1 className="m-0 text-[20px]">
              Are you sure to publish this challenge?
            </h1>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onCloseConfirm} />
          </div>
        </div>
        <div className="mt-6">
          <span className="text-[16px]">
            You cannot edit the related mission and cannot back this challenge
            to draft.
          </span>
        </div>
        <div className="mt-6">
          <div className="flex items-center mt-5 justify-end">
            <Button
              type="ghost"
              size="middle"
              className="submit__btn login-btn mr-10"
              onClick={onCloseConfirm}
            >
              {'No'}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="middle"
              className="submit__btn login-btn"
              onClick={() => onSave(cacheData.current)}
            >
              {'Yes'}
            </Button>
          </div>
        </div>
      </AppModal>
    </SingleBlogStyled>
  )
}

export default DetailChallenge

const SingleBlogStyled = styled('div')(() => {
  // return {
  //   '.ant-tabs-nav': {
  //     marginBottom: 0,
  //     paddingLeft: '1.25rem',

  //     '& .ant-tabs-tab': {
  //       borderRadius: '0.75rem 0.75rem 0 0 !important',

  //       '&.ant-tabs-tab-active': {
  //         span: {
  //           color: '#009BB7',
  //           fontWeight: '500',
  //         },
  //       },
  //     },
  //   },

  //   '.ant-upload.ant-upload-select-picture-card': {
  //     width: '100%',
  //     height: 'auto',
  //   },
  // }
  return {}
})
