import { CONTENT_MANAGEMENT_VALIDATION } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  TEACHER_SEO_CONFIGURE_DEFAULT_VALUES,
  TeacherSEOConfigureSchema,
} from '@pages'
import {
  getTeacherSEOConfigureAction,
  updateTeacherSEOConfigureAction,
  useAppDispatch,
} from '@redux'
import { message } from 'antd'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, HookFormTextArea, Input, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { EMediaType, EMediaCategory, IMediaItem } from 'src/interfaces/gallery'

type TeacherSEOConfigureProps = {
  activeTab: string
}
export const TeacherSEOConfigure = (props: TeacherSEOConfigureProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    setError,
    watch,
    getValues,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues: TEACHER_SEO_CONFIGURE_DEFAULT_VALUES,
    resolver: zodResolver(TeacherSEOConfigureSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onChangeDesktopRef = useRef<any>(null)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getTeacherSEOConfigureAction()).unwrap()
      if (response) {
        setValue('metaTitle', response.metaTitle)
        setValue('keyWords', response.keywords)
        setValue('metaDescription', response.metaDescription)
        setValue('canonical', response.canonical)
        setValue('metaImage', response.metaImage)
      }
    }
    fetchData()
  }, [])

  const handleConfirmSubmit = async (data: any) => {
    try {
      const body = {
        metaTitle: data.metaTitle,
        keywords: data.keyWords,
        metaDescription: data.metaDescription,
        canonical: data.canonical,
        metaImage: data.metaImage,
        schemaMarkup: 'schemaMarkup',
      }

      const response = await dispatch(
        updateTeacherSEOConfigureAction(body)
      ).unwrap()

      if (response?.commonContent) {
        message.success({
          content: response?.message ?? t('common:save_success'),
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onReset = () => {
    setValue('metaTitle', '')
    setValue('keyWords', '')
    setValue('metaDescription', '')
    setValue('canonical', '')
    setValue('metaImage', '')
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }
  const onSelectMedia = (data: IMediaItem) => {
    console.log(data)
    if (setValue) {
      setValue('metaImage', data?.baseUrl ?? '')
    }
    onChangeDesktopRef.current(data?.baseUrl ?? '')

    setShowModalSelectMedia(false)
  }

  return (
    <div className="blog-content-m-right col-span-12 lg:col-span-4 rounded-lg">
      <div className="intro-y box p-5 bg-white rounded-lg">
        <div className="blog-content-top intro-y mt-2 flex flex-col items-center sm:flex-row">
          <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-between">
            Become Instructor
          </h2>
        </div>

        {/* Content */}

        <div className="blog-content-main intro-y mt-5">
          <div className="grid grid-cols-12 gap-4">
            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <Controller
                  name="metaTitle"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        name="Meta Title"
                        placeholder="Meta Title"
                        value={value}
                        onChange={(event) => {
                          onChange(event?.target?.value)
                        }}
                        required
                        label="Meta Title"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>

              <div className="col-span-6">
                <Controller
                  name="keyWords"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="Key words"
                        value={value}
                        onChange={onChange}
                        required
                        label="Key words"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>
            </div>

            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <HookFormTextArea
                  control={control}
                  controlName={`metaDescription`}
                  label="Meta Description"
                  placeholder="Meta Description"
                  rows={5}
                  required
                />
              </div>

              <div className="col-span-6">
                <Controller
                  name="canonical"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        placeholder="Canonical"
                        value={value}
                        onChange={onChange}
                        required
                        label="Canonical"
                        alignment="col"
                        errors={error?.message}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                      />
                    )
                  }}
                />
              </div>
            </div>

            <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
              <div className="col-span-6">
                <Controller
                  name="metaImage"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    onChangeDesktopRef.current = onChange
                    return (
                      <CustomDragger
                        labelClassName="w-full"
                        label="Meta Image"
                        name="metaImage"
                        id="metaImage"
                        containerClassName="mt-3"
                        alignment="col"
                        required
                        onLoadEnd={(data) => {
                          setValue('metaImage', data?.original ?? '')
                          onChange(data?.original ?? '')
                        }}
                        errors={error?.message}
                        initResource={value}
                        reset={getValues && getValues('metaImage') === ''}
                        limitFileSize={
                          CONTENT_MANAGEMENT_VALIDATION.MAX_IMAGE_SIZE
                        }
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        changeLoading={setIsModalLoading}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    )
                  }}
                />
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setShowModalSelectMedia(true)
                  }}
                  isLoadingMedia={isModalLoading}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="blog-content-top item-right-wrapper flex items-center justify-center gap-2 mt-5 pb-8">
          {/* <Button type="default" size="small" id="blog-form" onClick={onReset}>
            {t('common:reset')}
          </Button> */}

          <Button
            type="primary"
            size="small"
            id="blog-form"
            onClick={() => {
              handleSubmit(handleConfirmSubmit)()
            }}
            disabled={!isDirty}
          >
            {t('common:save')}
          </Button>
        </div>

        {showModalSelectMedia ? (
          <>
            <SelectMediaModal
              isOpen={showModalSelectMedia}
              onClose={onCloseModalSelectMedia}
              mediaType={EMediaType.IMAGE}
              category={EMediaCategory.CONTENT}
              onSelectMedia={onSelectMedia}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
