import { MultiSelectInput, ShareDateSelect } from '@components'
import {
  EPopupState,
  LIST_MOBILE_SCREEN_POPUP_DISPLAY,
  LIST_WEB_SCREEN_POPUP_DISPLAY,
  ModalConfirmPublish,
  TPopupType,
} from '@pages'
import { Popover, Switch, Typography } from 'antd'
import { t } from 'i18next'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button } from 'src/common'

type CreatePopupStepTwoProps = {
  popupDetail: TPopupType | null
  setPopupDetail: (data: TPopupType) => void
  handleSavePopup: (body: TPopupType) => void
  handlePrevStep: () => void
  popupId: string | undefined
  handleChangeStatusPopup?: (popupData: TPopupType | any) => void
}

export const CreatePopupStepTwo = (props: CreatePopupStepTwoProps) => {
  const {
    popupDetail,
    setPopupDetail,
    handleSavePopup,
    handlePrevStep,
    popupId,
    handleChangeStatusPopup,
  } = props

  const [selectedScreen, setSelectedScreen] = useState<string[] | undefined>([])
  const [selectedScreenMobile, setSelectedScreenMobile] = useState<
    string[] | undefined
  >([])
  const [startDate, setStartDate] = useState<any>(null)
  const [isEnabledInAllPage, setIsEnabledInAllPage] = useState<
    boolean | undefined
  >(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)

  const [errorRequiredWebScreen, setErrorRequiredWebScreen] =
    useState<string>('')
  const [errorRequiredMobileScreen, setErrorRequiredMobileScreen] =
    useState<string>('')

  useEffect(() => {
    if (popupDetail && popupId) {
      setSelectedScreen(popupDetail.displayOnWeb)
      setSelectedScreenMobile(popupDetail.displayOnMobile)
      setStartDate(
        popupDetail.scheduleDate ? moment(popupDetail.scheduleDate) : null
      )
      setIsEnabledInAllPage(popupDetail.isDisplayAllPage)
    }

    if (popupDetail && !popupId) {
      setSelectedScreen(popupDetail.displayOnWeb || [])
      setSelectedScreenMobile(popupDetail.displayOnMobile|| [])
      setStartDate(
        popupDetail.scheduleDate ? moment(popupDetail.scheduleDate) : null
      )
      setIsEnabledInAllPage(popupDetail.isDisplayAllPage || false)
    }
  }, [popupDetail, popupId])

  const handleSubmit = async (state?: EPopupState) => {
    if (
      !isEnabledInAllPage &&
      selectedScreen?.length === 0 &&
      selectedScreenMobile?.length === 0
    ) {
      setErrorRequiredWebScreen('Please select at least one screen')
      setErrorRequiredMobileScreen('Please select at least one screen')
      return
    }

    setErrorRequiredWebScreen('')
    setErrorRequiredMobileScreen('')

    const body: TPopupType = {
      ...popupDetail,
      isDisplayAllPage: isEnabledInAllPage,
      displayOnWeb: selectedScreen,
      displayOnMobile: selectedScreenMobile,
      scheduleDate: startDate,
    }
    
    const updateStateBody = {
      ...popupDetail,
      isDisplayAllPage: isEnabledInAllPage,
      displayOnWeb: selectedScreen,
      displayOnMobile: selectedScreenMobile,
      scheduleDate: startDate,
      state,
    }

    if (state === EPopupState.PUBLISHED && popupId) {
      delete body.scheduleDate
    }

    await setPopupDetail(body)
    await handleSavePopup(updateStateBody)
  }

  const handleConfirmModal = () => {
    if (!!popupId && popupDetail?.state !== EPopupState.PUBLISHED) {
      handleChangeStatusPopup?.(popupDetail)
      return
    }
    handleSubmit(EPopupState.PUBLISHED)
  }

  const handleNavigateToPreviousStep = async () => {
    const body: TPopupType = {
      ...popupDetail,
      isDisplayAllPage: isEnabledInAllPage,
      displayOnWeb: selectedScreen,
      displayOnMobile: selectedScreenMobile,
      scheduleDate: startDate,
    }

    await setPopupDetail(body)
    await handlePrevStep()
  }

  const SaveButtonContent = () => {
    return (
      <div>
        <div
          onClick={() => {
            if (popupId) {
              setOpenModalConfirm(true)
              setOpenPopover(false)
            } else {
              handleSubmit(EPopupState.PUBLISHED)
            }
          }}
          className="mb-2 flex justify-center"
        >
          <span className="text-sm hover:text-sky-900 cursor-pointer">
            Save and publish
          </span>
        </div>

        <div
          onClick={() => {
            handleSubmit(EPopupState.DRAFT)
          }}
          className="flex justify-center"
        >
          <span className="text-sm hover:text-sky-900 cursor-pointer">
            Save and draft
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
      {openModalConfirm ? (
        <ModalConfirmPublish
          open={true}
          onClose={() => setOpenModalConfirm(false)}
          onConfirm={handleConfirmModal}
        />
      ) : null}
      <div className="blog-content-m-right col-span-12 lg:col-span-4 rounded-lg">
        <div className="intro-y box p-5 bg-white rounded-lg">
          <div className="blog-content-top intro-y mt-2 flex flex-col items-center sm:flex-row">
            <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-between">
              Popup Behavior
            </h2>
          </div>

          {/* Content */}

          <div className="grid grid-cols-12 mt-2">
            {/* Display setting */}
            <div className="col-span-6">
              {/* All */}
              <div>
                <Typography.Text className="text-base font-medium">
                  Display Setting
                </Typography.Text>

                <div className="flex items-center gap-2 my-2">
                  <Typography.Text className="text-base font-base">
                    Auto display Pop-up on all pages
                  </Typography.Text>
                  <Switch
                    checked={isEnabledInAllPage}
                    onChange={() => {
                      setIsEnabledInAllPage(!isEnabledInAllPage)
                    }}
                  />
                </div>
              </div>
              {/* Select screen */}
              <div
                style={{
                  display: isEnabledInAllPage ? 'none' : 'block',
                }}
              >
                <Typography.Text className="text-base font-medium mb-2">
                  Or select page/screen to display
                </Typography.Text>

                <MultiSelectInput
                  label={'Web'}
                  required
                  options={LIST_WEB_SCREEN_POPUP_DISPLAY}
                  handleChange={(value) => {
                    setSelectedScreen(value)
                  }}
                  value={selectedScreen}
                  containerClassName="my-3"
                  placeholder="Select screen"
                  errors={errorRequiredWebScreen}
                />

                <MultiSelectInput
                  label={'Mobile'}
                  required
                  options={LIST_MOBILE_SCREEN_POPUP_DISPLAY}
                  handleChange={(value) => {
                    setSelectedScreenMobile(value)
                  }}
                  containerClassName="my-3"
                  placeholder="Select screen"
                  errors={errorRequiredMobileScreen}
                  value={selectedScreenMobile}
                />
              </div>
            </div>
          </div>

          {popupDetail?.state !== EPopupState.PUBLISHED && (
            <div className="grid grid-cols-12 mt-5">
              {/* Availability setting */}
              <div className="col-span-6">
                <Typography.Text className="text-base font-medium">
                  Availability Setting
                </Typography.Text>
                <ShareDateSelect
                  label="Start date"
                  containerClassName="mt-2"
                  minDate={moment().add(1, 'day').toDate()}
                  value={startDate}
                  onChange={(date) => {
                    setStartDate(date)
                  }}
                  inputClassName="w-full h-[46px]"
                  allowClear
                  format="DD MMM YYYY"
                />
              </div>
            </div>
          )}
        </div>

        <div className="blog-content-top item-right-wrapper flex items-center justify-center gap-2 mt-5 pb-8">
          {popupId ? (
            <div>
              <Button
                type="primary"
                size="small"
                id="blog-form"
                onClick={() => {
                  handleSubmit(popupDetail?.state as EPopupState)
                }}
                className="mr-2"
              >
                {t('common:save')}
              </Button>

              {popupDetail?.state !== EPopupState.PUBLISHED && (
                <Button
                  type="primary"
                  size="small"
                  id="blog-form"
                  onClick={() => setOpenModalConfirm(true)}
                >
                  {t('common:publish')}
                </Button>
              )}
            </div>
          ) : (
            <div>
              <Button
                disabled={false}
                type="default"
                size="small"
                id="blog-form"
                loading={false}
                className="mr-2"
                onClick={handleNavigateToPreviousStep}
              >
                {t('common:previousStep')}
              </Button>
              <Popover
                placement="bottom"
                open={openPopover}
                onOpenChange={(visible) => setOpenPopover(visible)}
                content={SaveButtonContent}
                trigger="click"
              >
                <Button
                  disabled={false}
                  type="primary"
                  size="small"
                  id="blog-form"
                  loading={false}
                >
                  {t('common:save')}
                </Button>
              </Popover>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
