import { Image, Typography } from 'antd'
import { Button, Input, TextArea } from 'src/common'
import { Fragment } from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import {
  EStudentAnswerResult,
  IGradingResultData,
} from 'src/pages/test-management/type'
import { useSelector } from 'react-redux'
import { RootState } from '@redux'
import { ShareSelectInput } from '@components'
import { STUDENT_ANSWER_RESULT_OPTION } from '../../config'

interface IGradingResultFormProps {
  gradedQuestionData: IGradingResultData
  onNextQuestion: () => void
}

export default function GradingResultForm({
  gradedQuestionData,
  onNextQuestion,
}: IGradingResultFormProps) {
  const {
    comment,
    gradingComment = [],
    isCorrected = false,
  } = gradedQuestionData || {}
  const { currentQuestion, gradingResult } = useSelector(
    (state: RootState) => state.testManagement
  )
  const { t } = useTranslation(['testManagement'])

  return (
    <form>
      <div>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: 700,
            marginBottom: 8,
          }}
        >
          {t('testManagement:grading')}
        </Typography>
      </div>

      <ShareSelectInput
        data={STUDENT_ANSWER_RESULT_OPTION}
        value={
          isCorrected ? EStudentAnswerResult.PASS : EStudentAnswerResult.FAIL
        }
        style={{ minWidth: 150, maxWidth: 150, marginBottom: 8 }}
        disabled
        showArrow
      />

      <div className="flex flex-col items-start justify-center mb-4">
        <Typography
          style={{
            fontSize: 18,
            fontWeight: 500,
            marginBottom: 8,
          }}
        >
          {t('testManagement:teacherComment')}
        </Typography>

        <TextArea
          readOnly
          value={comment || ''}
          style={{ minHeight: 120 } as any}
        />
      </div>

      <div>
        {gradingComment?.map((item, index) => {
          return (
            <Fragment key={item.id}>
              <div className="answer-item">
                <div className="flex flex-col relative answer-item answer mb-5 row gap-4 item-center">
                  <div className="flex flex-col">
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        marginBottom: 8,
                      }}
                    >
                      {t('testManagement:photoComment')}
                    </Typography>

                    <div className="flex justify-center pb-4">
                      <Image
                        preview={false}
                        className="w-full"
                        src={item?.media?.url || ''}
                        style={{
                          maxHeight: 540,
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        marginBottom: 8,
                      }}
                    >
                      {t('testManagement:photoCaption')}
                    </Typography>

                    <TextArea
                      readOnly
                      value={item?.caption || ''}
                      style={{ minHeight: 120 } as any}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          )
        })}
      </div>

      <div className="flex justify-end items-center">
        {currentQuestion !==
        gradingResult?.data?.finalTestSectionResults?.length ? (
          <Button
            icon={
              <ArrowRightOutlined
                style={{
                  marginRight: 8,
                }}
              />
            }
            type="primary"
            danger
            onClick={onNextQuestion}
            disabled={
              currentQuestion ===
              gradingResult?.data?.finalTestSectionResults?.length
            }
          >
            {t('testManagement:nextQuestion')}
          </Button>
        ) : null}
      </div>
    </form>
  )
}
