import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { ShareSelectInput } from '@components'
import {
  DAILY_ROUTINE_TYPE,
  DISPLAY_ACTIVE_INACTIVE_OPTIONS,
  DISPLAY_PUBLISH_DRAFT_OPTIONS,
  DISPLAY_YES_NO_OPTIONS,
  EActiveInActiveOptions,
  EDailyRoutineType,
  EHabitGoalStatus,
  EPublishDraftOptions,
  EUserPostStatus,
  EYesNoOptions,
  PATH_DAILY_ROUTINE_DETAIL,
  PATH_DAILY_ROUTINE_DETAIL_CREATE,
  PATH_EMAIL_CREATE,
  PATH_EMAIL_DETAIL,
  PATH_SUB_DAILY_ROUTINE,
  POST_DISPLAY_NAME,
  enumPageSize,
  enumTableTitleRoutineManagement,
  thunkActionLoading,
} from '@configs'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Card, Skeleton, Tooltip, message } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Input, Loading, SharedTable } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'

import queryString from 'query-string'
import {
  IGetRoutineParams,
  IRoutineItem,
} from 'src/interfaces/habit-management'
import {
  deleteDailyRoutineAction,
  duplicateDailyRoutineAction,
  getListDailyRoutineAction,
  getListGoalsAction,
} from 'src/redux/actions/habit-management'

type Props = {}

const RoutineManagement = (props: Props) => {
  const { t } = useTranslation(['error', 'common', 'habit'])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isModalLoading, setIsModalLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState<any>('')
  const { search } = useLocation()

  const debouncedValue = useDebounce<string | null>(searchText, 500)
  const [isSearching, setIsSearching] = useState(false)

  const [deleteRoutine, setDeleteRoutine] = useState<IRoutineItem | null>(null)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const { goal } = useAppSelector((state) => state.habit)

  const [filterParams, setFilterParams] = useState<
    | {
        isDefault?: boolean | undefined
        isDraft?: boolean | undefined
        goalId?: string | undefined
        status?: boolean | undefined
      }
    | undefined
  >()
  const isDefaultOptions = useMemo(() => {
    return Object.entries(DISPLAY_YES_NO_OPTIONS).map(([key, value]) => ({
      label: value,
      value: key,
    }))
  }, [])

  const statusOptions = useMemo(() => {
    return Object.entries(DISPLAY_ACTIVE_INACTIVE_OPTIONS).map(
      ([key, value]) => ({
        label: value,
        value: key,
      })
    )
  }, [])

  const publishDraftOptions = useMemo(() => {
    return Object.entries(DISPLAY_PUBLISH_DRAFT_OPTIONS).map(
      ([key, value]) => ({
        label: value,
        value: key,
      })
    )
  }, [])

  const onDuplicateChallenge = async (id: number) => {
    try {
      const response = await dispatch(duplicateDailyRoutineAction(id))
      message.success('Duplicate daily routine information successfully')
    } catch (error) {
    } finally {
      getRoutines()
    }
  }
  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleRoutineManagement.NO}</p>
          </div>
        )
      },
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '5%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: false,
    },
    {
      title: enumTableTitleRoutineManagement.TITLE,
      dataIndex: 'title',
      key: 'title',
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,

      // width: '25%',
    },
    {
      title: enumTableTitleRoutineManagement.TITLE_IN_DUTCH,
      dataIndex: 'titleInDutch',
      key: 'titleInDutch',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      // width: '25%',
    },
    {
      title: enumTableTitleRoutineManagement.TYPE,
      dataIndex: 'goal',
      key: 'goal',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
      width: '10%',
      render: (record: any, item: any) => {
        return (
          <div className="flex w-full items-center justify-center">
            <a
              className="m-0 underline text-center"
              onClick={() => {
                onChangeType(item?.goal?.id)
              }}
            >
              {record?.goal}
            </a>
          </div>
        )
      },
    },
    {
      title: enumTableTitleRoutineManagement.IS_DEFAULT,
      dataIndex: 'isDefault',
      key: 'isDefault',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
    },
    {
      title: 'Number of jobs',
      key: 'numberOfTasks',
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
      sorter: (a: any, b: any) => a.missionsNumber - b.missionsNumber,
      render: (record: IRoutineItem) => (
        <div className="flex w-full items-center justify-center">
          <span
            className="m-0 underline text-center text-blue-500"
            onClick={() => {
              if (record.id) {
                navigate(`${PATH_SUB_DAILY_ROUTINE}/${record.id}/jobs`, {
                  state: {
                    isRoutinePublished: !record?.isDraft,
                  },
                })
              }
            }}
          >
            {record.jobsNumber || 0}
          </span>
        </div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      width: '10%',
      sorter: (a: any, b: any) => a.status - b.status,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      render: (challenge: IRoutineItem) => (
        <div className="flex  w-full">
          <p className={`m-0 bold ${!!challenge?.deletedAt && 'text-danger'}`}>
            {challenge?.deletedAt
              ? 'Deleted'
              : challenge?.status
              ? 'Active'
              : 'InActive'}
          </p>
        </div>
      ),
    },
    {
      title: enumTableTitleRoutineManagement.PUBLISHED_DRAFT,
      dataIndex: 'publishedDraft',
      key: 'publishedDraft',
      showSorterTooltip: false,
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
    {
      title: enumTableTitleRoutineManagement.ACTION,
      key: 'action',
      dataIndex: 'action',
      width: '10%',
      align: 'center',
      render: (record: IRoutineItem) => (
        <div className="flex  flex-col  ml-5  justify-center items-center w-full">
          <div className="flex gap-4   justify-start w-full">
            <Tooltip title={t('common:edit')}>
              {!record?.deletedAt ? (
                <EditOutlined
                  className="text-lg font-light cursor-pointer !text-[#184f64] hover:opacity-80"
                  onClick={() => {
                    navigate(
                      `${PATH_DAILY_ROUTINE_DETAIL.replace(
                        ':routineId',
                        record?.id.toString()
                      )}`,
                      {
                        state: {
                          query: search,
                        },
                      }
                    )
                  }}
                />
              ) : (
                <Tooltip title={t('common:view_detail')}>
                  <EyeOutlined
                    className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
                    onClick={() => {
                      // setShowModal(true)
                      navigate(
                        `${PATH_DAILY_ROUTINE_DETAIL.replace(
                          ':routineId',
                          record?.id.toString()
                        )}`,
                        {
                          state: {
                            deleted: true,
                            query: search,
                          },
                        }
                      )
                    }}
                  />
                </Tooltip>
              )}
            </Tooltip>
          </div>
          {!record?.deletedAt && (
            <div className="flex  mt-4 gap-4 justify-start w-full">
              <Tooltip title={'Duplicate routine'}>
                <CopyOutlined
                  className={`text-lg font-light cursor-pointer  hover:opacity-80`}
                  onClick={() => {
                    onDuplicateChallenge(record.id)
                  }}
                />
              </Tooltip>
              <Tooltip title={t('common:delete')}>
                <DeleteOutlined
                  className={`text-lg font-light cursor-pointer  hover:opacity-80 !text-red-600
                }`}
                  disabled={isModalLoading}
                  onClick={() => {
                    if (record.isDraft) {
                      onDeleteRoutine(record)
                    } else {
                      setDeleteRoutine(record)
                      setOpenConfirmDeleteModal(true)
                    }
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      ),
    },
  ]

  const { routine } = useAppSelector((state: RootState) => state.habit)

  const isGetListRoutineLoading = useAppSelector(
    (state: RootState) =>
      state.habit?.loadings[thunkActionLoading.GET_LIST_DAILY_ROUTINES_LOADING]
  )

  const isDeleteRoutineLoading = useAppSelector(
    (state: RootState) =>
      state.habit?.loadings[thunkActionLoading.DELETE_DAILY_ROUTINE_LOADING]
  )

  const getRoutines = async (params?: IGetRoutineParams) => {
    const localURlQuery: any = {
      page: params?.page ?? 1,
      search: params?.search,
    }
    if (debouncedValue) {
      localURlQuery.search = debouncedValue.trim() || ''
    }
    const stringifyQuery = queryString.stringify(localURlQuery)
    setSearchParams(stringifyQuery)
    dispatch(
      getListDailyRoutineAction({
        page: localURlQuery.page ?? page,
        limit: 10,
        search: params?.search
          ? params?.search
          : debouncedValue !== null && debouncedValue.trim()
          ? debouncedValue.trim()
          : undefined,
        ...(params || {}),
      })
    )
  }
  const getRoutinesBySearchValue = async () => {
    const localURlQuery: any = {
      page: 1,
    }
    if (debouncedValue) {
      localURlQuery.search = debouncedValue.trim() || ''
    }
    const stringifyQuery = queryString.stringify(localURlQuery)
    setSearchParams(stringifyQuery)
    try {
      setIsSearching(true)
      await dispatch(
        getListDailyRoutineAction({
          page: 1,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
          ...filterParams,
        })
      ).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setIsSearching(false)
    }
  }

  const onChangeRecommendationRoutine = (value: string) => {
    switch (value) {
      case EYesNoOptions.ALL:
        setFilterParams((pre) => ({
          ...(filterParams || {}),
          isDefault: undefined,
        }))
        getRoutines({ page: 1, ...filterParams, isDefault: undefined })
        return
      case EYesNoOptions.YES:
        setFilterParams((pre) => ({
          ...filterParams,
          isDefault: true,
        }))
        getRoutines({ page: 1, ...filterParams, isDefault: true })
        return
      case EYesNoOptions.NO:
        setFilterParams((pre) => ({
          ...filterParams,
          isDefault: false,
        }))
        getRoutines({ page: 1, ...filterParams, isDefault: false })
        return
      default:
        return
    }
  }

  const onChangeType = (value: string) => {
    if (!value) {
      setFilterParams((pre) => ({
        ...filterParams,
        goalId: undefined,
      }))
      getRoutines({ page: 1, ...filterParams, goalId: undefined })
      return
    } else {
      setFilterParams((pre) => ({
        ...filterParams,
        goalId: value,
      }))
      getRoutines({ page: 1, ...filterParams, goalId: value })
      return
    }
  }

  const onChangeStatus = (value: string) => {
    switch (value) {
      case EActiveInActiveOptions.ALL:
        setFilterParams((pre) => ({
          ...(filterParams || {}),
          status: undefined,
        }))
        getRoutines({ page: 1, ...filterParams, status: undefined })
        return
      case EActiveInActiveOptions.ACTIVE:
        setFilterParams((pre) => ({
          ...filterParams,
          status: true,
        }))
        getRoutines({ page: 1, ...filterParams, status: true })
        return
      case EActiveInActiveOptions.INACTIVE:
        setFilterParams((pre) => ({
          ...filterParams,
          status: false,
        }))
        getRoutines({ page: 1, ...filterParams, status: false })
        return
      default:
        return
    }
  }

  const onChangePublishDraft = (value: string) => {
    switch (value) {
      case EPublishDraftOptions.ALL:
        setFilterParams((pre) => ({
          ...(filterParams || {}),
          isDraft: undefined,
        }))
        getRoutines({ page: 1, ...filterParams, isDraft: undefined })
        return
      case EPublishDraftOptions.PUBLISHED:
        setFilterParams((pre) => ({
          ...filterParams,
          isDraft: false,
        }))
        getRoutines({ page: 1, ...filterParams, isDraft: false })
        return
      case EPublishDraftOptions.DRAFT:
        setFilterParams((pre) => ({
          ...filterParams,
          isDraft: true,
        }))
        getRoutines({ page: 1, ...filterParams, isDraft: true })
        return
      default:
        return
    }
  }

  const onDeleteRoutine = async (record?: IRoutineItem) => {
    setIsModalLoading(true)
    try {
      if (record) {
        const response = await dispatch(
          deleteDailyRoutineAction(record?.id as number)
        ).unwrap()
        if (response?.success) message.success(response.message)
      } else if (deleteRoutine) {
        const response = await dispatch(
          deleteDailyRoutineAction(deleteRoutine?.id as number)
        ).unwrap()
        if (response?.success) message.success(response.message)
      }
      getRoutines()
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setDeleteRoutine(null)
      setOpenConfirmDeleteModal(false)
      setIsModalLoading(false)
    }
  }

  const onCloseDeleteModal = () => {
    setOpenConfirmDeleteModal(false)
    setDeleteRoutine(null)
  }

  const getGoals = async () => {
    dispatch(
      getListGoalsAction({
        page: 1,
        limit: 100000,
        isDailyRoutine: true,
      })
    )
  }

  useEffect(() => {
    return () => {
      setFilterParams(undefined)
    }
  }, [])
  useEffect(() => {
    getGoals()
    if (search) {
      setSearchParams(search)
      const parsedQuery = queryString.parse(search)
      getRoutines(parsedQuery)
      return
    }
    getRoutines({ page: 1 })
  }, [search])

  useEffect(() => {
    if (!!debouncedValue) {
      getRoutinesBySearchValue()
    }
  }, [debouncedValue])

  return (
    <Card>
      <div className="flex justify-between py-[1.25rem] gap-5 w-full items-start">
        <div className=" flex gap-8 max-w-full flex-wrap ">
          <Input
            prefix={<SearchOutlined className="pl-2" />}
            placeholder="Search daily routine"
            value={searchText === null ? '' : searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            suffix={debouncedValue && isSearching ? <Loading /> : undefined}
            className="!pr-[0]"
            containerClassName="!w-[400px]"
          />

          {/* isDefault filter */}
          <div className="filter-status flex justify-center items-center gap-1 relative">
            <div className="status-label w-[164px]">
              {enumTableTitleRoutineManagement.IS_DEFAULT}
            </div>
            <ShareSelectInput
              placeholder={'Select value'}
              onChange={onChangeRecommendationRoutine}
              data={isDefaultOptions}
              showArrow
              // className="!w-[72px]"
            />
          </div>

          {/* type filter */}
          <div className="filter-status flex justify-center items-center gap-1 relative">
            <div className="status-label">
              {enumTableTitleRoutineManagement.TYPE}
            </div>
            <ShareSelectInput
              placeholder={enumTableTitleRoutineManagement.TYPE}
              onChange={onChangeType}
              value={filterParams?.goalId}
              showArrow
              data={[
                {
                  value: 0,
                  label: 'All',
                },
                ...(goal?.goals?.map((item) => {
                  return {
                    value: item.id,
                    label: item.goal,
                  }
                }) ?? []),
              ]}
              className="!w-max min-w-[180px]"
            />
          </div>

          {/* Status filter */}
          <div className="filter-status flex justify-center items-center gap-1 relative">
            <div className="status-label">
              {enumTableTitleRoutineManagement.STATUS}
            </div>
            <ShareSelectInput
              placeholder="Status"
              onChange={onChangeStatus}
              data={statusOptions}
              className="!w-[97px]"
              showArrow
            />
          </div>
          {/* publishDraft filter */}
          <div className="filter-status flex justify-center items-center gap-1 relative">
            <div className="status-label">
              {enumTableTitleRoutineManagement.PUBLISHED_DRAFT}
            </div>
            <ShareSelectInput
              placeholder={'Select value'}
              onChange={onChangePublishDraft}
              data={publishDraftOptions}
              className="!w-[124px]"
              showArrow
            />
          </div>
        </div>

        {/* ----Filter---- */}
        <div>
          <Button
            type="primary"
            onClick={() => {
              // Create new email content action
              navigate(PATH_DAILY_ROUTINE_DETAIL_CREATE)
            }}
          >
            {'New Routine'}
          </Button>
        </div>
      </div>
      {isGetListRoutineLoading ? (
        <Skeleton
          paragraph={{ rows: 10 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={routine?.routines?.map((item, index) => {
            return {
              ...item,
              key: item?.id,
              no: index + 1,
              title: item?.title ? item?.title : `--`,
              titleInDutch: item?.titleInDutch ? item?.titleInDutch : `--`,
              type: item?.type
                ? DAILY_ROUTINE_TYPE[item?.type as EDailyRoutineType]
                : `--`,
              isDefault: item?.isDefault
                ? DISPLAY_YES_NO_OPTIONS[EYesNoOptions.YES]
                : DISPLAY_YES_NO_OPTIONS[EYesNoOptions.NO],
              numberOfTasks: item?.jobsNumber,
              status: item?.status,
              publishedDraft: item?.isDraft
                ? POST_DISPLAY_NAME[EUserPostStatus.FRIEND]
                : POST_DISPLAY_NAME[EUserPostStatus.PUBLIC],
              record: item,
              action: item,
            }
          })}
          sticky
          paginationProps={{
            total: routine?.total ?? 0,
            pageSize: enumPageSize.LIMIT_10,
            showSizeChanger: false,
            current: routine?.page ?? 1,
            onChange(page) {
              getRoutines({ page: page, ...filterParams })
              setPage(page)
            },
          }}
        />
      )}
      {openConfirmDeleteModal ? (
        <ConfirmDeleteModal
          title="Do you  want to delete this daily routine"
          content="This daily routine is published, deletion will affect to others participating in this!"
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading || !!isDeleteRoutineLoading}
          onDelete={() => {
            deleteRoutine && onDeleteRoutine(deleteRoutine)
          }}
          highlight
          onClose={onCloseDeleteModal}
        />
      ) : null}
    </Card>
  )
}

export default RoutineManagement
