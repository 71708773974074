import {
  DAILY_ROUTINE_TYPE,
  EUploadFileType,
  PATH_SUB_DAILY_ROUTINE,
  enumTableTitleRoutineManagement,
  thunkActionLoading,
} from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { RouterParams } from '@interfaces'
import { useAppDispatch, useAppSelector } from '@redux'
import { Card, Checkbox, Skeleton, Typography, message } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { AppModal, ShareSelectInput, XCloseIcon } from '@components'
import { t } from 'i18next'
import { Button, Input, SwitchButton, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { IRoutineItem, TRoutineFormData } from 'src/interfaces/habit-management'
import {
  getDetailDailyRoutineAction,
  getListGoalsAction,
  postDetailDailyRoutineAction,
  putDetailDailyRoutineAction,
} from 'src/redux/actions/habit-management'
import { z } from 'zod'

type Props = {}

const routineSchema = z.object({
  goalId: z.number().positive(t('error:field_required')),
  title: z
    .string()
    .trim()
    .nonempty(t('error:field_required'))
    .max(255, t('error:routine_title_length_error')),
  titleInDutch: z
    .string()
    .trim()
    .nonempty(t('error:field_required'))
    .max(255, t('error:routine_title_length_error')),
  description: z
    .string()
    .trim()
    .nonempty(t('error:field_required'))
    .max(2000, t('error:routine_description_length_error')),
  descriptionInDutch: z
    .string()
    .trim()
    .nonempty(t('error:field_required'))
    .max(2000, t('error:routine_description_length_error')),
  isDefault: z.boolean(),
  status: z.boolean(),
  isDraft: z.boolean(),
  mediaId: z.number().min(1, { message: t('error:field_required') as string }),
})

const defaultValue = {
  // type: '',
  title: '',
  titleInDutch: '',
  description: '',
  descriptionInDutch: '',
  isDefault: false,
  status: false,
  isDraft: true,
  mediaId: 0,
}

const RoutinePage = (props: Props) => {
  const { routineId } = useParams<RouterParams['RoutineDetail']>()
  const { state } = useLocation()
  const isEdit = Boolean(routineId)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isDraft, setIsDraft] = useState(true)

  const cacheData = useRef<any>({})

  const [imageSource, setImageSource] = useState<string | null>(null)
  const [localData, setLocalData] = useState<IRoutineItem>()
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  const isGetDetailRoutineLoading = useAppSelector(
    (state) =>
      state.habit?.loadings[thunkActionLoading.GET_DETAIL_DAILY_ROUTINE_LOADING]
  )

  const { goal } = useAppSelector((state) => state.habit)

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm<TRoutineFormData>({
    defaultValues: defaultValue,
    resolver: zodResolver(routineSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_type) {
      return { message: t('error:field_required') }
    }

    return { message: ctx.defaultError }
  }

  z.setErrorMap(customErrorMap)
  const typeOptions = () => {
    const tempOptions =
      goal?.goals?.map((item) => ({
        value: item.id,
        label: item.goal,
      })) ?? []
    return tempOptions
  }

  const onCloseConfirm = () => {
    setOpenConfirm(false)
    cacheData.current = {}
  }

  const onSave = async (data: TRoutineFormData) => {
    if (!data?.goalId) {
      setError('goalId', {
        type: 'invalid_type',
        message: t('error:field_required') as string,
      })
    }

    // if (!data?.groupId) {
    //   setError('groupId', {
    //     type: 'invalid_type',
    //     message: t('error:field_required') as string,
    //   })
    // }
    const { isDraft, ...passData } = data

    if (!isDraft && !openConfirm) {
      cacheData.current = data
      setOpenConfirm(true)
      return
    }

    try {
      let response: any

      if (!isEdit) {
        response = await dispatch(
          postDetailDailyRoutineAction({
            ...passData,
          })
        ).unwrap()
      } else {
        response = await dispatch(
          putDetailDailyRoutineAction({
            ...data,
            id: routineId ?? '',
          })
        ).unwrap()
      }

      if (response?.success) {
        message.success(response?.message)
        navigate(PATH_SUB_DAILY_ROUTINE + (state?.query ?? '?page=1'))
      }

      return
    } catch (error: any) {
      message.error(error && error?.message)
    }
  }

  const getGoals = async () => {
    dispatch(
      getListGoalsAction({
        page: 1,
        limit: 100000,
        isDailyRoutine: true,
        status: true,
      })
    )
  }

  useEffect(() => {
    const getDetail = async () => {
      if (!routineId) return
      try {
        const response = await dispatch(
          getDetailDailyRoutineAction(+routineId)
        ).unwrap()
        if (response?.success) {
          const data = response?.data
          setLocalData(data)
          setImageSource(data?.media?.url ?? '')
          setValue('goalId', data?.goalId)
          setValue('title', data?.title)
          setValue('titleInDutch', data?.titleInDutch)
          setValue('description', data?.description)
          setValue('descriptionInDutch', data?.descriptionInDutch)
          setValue('status', data?.status)
          setValue('isDraft', data?.isDraft)
          setValue('isDefault', data?.isDefault)
          setValue('mediaId', data?.media?.id ?? 0)
          setIsDraft(data?.isDraft)
        }
      } catch (error: any) {
        message.error(error && error?.message)
      }
    }
    if (isEdit) {
      getDetail()
    }
  }, [routineId])

  useEffect(() => {
    getGoals()
  }, [routineId])

  return (
    <Card className="bg-white p-100 min-h-[600px]">
      <Typography className="text-[24px] font-semibold w-full text-center text-black mb-5">
        {isEdit ? t('habit:header_detail') : t('habit:header_create')}
      </Typography>

      {isGetDetailRoutineLoading ? (
        <Skeleton
          paragraph={{ rows: 10 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <div>
          <Controller
            name="goalId"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <ShareSelectInput
                  placeholder={'Type'}
                  required
                  label={'Type'}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  disabled={state?.deleted}
                  data={typeOptions()}
                  value={value}
                  errors={error?.message}
                  showArrow
                  // containerClassName="!mb-5"
                />
              )
            }}
          />

          <Controller
            name={'title'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label={t('habit:form_title')}
                  placeholder={t('habit:form_title')}
                  name="title"
                  required
                  onChange={onChange}
                  value={value}
                  errors={error?.message}
                  alignment="col"
                  disabled={state?.deleted}
                  containerClassName="!mt-5"
                />
              )
            }}
          />
          <Controller
            name={'titleInDutch'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label={t('habit:form_title_in_dutch')}
                  placeholder={t('habit:form_title_in_dutch')}
                  name="titleInDutch"
                  required
                  onChange={onChange}
                  value={value}
                  errors={error?.message}
                  alignment="col"
                  disabled={state?.deleted}
                  containerClassName="!mt-5"
                />
              )
            }}
          />

          {/* Description */}

          <Controller
            name={'description'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextArea
                  label={t('habit:form_description')}
                  placeholder={t('habit:form_description')}
                  onChange={onChange}
                  required
                  value={value}
                  style={{ marginTop: 10, width: '100%' }}
                  disabled={state?.deleted}
                  errors={error?.message}
                  containerClassName="!mb-5"
                  labelClassName="mb-2 inline-block"
                  autoSize={{ minRows: 4, maxRows: 16 }}
                />
              )
            }}
          />

          <Controller
            name={'descriptionInDutch'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextArea
                  label={t('habit:form_description_in_dutch')}
                  placeholder={t('habit:form_description_in_dutch')}
                  onChange={onChange}
                  required
                  disabled={state?.deleted}
                  value={value}
                  style={{ marginTop: 10, width: '100%' }}
                  errors={error?.message}
                  containerClassName="!mb-5"
                  labelClassName="mb-2 inline-block"
                  autoSize={{ minRows: 4, maxRows: 16 }}
                />
              )
            }}
          />

          <div className="routine-media-option flex gap-5 items-center justify-between">
            <div className="wrapper-upload w-1/2">
              <Controller
                name="mediaId"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => {
                  return (
                    <CustomDragger
                      label={t('habit:form_label_upload')}
                      name="mediaId"
                      id="mediaId"
                      required
                      containerClassName="w-full"
                      onLoadEnd={(data) => {
                        setValue('mediaId', data?.id ?? 0, {
                          shouldDirty: true,
                        })
                        clearErrors('mediaId')
                        setImageSource(data?.url ?? null)
                      }}
                      disabled={state?.deleted}
                      errors={error?.message}
                      initResource={imageSource as string}
                      changeLoading={(loading) => {
                        //
                      }}
                      // alignment="row"
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      limitFileSize={5}
                      labelClassName="w-full"
                      uploadType={EUploadFileType.IMAGE}
                    />
                  )
                }}
              />
            </div>
            <div className="wrapper-routine-option w-1/2 flex flex-col justify-between">
              <Controller
                name="status"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <div className="grid  gap-3 mt-4">
                      <div className="col-span-2 flex items-center">
                        <p className="mb-0 mr-4">
                          {enumTableTitleRoutineManagement.STATUS}
                        </p>
                      </div>
                      <div className="col-span-5">
                        <SwitchButton
                          size="default"
                          // disabled={defaultStatus}
                          checked={value}
                          disabled={state?.deleted}
                          onChange={(e) => {
                            onChange(e)
                          }}
                        />
                      </div>
                    </div>
                  )
                }}
              />
              <Controller
                name="isDraft"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <div className="grid  gap-3 mt-4">
                      <div className="col-span-2 flex items-center">
                        <p className="mb-0 mr-4">{t('common:draft')}</p>
                      </div>
                      <div className="col-span-5">
                        <SwitchButton
                          size="default"
                          disabled={!isEdit || !isDraft || state?.deleted}
                          checked={!isEdit || value}
                          onChange={(e) => {
                            onChange(e)
                          }}
                        />
                      </div>
                    </div>
                  )
                }}
              />
              <Controller
                name="isDefault"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <div className="flex gap-3 mt-4">
                      <Checkbox
                        disabled={
                          !routineId || localData?.isDefault || state?.deleted
                        }
                        checked={value}
                        onChange={(e) => {
                          onChange(e)
                        }}
                      />
                      <p className="mb-0 mr-4">
                        {t('habit:form_make_as_default_routine')}
                      </p>
                    </div>
                  )
                }}
              />
            </div>
          </div>

          <div className="flex mt-10 pb-10 items-center gap-[40px] justify-center">
            <Button
              type="primary"
              size="middle"
              className="submit__btn login-btn"
              loading={false}
              disabled={!isDirty || state?.deleted}
              onClick={() => {
                handleSubmit(onSave)()
              }}
            >
              {watch('isDraft') ? t('common:save_draft') : t('common:save')}
            </Button>
          </div>
        </div>
      )}
      <AppModal open={openConfirm} onClose={onCloseConfirm}>
        <div className="flex items-center justify-between ">
          <div>
            <h1 className="m-0 text-[20px]">
              Are you sure to publish this routine?
            </h1>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onCloseConfirm} />
          </div>
        </div>
        <div className="mt-6">
          <span className="text-[16px]">
            You cannot edit the related job and cannot back this routine to
            draft.
          </span>
        </div>
        <div className="mt-6">
          <div className="flex items-center mt-5 justify-end">
            <Button
              type="ghost"
              disabled={state?.deleted}
              size="middle"
              className="submit__btn login-btn mr-10"
              onClick={onCloseConfirm}
            >
              {'No'}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="middle"
              disabled={state?.deleted}
              className="submit__btn login-btn"
              onClick={() => onSave(cacheData.current)}
            >
              {'Yes'}
            </Button>
          </div>
        </div>
      </AppModal>
    </Card>
  )
}

export default RoutinePage
