import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { ETypeStatic, StatisticItem } from './StatisticItem'
import {
  getIncomeDataAction,
  getStatisticChartIncome,
  getStatisticChartUSer,
  getSubscriberDataAction,
  getUsersDataAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { EStepChart } from '@configs'
import { getCurrentYear, getPreviousMonthDate } from '@utils'
import { dashboardAPI } from '@api'
import { handleAPIError } from 'src/utils/handleApiError'

export const Statistic = () => {
  const dispatch = useAppDispatch()

  const [data, setData] = useState({
    users: 0,
    orders: 0,
    subscribers: 0,
    newSubscribers: 0,
    income: '0',
    totalInCome: '0',
    totalUser: 0,
  })

  const getData = async () => {
    try {
      const subscriberStatus = await dispatch(
        getSubscriberDataAction()
      ).unwrap()
      const usersStatus = await dispatch(getUsersDataAction()).unwrap()
      const incomeStatus = await dispatch(getIncomeDataAction()).unwrap()
      const userChart = await dispatch(
        getStatisticChartUSer(getCurrentYear())
      ).unwrap()
      const incomeChart = await dispatch(
        getStatisticChartIncome(getCurrentYear())
      ).unwrap()

      if (
        userChart ||
        incomeChart ||
        subscriberStatus ||
        usersStatus ||
        incomeStatus
      ) {
        setData({
          users: usersStatus?.usersInMonth ?? 0,
          orders: 0,
          subscribers: subscriberStatus?.totalSubscribers ?? 12,
          newSubscribers: subscriberStatus?.newSubscribers ?? 13,
          income: (incomeStatus?.incomeInMonth ?? 0).toLocaleString() + '€',
          totalInCome: (incomeStatus?.totalIncome ?? 0).toLocaleString() + '€',
          totalUser: usersStatus.totalUsers ?? 0,
        })
      }
    } catch (error: any) {
      handleAPIError(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="grid grid-cols-8 gap-3">
      <StatisticItem
        data={data.users}
        totalUser={data.totalUser}
        type={ETypeStatic.USERS}
      />
      <StatisticItem
        data={data.newSubscribers}
        type={ETypeStatic.SUBSCRIBERS}
        totalUser={data.subscribers}
      />
      {/* <StatisticItem data={data.orders} type={ETypeStatic.ORDERS} /> */}
      <StatisticItem
        data={data.income}
        type={ETypeStatic.INCOME}
        totalUser={data.totalInCome}
      />
    </div>
  )
}
