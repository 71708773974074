import '@translations/i18n'
import AOS from 'aos'
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { themes, useTheme } from '@theme'
import RoleProvider from 'src/contexts/RoleContext'
import { store } from 'src/redux'
import { AppToast } from './components'
import Router from './routes'
// ..
AOS.init()

function App() {
  const { theme, themeLoaded } = useTheme()
  const [selectedTheme, setSelectedTheme] = useState(theme)
  useEffect(() => {
    setSelectedTheme(themes.theme.light)
  }, [themeLoaded])

  return (
    <Provider store={store}>
      <RoleProvider>
        <ThemeProvider theme={selectedTheme || themes.theme.light}>
          <BrowserRouter>
            <Router />
            <AppToast />
          </BrowserRouter>
        </ThemeProvider>
      </RoleProvider>
    </Provider>
  )
}

export default App
