import { SearchOutlined } from '@ant-design/icons'
import { Skeleton, message } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { CURRENT_TIMEZONE, enumTableEmailSubscribed } from '@configs'
import { Button, Input, Loading, SharedTable } from 'src/common'
import { useDebounce } from 'src/hooks/useDebounce'
import { IPackageParams } from 'src/interfaces/package-management'
import { RootState, useAppDispatch } from 'src/redux'
import { selectEmailSubLoading } from 'src/redux/Slices/email-subscribed'
import { getListEmailSubscribedAction } from 'src/redux/actions/email-subscribed'
import { getPackageBenefitsAction } from 'src/redux/actions/package-management'
import { dowloadFile } from 'src/utils/file'

const SubscribedEmailTable = () => {
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [isSearching, setIsSearching] = useState(false)
  const debouncedValue = useDebounce<string | null>(searchValue, 800)
  const [sort, setSort] = useState<{ [key: string]: any }>({})
  const [isDowloading, setIsDowloading] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { emails, total, page, limit } = useSelector(
    (state: RootState) => state.emailsSub
  )

  const getEmailSubLoading = useSelector((state: RootState) =>
    selectEmailSubLoading(state, 'getListEmailSubscribedAction')
  )
  const getPackages = async (params?: IPackageParams) => {
    dispatch(
      getListEmailSubscribedAction({
        page: +page,
        sort,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        ...(params || {}),
      })
    )
  }

  const getPackagesBySearchValue = async () => {
    try {
      setIsSearching(true)
      await dispatch(
        getListEmailSubscribedAction({
          page: 1,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
        })
      ).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error({
          content: error.message,
        })
      }
    } finally {
      setIsSearching(false)
    }
  }

  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableEmailSubscribed.NO}</p>
          </div>
        )
      },
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      sorter: (a: any, b: any) => a.no - b.no,
      width: '10%',
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
    },
    {
      title: enumTableEmailSubscribed.EmailAddress,
      dataIndex: 'email',
      key: 'email',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: enumTableEmailSubscribed.RegisterTime,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (e: string) => (
        <div>{moment(e).format('hh:mm:ss -  MMM DD YYYY')}</div>
      ),
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),

      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
    },
  ]
  const onDowloadFile = async () => {
    try {
      setIsDowloading(true)
      await dowloadFile(`email-subscribed/export?timezone=${CURRENT_TIMEZONE}`)
    } catch (error: any) {
      message.error(error.message || 'Oops, something went wrong')
    } finally {
      setIsDowloading(false)
    }
  }
  useEffect(() => {
    getPackages({ page: 1 })
  }, [])

  useEffect(() => {
    dispatch(getPackageBenefitsAction())
  }, [dispatch])

  useEffect(() => {
    debouncedValue !== null && getPackagesBySearchValue()
  }, [debouncedValue])

  return (
    <ListSubscribedStyle>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        <div className="max-w-[500px] flex-1">
          <Input
            prefix={<SearchOutlined className="pl-2" />}
            placeholder="Enter email address"
            value={searchValue === null ? '' : searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            suffix={isSearching ? <Loading /> : undefined}
          />
        </div>
        <div>
          <Button type="primary" loading={isDowloading} onClick={onDowloadFile}>
            Export
          </Button>
        </div>
      </div>
      <div className="pb-[1.25rem]">
        {getEmailSubLoading || isSearching ? (
          <Skeleton paragraph={{ rows: 4 }} />
        ) : (
          <SharedTable
            columns={columns as any}
            dataSource={
              emails.map((email, index) => ({
                ...email,
                no: index + 1,
              })) || []
            }
            paginationProps={{
              total: !!total ? +total : undefined,
              pageSize: +limit || 10,
              current: +page,
              showSizeChanger: false,
              onChange(page) {
                getPackages({
                  page,
                })
              },
            }}
          />
        )}
      </div>
    </ListSubscribedStyle>
  )
}

const ListSubscribedStyle = styled('div')(() => {
  return {
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',

    '& .ant-table-wrapper': {
      margin: 0,
      '& .ant-table-content': {
        marginRight: 0,
      },
    },
  }
})

export default SubscribedEmailTable
