import { lessonAPI } from '@api'
import { zodResolver } from '@hookform/resolvers/zod'
import { GetAssignmentResponse } from '@interfaces'
import { Divider, message, Skeleton } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Button, Input, TextArea } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { useAppDispatch } from 'src/redux'
import { getListPackageAction } from 'src/redux/actions/package-management'
import styled from 'styled-components'
import { z } from 'zod'
import { IFinalTestForm, IFinalTestSection } from 'src/interfaces/final-test'
import FinalTestSection from 'src/pages/course-management/form/final-test/FinalTestSection'
import { dateLongFormat, EAnswerType, ETestSectionType } from '@configs'
import { sectionSchema } from 'src/pages/course-management/form/final-test/schema'
import {
  createFinalTestSectionAction,
  updateFinalTestSectionAction,
} from 'src/redux/actions/lesson-management'
import { useNavigate } from 'react-router-dom'
import { formatDate } from '@utils'
import useRole from 'src/hooks/useRole'
import ModalAddFinalTestSection from './ModalAddSection'

type Props = {
  open: boolean
  isLoading?: boolean
  onClose: () => void
  onSave: () => void
  finalTestId?: number
  selectSection?: number
  isActive?: boolean
  isTeacherView?: boolean
  deadline?: string
  isViewMode?: boolean
  onAcceptTest?: () => void
  onRejectTest?: () => void
}

const schema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:title_length_error') as string,
    })
    .min(2, {
      message: t('error:title_min_2') as string,
    }),

  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:title_length_error') as string,
    })
    .min(2, {
      message: t('error:title_min_2') as string,
    }),

  content: z
    .string()
    .trim()
    .max(1000, {
      message: t('error:assignment_content_length_error'),
    }),

  contentInDutch: z
    .string()
    .trim()
    .max(1000, {
      message: t('error:assignment_content_length_error'),
    }),

  sections: z
    .array(sectionSchema)
    .min(1, {
      message: t('error:section_min_1') as string,
    })
    .max(2, {
      message: t('error:section_max_2') as string,
    })
    .optional(),
})

const ModalFinalTest = ({
  open,
  onClose,
  finalTestId,
  selectSection,
  onSave,
  isActive = true,
  isTeacherView,
  isViewMode,
  deadline,
  onAcceptTest,
  onRejectTest,
}: Props) => {
  const {
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues: {
      title: '',
      content: '',
      titleInDutch: '',
      contentInDutch: '',
      sections: [],
    },
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [isAddNew, setIsAddNew] = useState<boolean>(false)
  const [currentData, setCurrentData] = useState<
    GetAssignmentResponse | undefined
  >(undefined)
  const [currentSectionItem, setCurrentSectionItem] = useState<
    IFinalTestSection & { index: number }
  >()
  const { isAdminRole } = useRole()

  const { fields, append, remove, update } = useFieldArray({
    control,
    rules: {
      minLength: 1,
      maxLength: 2,
    },
    name: 'sections',
  })

  const watchSections = watch('sections')

  const dispatch = useAppDispatch()

  const [openAddSection, setOpenAddSection] = useState<boolean>(false)

  const closeModalSection = () => {
    setCurrentSectionItem(undefined)
    setOpenAddSection(false)
  }
  const openModalSection = () => {
    setOpenAddSection(true)
  }

  useEffect(() => {
    if (isAdminRole) {
      dispatch(getListPackageAction({ page: 1, limit: 10000 }))
    }
  }, [dispatch, isAdminRole])

  useEffect(() => {
    if (finalTestId) {
      return
    }
    return () => {
      reset()
    }
  }, [open, setValue, reset])

  const haveMultipleChoices = useMemo(() => {
    if (!watchSections) return false
    for (let section of watchSections) {
      if (section.type === ETestSectionType.MULTIPLE_CHOICE) {
        return true
      }
    }
    return false
  }, [watchSections, fields])

  const havePractice = useMemo(() => {
    if (!watchSections) return false
    for (let section of watchSections) {
      if (section.type === ETestSectionType.PRACTICE) {
        return true
      }
    }
    return false
  }, [watchSections, fields])

  const fetchLesson = async () => {
    if (!finalTestId) {
      return
    }
    setIsFetching(true)
    try {
      const response = await lessonAPI.getAssignment(finalTestId)
      if (response?.success) {
        const { data } = response
        setCurrentData(data)
        reset({
          ...data,
          sections: data.finalTestSections,
          attachments: data.lessonAttachment.map((data) => data.attachment),
        })
      }
    } catch (error) {
      message.error(t('error:error_connect_server'))
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    fetchLesson()
  }, [finalTestId])

  useEffect(() => {
    if (isDirty && !open) {
      fetchLesson()
    }
  }, [isDirty, open])

  useEffect(() => {
    if (errors.sections) {
      message.error(errors.sections.message)
    }
  }, [errors])

  const hasEmptyQuestions = (sections: any) => {
    if (!watchSections) return true
    for (const section of sections) {
      if (section.questions.length < 1) {
        return true
      }
    }
    return false
  }

  const currentMultipleChoiceAnswer = (index: number) => {
    if (!currentData) return
    const currentMultipleChoice = currentData.finalTestSections.find(
      (section) => section.type === ETestSectionType.MULTIPLE_CHOICE
    )
    return currentMultipleChoice?.questions
      ? currentMultipleChoice?.questions[index].answers
      : []
  }

  return (
    <ModalContainer>
      <TextAreaStyled>
        <AppModal
          open={open}
          onClose={onClose}
          title={
            <div className="flex items-center justify-between ">
              <div>
                <h1 className="m-0 text-[20px]">
                  {t('finalTest:detail_final_test')}
                </h1>
              </div>
              <div className="hover:opacity-75 cursor-pointer">
                <XCloseIcon width={16} height={16} onClick={onClose} />
              </div>
            </div>
          }
        >
          {isFetching ? (
            <Skeleton paragraph={{ rows: 10 }} />
          ) : (
            <div className="mt-6 w-[1200px]">
              <form>
                <div className="flex flex-row gap-5">
                  <Controller
                    name="title"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextArea
                          label={t('common:title')}
                          onChange={onChange}
                          value={value}
                          name="title"
                          style={{ marginTop: 10, width: '100%' }}
                          errors={error?.message}
                          containerClassName="mt-[24px]"
                          labelClassName="mb-[16px] inline-block"
                          disabled={!isActive || isViewMode}
                          autoSize={{ minRows: 4, maxRows: 16 }}
                          required
                        />
                      )
                    }}
                  />
                  <Controller
                    name="titleInDutch"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextArea
                          label={t('common:title_in_nl')}
                          onChange={onChange}
                          value={value}
                          name="titleInDutch"
                          style={{ marginTop: 10, width: '100%' }}
                          errors={error?.message}
                          containerClassName="mt-[24px]"
                          labelClassName="mb-[16px] inline-block"
                          disabled={!isActive || isViewMode}
                          autoSize={{ minRows: 4, maxRows: 16 }}
                          required
                        />
                      )
                    }}
                  />
                </div>
                <div className="flex flex-row mb-24 gap-5">
                  <Controller
                    name="content"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextArea
                          label={t('common:content')}
                          onChange={onChange}
                          value={value}
                          name="content"
                          style={{
                            marginTop: 10,
                            width: '100%',
                            minHeight: '200px',
                          }}
                          errors={error?.message}
                          containerClassName="mt-[24px]"
                          labelClassName="mb-[16px] inline-block"
                          autoSize={{ minRows: 8, maxRows: 8 }}
                          disabled={!isActive || isViewMode}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="contentInDutch"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextArea
                          label={t('common:content_in_nl')}
                          onChange={onChange}
                          value={value}
                          name="contentInDutch"
                          style={{ marginTop: 10, width: '100%' }}
                          errors={error?.message}
                          containerClassName="mt-[24px]"
                          labelClassName="mb-[16px] inline-block"
                          disabled={!isActive || isViewMode}
                          autoSize={{ minRows: 8, maxRows: 8 }}
                        />
                      )
                    }}
                  />
                </div>

                <div>
                  <Divider orientation="center" className="text-lg">
                    {t('section:section')}
                  </Divider>
                  <div className="flex gap-5 flex-wrap items-center justify-center max-w-full">
                    {fields.map((item: any, index) => {
                      return (
                        <div className="w-full" key={item.id}>
                          <FinalTestSection
                            item={item}
                            index={index}
                            disabled={!isActive || isViewMode}
                            onUpdate={({
                              title,
                              titleInDutch,
                              restrictTimer,
                              type,
                              questions,
                            }: IFinalTestSection) => {
                              update(index, {
                                title,
                                titleInDutch,
                                restrictTimer,
                                type,
                                questions,
                              })
                              closeModalSection()
                            }}
                            onEdit={() => {
                              setCurrentSectionItem({
                                ...item,
                                index,
                              })
                              openModalSection()
                              setIsAddNew(false)
                            }}
                            onRemove={() => {
                              remove(index)
                            }}
                          />
                          <Divider
                            orientation="center"
                            className="text-lg items-center text-center justify-center"
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>

                {!!deadline ? (
                  <div className="w-full text-[#DE3B40] text-[24px] font-semibold text-center">
                    Deadline: {formatDate(deadline, dateLongFormat)}
                  </div>
                ) : null}

                {!!isTeacherView ? (
                  <TeacherViewWrapper>
                    <div className="flex items-center mt-5 justify-center">
                      <Button
                        type="ghost"
                        size="middle"
                        className="submit__btn login-btn mr-4"
                        onClick={() => {
                          onClose()
                          reset()
                        }}
                      >
                        {t('common:cancel')}
                      </Button>
                      <Button
                        htmlType="button"
                        type="primary"
                        size="middle"
                        className="submit__btn login-btn mr-4"
                        onClick={onAcceptTest}
                      >
                        {t('common:accept')}
                      </Button>
                      <Button
                        htmlType="button"
                        type="primary"
                        size="middle"
                        className="submit__btn login-btn !bg-[#DE3B40] !border-none"
                        onClick={onRejectTest}
                      >
                        {t('common:reject')}
                      </Button>
                    </div>
                  </TeacherViewWrapper>
                ) : (
                  <div className="mt-12">
                    <div className="flex items-center mt-5 justify-center">
                      <Button
                        type="ghost"
                        size="middle"
                        className="submit__btn login-btn mr-10"
                        onClick={() => {
                          onClose()
                          reset()
                        }}
                      >
                        {t('common:cancel')}
                      </Button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          )}
        </AppModal>
      </TextAreaStyled>
      <ModalAddFinalTestSection
        section={currentSectionItem}
        open={openAddSection}
        onClose={closeModalSection}
        haveMultipleChoices={haveMultipleChoices}
        havePractice={havePractice}
        isActive={isActive}
        isAddNew={isAddNew}
        isViewMode
        onAppendNewSection={({
          title,
          titleInDutch,
          restrictTimer,
          type,
          questions,
        }: IFinalTestSection) => {
          append({
            title,
            titleInDutch,
            restrictTimer,
            type,
            questions,
          })
        }}
        onEdit={({
          title,
          titleInDutch,
          restrictTimer,
          type,
          questions,
        }: IFinalTestSection) => {
          if (!currentSectionItem) return

          update(currentSectionItem?.index, {
            title,
            titleInDutch,
            restrictTimer,
            type,
            questions,
          })
        }}
      />
    </ModalContainer>
  )
}

const TextAreaStyled = styled.div`
  .display-block {
    display: block !important;
  }
  .upload-video {
    display: none !important;
  }
`

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;

  input:disabled,
  input[type='text']:disabled,
  textarea:disabled {
    color: #6c6c6c !important;
  }
`

const TeacherViewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding-top: 12px;
`
export default ModalFinalTest
