import { EAboutUsPageTabs } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  RootState,
  createOutStoryAction,
  getListOurStoryAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Card, Skeleton, message } from 'antd'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, TextArea } from 'src/common'
import {
  IAddProvidingRequest,
  IOurStoryFormData,
} from 'src/interfaces/aboutus-management'
import { z } from 'zod'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import SelectMediaModal from '../gallery/SelectMediaModal'
import SelectMedia from '../gallery/SelectMedia'
import { InputWrapperGridStyled } from '@theme'
interface IAboutUsPageTabItemProps {
  activeTab?: string
}

const addAboutUsSchema = z.object({
  content: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(500, {
      message: t('error:our_story_content_length_error') as string,
    }),
  contentInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(500, {
      message: t('error:our_story_content_dutch_length_error') as string,
    }),
  author: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(50, {
      message: t('error:our_story_author_length_error') as string,
    }),
  authorTitle: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(50, {
      message: t('error:our_story_author_title_length_error') as string,
    }),
  authorTitleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(50, {
      message: t(
        'error:our_story_author_title_in_dutch_length_error'
      ) as string,
    }),
  mediaId: z.number({
    required_error: t('error:field_required') as string,
  }),
  mediaMobileId: z.number({
    required_error: t('error:field_required') as string,
  }),
})

const OurStoryTab = ({ activeTab }: IAboutUsPageTabItemProps) => {
  const dispatch = useAppDispatch()
  const [imageSrc, setImageSrc] = useState('')
  const [imageMobileSrc, setImageMobileSrc] = useState('')
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const getListOurStoryLoading = useAppSelector(
    (state: RootState) =>
      state.aboutUsManagement.loadings?.getListOurStoryActionLoading
  )

  const listOurStory = useAppSelector(
    (state: RootState) => state.aboutUsManagement.ourStory?.listOurStory
  )

  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const defaultValue: IOurStoryFormData = {
    content: listOurStory?.content ?? '',
    contentInDutch: listOurStory?.contentInDutch ?? '',
    author: listOurStory?.author ?? '',
    authorTitle: listOurStory?.authorTitle ?? '',
    authorTitleInDutch: listOurStory?.authorTitleInDutch ?? '',
    mediaId: listOurStory?.media?.id ?? 0,
    mediaMobileId: listOurStory?.mediaMobile?.id ?? 0,
  }
  const { control, handleSubmit, setValue } = useForm<IAddProvidingRequest>({
    defaultValues: defaultValue,
    resolver: zodResolver(addAboutUsSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      setImageMobileSrc(data?.baseUrl ?? '')
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
  }

  useEffect(() => {
    if (activeTab === EAboutUsPageTabs.OUR_STORY) {
      dispatch(getListOurStoryAction())
    }
  }, [activeTab])

  useEffect(() => {
    if (listOurStory) {
      setValue('content', listOurStory?.content ?? '')
      setValue('contentInDutch', listOurStory?.contentInDutch ?? '')
      setValue('author', listOurStory?.metaData?.author ?? '')
      setValue('authorTitle', listOurStory?.metaData?.authorTitle ?? '')
      setValue(
        'authorTitleInDutch',
        listOurStory?.metaData?.authorTitleInDutch ?? ''
      )
      setValue('mediaId', listOurStory?.media?.id ?? 0)
      setValue('mediaMobileId', listOurStory?.mediaMobile?.id ?? 0)
      listOurStory?.media?.url && setImageSrc(listOurStory.media.url)
      listOurStory?.mediaMobile?.url &&
        setImageMobileSrc(listOurStory?.mediaMobile?.url)
    }
  }, [listOurStory])

  const onSave = async (data: IOurStoryFormData) => {
    //on save about us
    try {
      const response = await dispatch(createOutStoryAction(data)).unwrap()
      if (response?.success) message.success(response.message)
      dispatch(getListOurStoryAction())
    } catch (error: any) {
      message.error(error.message)
    }
  }

  return (
    <Card>
      {getListOurStoryLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <div className="mt-6">
          <form>
            <InputWrapperGridStyled>
              <Controller
                name="content"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextArea
                      label="Content"
                      name="content"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                      containerClassName="mt-3"
                    />
                  )
                }}
              />

              <Controller
                name="contentInDutch"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextArea
                      label="Content in Netherlands"
                      name="contentInDutch"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                      containerClassName="mt-3"
                    />
                  )
                }}
              />

              <Controller
                name={'author'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Author"
                      name="author"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                    />
                  )
                }}
              />
              <Controller
                name={'authorTitle'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Author title"
                      name="authorTitle"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                    />
                  )
                }}
              />

              <Controller
                name={'authorTitleInDutch'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Author title in Netherlands"
                      name="authorTitleInDutch"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                    />
                  )
                }}
              />

              <div className="space"></div>

              <div className="flex flex-col gap-1">
                <Controller
                  name="mediaId"
                  control={control}
                  render={({ field: { onChange }, fieldState: { error } }) => {
                    onChangeDesktopRef.current = onChange
                    return (
                      <CustomDragger
                        label="Image"
                        name="mediaVisionId"
                        id="mediaVisionId"
                        containerClassName="mt-3"
                        alignment="col"
                        required
                        onLoadEnd={(data) => {
                          setValue('mediaId', data?.id ?? 0)
                          onChange(data?.id ?? 0)
                        }}
                        changeLoading={(loading) => {
                          setIsLoadingImage(loading)
                        }}
                        errors={error?.message}
                        initResource={imageSrc}
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        limitFileSize={5}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    )
                  }}
                />
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'desktop')
                    setShowModalSelectMedia(true)
                  }}
                  isLoadingMedia={isLoadingImage}
                />
              </div>

              <div className="flex flex-col gap-1">
                <Controller
                  name="mediaMobileId"
                  control={control}
                  render={({ field: { onChange }, fieldState: { error } }) => {
                    onChangeMobileRef.current = onChange
                    return (
                      <CustomDragger
                        label="Image mobile"
                        name="mediaVisionMobileId"
                        id="mediaVisionMobileId"
                        containerClassName="mt-3"
                        alignment="col"
                        required
                        onLoadEnd={(data) => {
                          setValue('mediaMobileId', data?.id ?? 0)
                          onChange(data?.id ?? 0)
                        }}
                        changeLoading={(loading) => {
                          setIsLoadingImage(loading)
                        }}
                        errors={error?.message}
                        initResource={imageMobileSrc}
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        limitFileSize={5}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    )
                  }}
                />
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'mobile')
                    setShowModalSelectMedia(true)
                  }}
                  isLoadingMedia={isLoadingImage}
                />
              </div>
            </InputWrapperGridStyled>

            <div className="mt-6">
              <div className="flex items-center mt-5 justify-end">
                <Button
                  htmlType="submit"
                  type="primary"
                  size="middle"
                  className="submit__btn login-btn"
                  disabled={isLoadingImage}
                  loading={getListOurStoryLoading}
                  onClick={handleSubmit(onSave, (e) => {
                    // console.log(e)
                  })}
                >
                  {'Save'}
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}

      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.CONTENT}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </Card>
  )
}

export default OurStoryTab
