import { CHUNK_SIZE } from "@configs";
import { galleryAPI } from "src/api/gallery";
import { store, authActions } from 'src/redux'

export class Uploader {
  chunkSize: number;
  threadsQuantity: number;
  file: File;
  fileName: string;
  fileType: string;
  aborted: boolean;
  uploadedSize: number;
  progressCache: any;
  activeConnections: any;
  parts: {
    partNumber: number;
    signedUrl: string;
  }[];
  uploadedParts: {
    PartNumber: number;
    ETag: string;
  }[];
  uploadId: string | null;
  key: string | null;
  onProgressFn: (data: any) => void;
  onErrorFn: (error?: any) => void;
  onComplete: (data: any) => void;
  data: any;
  isUploading: boolean; // New flag for reload prevention
  private uploadCompletionCallback?: () => void;

  constructor(options: any) {
    this.chunkSize = CHUNK_SIZE;
    this.threadsQuantity = Math.min(5, 15);
    this.file = options.file;
    this.fileName = options.fileName;
    this.fileType = options.fileType;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.uploadId = null;
    this.key = null;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
    this.onComplete = () => {};
    this.isUploading = false; // Initialize as not uploading
  }

  setUploadCompletionCallback(callback: () => void) {
    this.uploadCompletionCallback = callback;
  }

  async start() {
    this.isUploading = true; // Mark as uploading
    store.dispatch(authActions.setIsCallingApi(true))
    this.addUnloadListener(); // Add reload prevention listener
    await this.initialize();
  }

  async initialize() {
    try {
      let fileName = this.fileName;
      const fileType = this.fileType;

      const videoInitializationUploadInput = {
        fileName,
        fileType,
      };
      const initializeResponse = await galleryAPI.initMultipartUpload(
        videoInitializationUploadInput
      );

      const AWSFileDataOutput = initializeResponse.data;
      this.uploadId = AWSFileDataOutput.uploadId;
      this.key = AWSFileDataOutput.key;

      const numberOfParts = Math.ceil(this.file.size / this.chunkSize);

      const AWSMultipartFileDataInput = {
        uploadId: this.uploadId,
        key: this.key,
        parts: numberOfParts,
      };

      const urlsResponse = await galleryAPI.getMulitpartPresignedUrl(
        AWSMultipartFileDataInput
      );

      const newParts = urlsResponse.data;
      this.parts.push(...newParts);

      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.partNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error: any) => {
          this.parts.push(part);
          this.complete(error);
          throw Error(error);
        });
    }
  }

  async complete(error?: any) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      this.cleanup();
      return;
    }

    if (error) {
      this.onErrorFn(error);
      this.cleanup();
      return;
    }

    if (this.uploadCompletionCallback) {
      this.uploadCompletionCallback();
    }

    this.cleanup();
  }

  async sendCompleteRequest() {
    if (this.uploadId && this.key && this.file) {
      const videoFinalizationMultiPartInput = {
        uploadId: this.uploadId,
        key: this.key,
        partsInfo: this.uploadedParts,
      };

      try {
        const finalResult = await galleryAPI.completeUploadMultipart(
          videoFinalizationMultiPartInput
        );
        if (finalResult.data) {
          return finalResult.data;
        }
      } catch (error: any) {
        throw new Error(error);
      }
    }
  }

  sendChunk(
    chunk: Blob,
    part: { partNumber: number; signedUrl: string },
    sendChunkStarted: () => void
  ) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }
          resolve("success");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  handleProgress(part: any, event: any) {
    if (this.file) {
      if (
        event.type === "progress" ||
        event.type === "error" ||
        event.type === "abort"
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  upload(
    file: Blob,
    part: { partNumber: number; signedUrl: string },
    sendChunkStarted: () => void
  ) {
    return new Promise((resolve, reject) => {
      if (this.uploadId && this.key) {
        const xhr = (this.activeConnections[part.partNumber - 1] =
          new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.partNumber - 1
        );

        xhr.upload.addEventListener("progress", progressListener);

        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader("ETag");

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.partNumber,
                ETag: ETag.replaceAll('"', ""),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.partNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error("Upload canceled by user"));
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.send(file);
      }
    });
  }

  private addUnloadListener() {
    window.addEventListener("beforeunload", this.preventUnload);
  }

  private removeUnloadListener() {
    window.removeEventListener("beforeunload", this.preventUnload);
  }

  private preventUnload(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = ""; // Required for showing the confirmation dialog
  }

  private cleanup() {
    store.dispatch(authActions.setIsCallingApi(false))
    this.isUploading = false; // Reset flag
    this.removeUnloadListener(); // Remove listener
  }
}
