import { createAsyncThunk } from '@reduxjs/toolkit'

import { ApiClient, dashboardAPI } from '@api'
import {
  IAcademyOverview,
  ICourseInsightOverview,
  IDashboardParams,
  IGetStatisticChartRequest,
  IRevenueFromCoursePurchase,
  IRevenueTeacherPackage,
  ITeacherGrowthItem,
  ITopFiveRevenueResponse,
  IWithdrawalAmountItem,
} from '@interfaces'
import moment from 'moment'
import {
  API_ACADEMY_OVERVIEW,
  API_COURSE_GROWTH,
  API_COURSE_INSIGHT_OVERVIEW,
  API_REVENUE_COURSE_PURCHASE,
  API_REVENUE_TEACHER_PACKAGE,
  API_TEACHER_GROWTH,
  API_TEACHER_OVERVIEW,
  API_TOP_REVENUE,
  API_WITHDRAWAL_AMOUNT,
} from 'src/routes/api'

export const getRevenueStatAction = createAsyncThunk(
  'dashboard/getRevenueStatAction',
  async (params: IDashboardParams) => {
    try {
      const localParams = params || {
        from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
      const res = await dashboardAPI.getRevenueStat(localParams)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getNewUserStatAction = createAsyncThunk(
  'dashboard/getNewUserStatAction',
  async (params: IDashboardParams) => {
    try {
      const localParams = params || {
        from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
      }
      const res = await dashboardAPI.getNewUserStat(localParams)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getStatisticChartUSer = createAsyncThunk(
  'dashboard/getDataChartUsers',
  async (year: string) => {
    try {
      const res = await dashboardAPI.getUserStatisticChart(year)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getStatisticChartIncome = createAsyncThunk(
  'dashboard/getDataChartIncome',
  async (year: string) => {
    try {
      const res = await dashboardAPI.getIncomeStatisticChart(year)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getSubscriberDataAction = createAsyncThunk(
  'dashboard/getSubscriberDataAction',
  async () => {
    try {
      const res = await dashboardAPI.getSubscriberData()
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getUsersDataAction = createAsyncThunk(
  'dashboard/getUsersDataAction',
  async () => {
    try {
      const res = await dashboardAPI.getUsersData()
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getIncomeDataAction = createAsyncThunk(
  'dashboard/getIncomeDataAction',
  async () => {
    try {
      const res = await dashboardAPI.getIncomeData()
      return res.data
    } catch (error) {
      throw error
    }
  }
)

type CourseInsightOverviewParams = {
  startDate?: string
  endDate?: string
  filterBy?: EFilterBy
}

export enum EFilterBy {
  ALL_TIME = 'allTime',
  LAST_MONTH = 'lastMonth',
  LAST_SIX_MONTH = 'lastSixMonths',
  LAST_YEAR = 'lastYear',
  CUSTOM = 'custom',
}

export const getCourseInsightOverview = createAsyncThunk(
  'courseInsightOverview/getCourseInsightOverview',
  async (params: CourseInsightOverviewParams) => {
    // Remove query params where the value is an empty string ('')
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== '')
    )

    const response = await ApiClient.get<ICourseInsightOverview>(
      `${API_COURSE_INSIGHT_OVERVIEW}`,
      {
        params: filteredParams, // Use the filtered params here
      }
    )
    return response?.data || {}
  }
)

type CourseGrowthParams = {
  year?: string
}

export const getCourseGrowth = createAsyncThunk(
  'courseGrowth/getCourseGrowth',
  async (params: CourseGrowthParams) => {
    const response = await ApiClient.get<ICourseInsightOverview>(
      `${API_COURSE_GROWTH}/${params.year}`
    )

    return response?.data || {}
  }
)

export const getTopRevenue = createAsyncThunk(
  'topRevenue/getTopRevenue',
  async (params: CourseInsightOverviewParams) => {
    // Remove query params where the value is an empty string ('')
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== '')
    )

    const response = await ApiClient.get<ITopFiveRevenueResponse>(
      API_TOP_REVENUE,
      {
        params: filteredParams,
      }
    )

    return response?.data || {}
  }
)

export const getRevenueByCoursePurchase = createAsyncThunk(
  'topRevenue/getTopRevenueByCoursePurchase',
  async (params: CourseGrowthParams) => {
    const response = await ApiClient.get<IRevenueFromCoursePurchase>(
      `${API_REVENUE_COURSE_PURCHASE}/${params.year}`
    )

    return response?.data || {}
  }
)

export const getTeacherOverview = createAsyncThunk(
  'teacherOverview/getTeacherOverview',
  async () => {
    const response = await ApiClient.get<any>(API_TEACHER_OVERVIEW)

    return response?.data || {}
  }
)

export const getAcademyOverview = createAsyncThunk(
  'academyOverview/getAcademyOverview',
  async () => {
    const response = await ApiClient.get<IAcademyOverview>(API_ACADEMY_OVERVIEW)

    return response?.data || {}
  }
)

export const getTeacherGrowth = createAsyncThunk(
  'teacherGrowth/getTeacherGrowth',
  async (params: CourseGrowthParams) => {
    const response = await ApiClient.get<ITeacherGrowthItem[]>(
      `${API_TEACHER_GROWTH}/${params.year}`
    )
    return response?.data || {}
  }
)

export const getTeacherWithdrawalAmount = createAsyncThunk(
  'teacherWithdrawalAmount/getTeacherWithdrawalAmount',
  async (params: CourseGrowthParams) => {
    const response = await ApiClient.get<IWithdrawalAmountItem[]>(
      `${API_WITHDRAWAL_AMOUNT}/${params.year}`
    )
    return response?.data || {}
  }
)

export const getTeacherRevenueByPackage = createAsyncThunk(
  'teacherRevenueByPackage/getTeacherRevenueByPackage',
  async (params: CourseGrowthParams) => {
    const response = await ApiClient.get<IRevenueTeacherPackage>(
      `${API_REVENUE_TEACHER_PACKAGE}/${params.year}`
    )
    return response?.data || {}
  }
)
