import clsx from 'clsx';

type Props = {
  onClickSelectMedia: () => void;
  isNotGrid?: boolean;
  buttonClassName?: string;
  isLoadingMedia?: boolean;
};

const SelectMedia = ({
  onClickSelectMedia,
  isNotGrid = false,
  buttonClassName = '',
  isLoadingMedia = false,
}: Props) => {
  
  return (
    <div className="grid grid-cols-7 w-full mt-3">
      {!isNotGrid && <div className="placeholder-grid col-span-2" />}
      <button
        type="button"
        onClick={onClickSelectMedia}
        className={clsx(
          'select-media-gallery underline',
          isNotGrid ? 'col-span-7 text-right' : 'col-span-5',
          isLoadingMedia ? 'cursor-not-allowed' : 'cursor-pointer',
          buttonClassName
        )}
        disabled={isLoadingMedia}
        aria-disabled={isLoadingMedia}
      >
        Or select media from gallery
      </button>
    </div>
  );
};

export default SelectMedia;
