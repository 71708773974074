import {
  CaretDownOutlined,
  CaretUpOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { Skeleton, Tooltip, message } from 'antd'
import { t } from 'i18next'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { CURRENT_TIMEZONE, EUserManagementTabs, enumTableTitleTransactionHistory } from '@configs'
import { Button, SharedTable } from 'src/common'
import {
  ICommonGetParams,
  ITransaction,
  TransactionStatusContent,
  TransactionTypeContent,
} from 'src/interfaces'
import { RootState } from 'src/redux'
import { selectUserLogLoading } from 'src/redux/Slices/user-log'
import { getTransactionsAction } from 'src/redux/actions/user-log'
import { dowloadFile } from 'src/utils/file'
import { set } from 'lodash'
import DetailTransactionLogModal from '../transaction-log/DetailTransactionLogModal'

interface ITransactionHistoryTabProps {
  activeTab?: string
}

const TransactionHistoryTab = ({ activeTab }: ITransactionHistoryTabProps) => {
  const [sort, setSort] = useState<{ [key: string]: any }>({})
  const [isDowloading, setIsDowloading] = useState<boolean>(false)

  const [selectedTransaction, setSelectedTransaction] = useState<any>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const routeParams = useParams() as any

  const dispatch = useDispatch()
  const { transactions, pageTransaction, totalPageTransaction } = useSelector(
    (state: RootState) => state.userLog
  )

  const getUserLogByUserIdLoading = useSelector((state: RootState) =>
    selectUserLogLoading(state, 'getTransactionsAction')
  )

  const getTransactionHistories = async (params?: ICommonGetParams) => {
    try {
      await dispatch(
        getTransactionsAction({
          id: +routeParams.userId,
          page: +pageTransaction,
          sort,
          ...(params || {}),
        })
      )
    } catch (error) {
      throw error
    }
  }

  const onClose = () => {
    setOpenModal(false)
    setSelectedTransaction(null)
  }

  const onDowloadFile = async () => {
    try {
      setIsDowloading(true)
      await dowloadFile(`transaction-histories/export/${routeParams.userId}?timezone=${CURRENT_TIMEZONE}`)
    } catch (error: any) {
      message.error(error.message || 'Oops, something went wrong')
    } finally {
      setIsDowloading(false)
    }
  }

  const columns = [
    {
      width: '40%',
      key: 'id',
      dataIndex: 'title',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Title</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({
                  ...sort,
                  title: sort.title === 'asc' ? 'desc' : 'asc',
                })
                const newSortTitle = sort.title === 'asc' ? 'desc' : 'asc'
                delete sort.title
                getTransactionHistories({
                  sort: {
                    title: newSortTitle,
                    ...sort,
                  },
                })
              }}
            >
              {sort.title === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },

      ellipsis: true,
    },
    {
      width: '20%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleTransactionHistory.TYPE}</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({ ...sort, type: sort.type === 'asc' ? 'desc' : 'asc' })
                const newSortTitle = sort.type === 'asc' ? 'desc' : 'asc'
                delete sort.type
                getTransactionHistories({
                  sort: { type: newSortTitle, ...sort },
                })
              }}
            >
              {sort.type === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      dataIndex: 'type',
      key: 'id',
      ellipsis: true,
      render: (e: string) => {
        return (
          <div className="flex items-center justify-center">
            {TransactionTypeContent[e]}
          </div>
        )
      },
    },
    {
      width: '20%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleTransactionHistory.TIME}</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({
                  ...sort,
                  createdAt: sort.createdAt === 'asc' ? 'desc' : 'asc',
                })
                const newSortTitle = sort.createdAt === 'asc' ? 'desc' : 'asc'
                delete sort.createdAt
                getTransactionHistories({
                  sort: {
                    createdAt: newSortTitle,
                    ...sort,
                  },
                })
              }}
            >
              {sort.createdAt === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      dataIndex: 'createdAt',
      key: 'id',
      render: (e: any) => {
        return (
          <div className="flex items-center justify-center">
            {moment(e).format('HH:mm:ss - DD MMM YYYY')}
          </div>
        )
      },
      ellipsis: true,
    },
    {
      width: '10%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleTransactionHistory.STATUS}</p>
          </div>
        )
      },
      dataIndex: 'status',
      key: 'id',
      render: (e: any) => {
        return (
          <div className="flex justify-center">
            {TransactionStatusContent[e]}
          </div>
        )
      },
      ellipsis: true,
    },
    {
      width: '10%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleTransactionHistory.ACTION}</p>
          </div>
        )
      },
      key: 'action',
      dataIndex: 'action',
      render: (_: any, record: ITransaction) => (
        <div className="flex space-x-4 justify-center">
          <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
              onClick={() => {
                setSelectedTransaction(record)
                setOpenModal(true)
              }}
            />
          </Tooltip>
        </div>
      ),
      ellipsis: true,
    },
  ]

  useEffect(() => {
    activeTab === EUserManagementTabs.TRANSACTION_HISTORY &&
      getTransactionHistories({ page: 1 })
  }, [activeTab])

  return (
    <TransactionHistoryTabStyled className="bg-[#fff] py-0">
      <div className="bg-[#fff] px-[1.25rem] rounded-[5px]">
        <div className="flex flex-row-reverse items-center justify-between py-[1.25rem] w-full">
          <div>
            <Button
              type="primary"
              onClick={onDowloadFile}
              loading={isDowloading}
              disabled={!transactions.length}
            >
              {t('common:export')}
            </Button>
          </div>
        </div>
        <div className="pb-[1.25rem]">
          {getUserLogByUserIdLoading ? (
            <Skeleton paragraph={{ rows: 4 }} />
          ) : (
            <SharedTable
              sticky
              columns={columns as any}
              dataSource={transactions}
              paginationProps={{
                total: !!totalPageTransaction
                  ? +totalPageTransaction
                  : undefined,
                pageSize: 10,
                current: +pageTransaction,
                onChange(page) {
                  getTransactionHistories({
                    page,
                  })
                },
              }}
              hasIndexColumn={false}
            />
          )}
        </div>
      </div>
      {openModal && (
        <DetailTransactionLogModal
          open={openModal}
          onClose={onClose}
          transactionItem={selectedTransaction}
          titleModal="Transaction Detail"
        />
      )}
    </TransactionHistoryTabStyled>
  )
}

export default TransactionHistoryTab

const TransactionHistoryTabStyled = styled('div')(() => {
  return {
    '&>div': {
      borderRight: '1px solid #f0f0f0',
      borderLeft: '1px solid #f0f0f0',
      borderBottom: '1px solid #f0f0f0',
    },

    '& .ant-table-wrapper': {
      margin: 0,
      '& .ant-table-content': {
        marginRight: 0,
      },
    },
  }
})
