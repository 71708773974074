import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { enumTableTitleInContentManagementAboutUs } from '@configs'
import { Card, Skeleton, Tooltip, message } from 'antd'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ICategory, ISubCategory } from '@interfaces'
import {
  RootState,
  deleteSubCategoryAction,
  getAllCategoriesAction,
  useAppDispatch,
} from '@redux'
import { useTranslation } from 'react-i18next'
import { Button, SharedTable } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { selectCategoryLoading } from 'src/redux/Slices/category-management'
import queryString from 'query-string'

import { ShareSelectInput } from '@components'
import EditSubCategoryModal from './EditSubCategoryModal'
import { useSearchParams } from 'react-router-dom'

export const SubCategory = () => {
  const { listSubCategory, categories } = useSelector(
    (state: RootState) => state.categories
  )
  const [status, setStatus] = useState<any>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(1)
  const [openEditModal, setOpenEditModal] = useState<{
    status: boolean
    item?: ICategory
  }>({
    status: false,
    item: undefined,
  })
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<{
    status: boolean
    id?: number
  }>({
    status: false,
    id: undefined,
  })

  const { t } = useTranslation(['error', 'common'])
  const categoryId = searchParams.get('q')
  const dispatch = useAppDispatch()
  const getCategoriesLoading = useSelector((state: RootState) =>
    selectCategoryLoading(state, 'getListCategoriesActionLoading')
  )

  const resetList = async () => {
    dispatch(getAllCategoriesAction())
  }

  const columns = useMemo(
    () => [
      {
        title: () => {
          return (
            <div className="flex items-center justify-center w-full">
              <p className="m-0">
                {enumTableTitleInContentManagementAboutUs.NO}
              </p>
            </div>
          )
        },
        dataIndex: 'no',
        align: 'center',
        key: 'no',
        width: '5%',
        sortDirections: ['ascend', 'descend'],
        showSorterTooltip: false,

        ellipsis: true,
      },
      {
        title: 'Sub category name',
        dataIndex: 'title',
        key: 'title',
        width: '30%',
        sortDirections: ['ascend', 'descend'],
        showSorterTooltip: false,
        ellipsis: true,
      },
      {
        title: 'Category name',
        dataIndex: 'name',
        key: 'name',
        width: '25%',
        sortDirections: ['ascend', 'descend'],
        showSorterTooltip: false,
        ellipsis: true,
        render: (title: string, record: ISubCategory) => {
          return (
            <div
              className="h-auto items-center flex"
              onClick={() => {
                setStatus(record.categoryId as number)
              }}
            >
              <p className="my-auto">{title}</p>
            </div>
          )
        },
      },

      {
        title: () => {
          return (
            <div className="flex items-center justify-center w-full">
              <p className="m-0">
                {enumTableTitleInContentManagementAboutUs.ACTION}
              </p>
            </div>
          )
        },
        key: 'action',
        dataIndex: 'action',
        width: '15%',
        render: (record: ICategory) => (
          <div className="flex space-x-4 items-center justify-center w-full">
            <Tooltip title={t('common:view_detail')}>
              <EyeOutlined
                className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
                onClick={() => {
                  setOpenEditModal({
                    status: true,
                    item: record,
                  })
                }}
              />
            </Tooltip>
            <DeleteOutlined
              className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
              onClick={() => {
                setOpenConfirmDeleteModal({
                  status: true,
                  id: record.id,
                })
              }}
            />
          </div>
        ),
        ellipsis: true,
      },
    ],
    []
  )

  const deleteCategory = useCallback(async () => {
    if (!openConfirmDeleteModal.id) {
      return
    }
    try {
      const response = await dispatch(
        deleteSubCategoryAction(openConfirmDeleteModal.id)
      ).unwrap()
      message.success({
        content: response.message,
      })
      setOpenConfirmDeleteModal({
        status: false,
        id: undefined,
      })
      resetList()
    } catch (error: any) {
      message.error({
        content: error.message,
      })
    }
  }, [dispatch, openConfirmDeleteModal.id, resetList])

  const handleChaneStatus = (value: string | number) => {
    const stringifyQuery = queryString.stringify({
      q: value,
    })
    setSearchParams(stringifyQuery)
    setStatus(value as number)
  }
  useEffect(() => {
    if (categoryId) {
      setStatus(+categoryId as number)
    }
  }, [categoryId])

  useEffect(() => {
    dispatch(getAllCategoriesAction())
  }, [dispatch])

  return (
    <Card>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: 1,
            rowGap: '20px',
          }}
        />
        <div className="wrapper-status-filter flex items-center justify-center gap-1 mr-5">
          <div className="label-status">Category</div>
          <ShareSelectInput
            data={[
              {
                value: 0,
                label: 'All',
              },
            ].concat(
              categories?.map((item) => ({
                value: item.id,
                label: item.name,
              }))
            )}
            value={status}
            placeholder="Category"
            style={{ width: '160px' }}
            onChange={handleChaneStatus}
            showArrow
          />
        </div>
        <Button
          htmlType="submit"
          type="primary"
          size="middle"
          className="submit__btn loginselected-btn"
          onClick={() => {
            setOpenEditModal({
              status: true,
              item: undefined,
            })
          }}
          style={{
            alignSelf: 'flex-end',
          }}
        >
          {'New sub category'}
        </Button>
      </div>
      {getCategoriesLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={
            status
              ? listSubCategory
                  .filter((item) => item.parentCategoryId === status)
                  .map((item, index) => {
                    return {
                      ...item,
                      key: item?.id,
                      no: index + 1,
                      title: item?.name ?? `-${t('error:no_data')}-`,
                      name: item.parentCategory,
                      parentId: item.parentCategoryId,
                      record: item,
                      action: item,
                    }
                  })
              : listSubCategory
                  ?.filter((_, index) => {
                    return index < page * 10 && index >= (page - 1) * 10
                  })
                  .map((item, index) => {
                    return {
                      ...item,
                      key: item?.id,
                      no: index + 1,
                      title: item?.name ?? `-${t('error:no_data')}-`,
                      name: item.parentCategory,
                      parentId: item.parentCategoryId,
                      record: item,
                      action: item,
                    }
                  })
          }
          paginationProps={
            !status
              ? {
                  total: listSubCategory.length,
                  pageSize: 10,
                  current: page,
                  showSizeChanger: false,
                  onChange(_page) {
                    setPage(_page)
                  },
                }
              : undefined
          }
          sticky
        />
      )}
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete sub category"
          content="Are you sure to delete this subcategory?"
          open={openConfirmDeleteModal.status}
          isLoading={false}
          onDelete={deleteCategory}
          onClose={() => {
            setOpenConfirmDeleteModal({
              status: false,
              id: undefined,
            })
          }}
        />
      )}
      {openEditModal && (
        <EditSubCategoryModal
          title="Edit sub category title"
          open={openEditModal.status}
          isLoading={false}
          item={openEditModal.item}
          onSuccess={() => {
            resetList()
            setOpenEditModal({
              status: false,
              item: undefined,
            })
          }}
          onClose={() => {
            setOpenEditModal({
              status: false,
              item: undefined,
            })
          }}
        />
      )}
    </Card>
  )
}
