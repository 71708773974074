import { EyeOutlined } from '@ant-design/icons'
import {
  ETableTitleAboutUsManagementCoreValue,
  PATH_CHALLENGE_DETAIL_PARTICIPANTS,
} from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAppDispatch } from '@redux'
import { Progress, Skeleton, Tooltip, message } from 'antd'
import { t } from 'i18next'
import { ReactNode, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { SharedTable } from 'src/common'
import { IUserChat } from 'src/interfaces/chat'
import {
  IChallengeFormDetail,
  IChallengeItem,
  IGetGoalParams,
  UserChallenge,
} from 'src/interfaces/habit-management'
import {
  getListChallengerByIdAction,
  getListChallengerUsersByIdAction,
} from 'src/redux/actions/habit-management'
import styled from 'styled-components'
import { RefinementCtx, z } from 'zod'

type ChallengeHabitModalProps = {
  isLoading: boolean
  title?: string
  content?: ReactNode
  item?: IChallengeItem | null
  changeLoading?: (value: boolean) => void
  noDefault: number
}
// const checkConfirmPassword = (
//   { applyFrom, applyTo }: { applyFrom: number; applyTo: number },
//   ctx: RefinementCtx
// ) => {
//   if (applyFrom >= applyTo) {
//     ctx.addIssue({
//       code: 'custom',
//       message: 'Apply to must be greater than Apply from',
//       path: ['applyTo'],
//     })
//   }
// }

const addCoreValueSchema = z.object({
  challenge: z.string().trim().nonempty('This is a required field'),
  challengeInDutch: z.string().trim().nonempty('This is a required field'),
  mediaId: z.number().min(1, { message: t('error:field_required') as string }),
  goalId: z.number().min(1, { message: t('error:field_required') as string }),
  description: z.string().trim().nonempty('This is a required field'),
  descriptionInDutch: z.string().trim().nonempty('This is a required field'),
  participantsNumberMax: z
    .number()
    .min(1, { message: t('error:field_required') as string }),
  // applyFrom: z
  //   .number()
  //   .min(1, { message: 'Apply From is maximum allowed value 1 to 10' })
  //   .max(10, { message: 'Apply From is maximum allowed value 1 to 10' }),
  // applyTo: z
  //   .number()
  //   .min(0, { message: t('error:field_required') as string })
  //   .max(10, { message: 'Apply To is maximum allowed value 1 to 10' }),
  status: z.boolean(),
})

const DetailChallenge_V2 = ({
  changeLoading,
  noDefault = 3,
}: ChallengeHabitModalProps) => {
  const { clId } = useParams<any>()

  const [imageSrc, setImageSrc] = useState('')
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [paging, setPaging] = useState({
    total: 0,
    page: 1,
    limit: 10,
  })
  const [listMissions, setListMissions] = useState<UserChallenge[]>([])

  const defaultValue: IChallengeFormDetail = {
    challenge: '',
    challengeInDutch: '',
    description: '',
    descriptionInDutch: '',
    participantsNumber: 0,
    goal: '',
    goalInDutch: '',
  }
  const { control, handleSubmit, setValue } = useForm<IChallengeFormDetail>({
    defaultValues: defaultValue,
    // resolver: zodResolver(addCoreValueSchema.superRefine(checkConfirmPassword)),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const navigate = useNavigate()

  const getChallenge = async () => {
    if (!clId) {
      return
    }
    try {
      setIsLoading(true)
      const response: any = await dispatch(
        getListChallengerByIdAction(+clId)
      ).unwrap()

      setImageSrc(response.data?.media?.url)
      setValue('challenge', response.data?.challenge ?? '')
      setValue('challengeInDutch', response.data?.challengeInDutch ?? '')
      setValue('goalInDutch', response.data?.goal?.goalInDutch ?? 0)
      setValue('goal', response.data?.goal?.goal ?? false)
      setValue('descriptionInDutch', response.data?.descriptionInDutch ?? '')
      setValue('participantsNumber', response.data?.participantsNumber ?? 0)
      setValue('description', response.data?.description ?? '')
    } catch (error: any) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }
  const getListUser = async (params?: IGetGoalParams) => {
    try {
      setIsLoading(true)
      if (clId) {
        const response = await dispatch(
          getListChallengerUsersByIdAction({
            page: params?.page || paging.page,
            limit: 5,
            id: +clId,
          })
        ).unwrap()
        setListMissions(response.data?.items)
        setPaging({
          page: response.data.page,
          limit: response.data.limit,
          total: response.data.total,
        })
      }
    } catch (err) {}

    setIsLoading(false)
  }
  const columns = [
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{ETableTitleAboutUsManagementCoreValue.NO}</p>
          </div>
        )
      },
      dataIndex: 'no',
      align: 'center',
      key: 'no',
      width: '7%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
    },

    {
      title: 'User id',
      dataIndex: 'userId',
      key: 'userId',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '10%',
    },
    {
      title: 'User name',
      dataIndex: 'user',
      key: 'user',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '20%',
      render: (user: IUserChat) => (
        <div className="flex  w-full">
          <p className="m-0 bold">{user.name}</p>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',

      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
    },

    {
      title: 'Progress',
      key: 'status',
      width: '20%',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      render: (goal: UserChallenge) => (
        <div className="flex  w-full">
          <Progress percent={goal.challengeProgress} />
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">
              {ETableTitleAboutUsManagementCoreValue.ACTION}
            </p>
          </div>
        )
      },
      key: 'action',
      dataIndex: 'action',
      width: '15%',
      render: (record: UserChallenge) => (
        <div className="flex space-x-4 items-center justify-center w-full">
          <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
              onClick={() => {
                navigate(
                  `${PATH_CHALLENGE_DETAIL_PARTICIPANTS.replace(
                    ':userId',
                    record.userId.toString() || ''
                  ).replace(':clId', clId || '')}`
                )
              }}
            />
          </Tooltip>
        </div>
      ),
      ellipsis: true,
    },
  ]

  useEffect(() => {
    if (clId) {
      getChallenge()
      getListUser()
    }
  }, [])

  const renderContent = ({
    value,
    name,
    title,
  }: {
    value: string
    name: string
    title: string
  }) => {
    return (
      <div
        className="flex items-start flex-col "
        style={{ marginTop: 10, width: '75%' }}
      >
        <label
          style={{
            fontWeight: '500',
            fontSize: '1.2rem',
          }}
          htmlFor={name}
          className={` inline-flex items-center  mr-[1.25rem]  text-dark col-span-2`}
        >
          {title}
        </label>
        <p className="textDetail mt-3 text-left">{value}</p>
      </div>
    )
  }
  return (
    <SingleBlogStyled className="px-20 bg-white min-h-screen">
      <>
        <div className="flex items-center justify-center ">
          <div>
            <h1 className="mb-5 mt-5 text-[32px] text-center ">
              {clId ? 'Challenge detail' : 'Create new challenge'}
            </h1>
          </div>
        </div>
        <div className="mt-6">
          <form>
            <div className=" flex flex-row gap-2  ">
              <Controller
                name="challenge"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) =>
                  renderContent({
                    value,
                    title: 'Challenge EN',
                    name: 'challenge',
                  })
                }
              />
              <Controller
                name={'challengeInDutch'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) =>
                  renderContent({
                    value,
                    title: 'Challenge NL',
                    name: 'challengeInDutch',
                  })
                }
              />
            </div>
            <div className=" flex flex-row gap-2 mt-5   ">
              <Controller
                name="goal"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) =>
                  renderContent({
                    value,
                    title: 'Goal EN',
                    name: 'goal',
                  })
                }
              />
              <Controller
                name={'goalInDutch'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) =>
                  renderContent({
                    value,
                    title: 'Goal NL',
                    name: 'goalInDutch',
                  })
                }
              />
            </div>
            <div
              className=" flex flex-row gap-2 mt-5  "
              style={{
                justifyContent: 'space-between',
              }}
            >
              <Controller
                name="description"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) =>
                  renderContent({
                    value,
                    title: 'Description EN',
                    name: 'description',
                  })
                }
              />
              <Controller
                name="descriptionInDutch"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) =>
                  renderContent({
                    value,
                    title: 'Description NL',
                    name: 'descriptionInDutch',
                  })
                }
              />
            </div>
            <Controller
              name="participantsNumber"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) =>
                renderContent({
                  value: value.toString(),
                  title: 'Number of joined participants',
                  name: 'participantsNumber',
                })
              }
            />
            <div>
              <p
                className="textDetail mt-3 text-center"
                style={{
                  fontWeight: '500',
                  fontSize: '1.2rem',
                }}
              >
                List of joined participants
              </p>
              {isLoading ? (
                <Skeleton
                  paragraph={{ rows: 4 }}
                  style={{
                    marginTop: 10,
                  }}
                />
              ) : (
                <SharedTable
                  columns={columns as any}
                  dataSource={listMissions?.map((item, index) => {
                    return {
                      ...item,
                      key: item?.userId,
                      no: index + 1,
                      action: item,
                    }
                  })}
                  sticky
                  paginationProps={{
                    total: !!paging.total ? +paging.total : undefined,
                    pageSize: +paging.limit || 10,
                    current: +paging.page,
                    showSizeChanger: false,
                    onChange(page: number) {
                      getListUser({
                        page: +page,
                      })
                    },
                  }}
                />
              )}
            </div>
          </form>
        </div>
      </>
    </SingleBlogStyled>
  )
}

export default DetailChallenge_V2

const SingleBlogStyled = styled('div')(() => {
  return {}
})
