import { ShareSelectInput } from '@components'
import { t } from 'i18next'
import {
  ECourseDifficulty,
  ECourseType,
  EErrorCode,
  ENationalLanguage,
  EStatus,
  ETeacherRole,
} from '@configs'
import {
  clearCourseRequestDetail,
  clearCourseRequestDetailError,
  getAllCategoriesAction,
  getCourseRequestDetail,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Checkbox, Skeleton, Typography } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Input, SwitchButton, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { IAddNewCourse } from 'src/interfaces/course'
import { EMediaCategory, EMediaType } from 'src/interfaces/gallery'
import { getUserChat } from 'src/redux/actions/chat'
import styled from 'styled-components'
import { getAllAuthorsAction } from 'src/redux/actions/blog-news'
import { getListPackageAction } from 'src/redux/actions/package-management'
import { DEFAULT_FORM_FIELD_VALUES, FORM_FIELDS } from './config'
import { ECourseRequestStatus } from 'src/interfaces/course-request-management'
import {
  COURSE_TYPE_OPTIONS,
  RESPONSE_STATUS_CONTENT,
} from 'src/pages/course-request-detail/config'
import { ETeacherStatus } from 'src/pages/teacher-management'
import CourseDescriptionEditor from 'src/pages/course-management/CourseDescriptionEditor'

export default function CourseOverview() {
  const { courseId } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const {
    data: courseDetailData,
    isLoading: isLoadingCourseDetail,
    error,
  } = useAppSelector(
    (state: RootState) => state.courseRequestManagement.courseRequestDetail
  )

  const isViewMode = true
  const [type, setType] = useState(ECourseType.FREE)

  const dispatch = useAppDispatch()
  const categories = useSelector(
    (state: RootState) => state.categories.categories
  )

  const packages = useSelector((state: RootState) => state.package.packages)
  const authors = useSelector((state: RootState) => state.blog.authors)

  const { control, watch, reset, clearErrors, setError, setValue } =
    useForm<IAddNewCourse>({
      defaultValues: DEFAULT_FORM_FIELD_VALUES,
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    })

  const watchTargetUser = watch('targetUser')
  const watchThumbnailUrl = watch('thumbnailId')
  const watchVideoUrl = watch('demoVideoId')
  const categoriesIdsValue = watch('categoriesIds')

  const packageOptions = useMemo(() => {
    if (!packages) return []
    return packages
      .filter((item) => {
        return !(type !== ECourseType.FREE && item.id === 1)
      })
      .map((item) => ({
        label: item.name,
        value: item.id,
      }))
  }, [packages, type])

  const categoryOptions = useMemo(() => {
    if (!Array.isArray(categories) || !categories?.length) return []

    return categories?.map((item, index) => {
      return {
        label: item.name,
        value: item.id,
      }
    })
  }, [categories])

  const subCategoryOptions = useMemo(() => {
    if (!Array.isArray(categories) || !categories?.length) return []

    const selectedCategories = categories.filter((category) =>
      categoriesIdsValue.includes(category.id)
    )

    return selectedCategories?.map((item) => {
      return {
        label: item?.name,
        options: item?.subCategories?.map((it) => ({
          label: it?.name,
          value: it?.id,
        })),
      }
    })
  }, [categories, categoriesIdsValue])

  const authorOptions = useMemo(() => {
    if (!Array.isArray(authors) || !authors?.length) return []

    return authors
      .filter(
        (item: any) =>
          item?.client === ETeacherRole.AUTHORIZED &&
          item?.status === ETeacherStatus.ACTIVE
      )
      .map((item: any) => {
        return {
          label: item?.name,
          value: item?.id,
        }
      })
  }, [authors])

  useEffect(() => {
    dispatch(getAllCategoriesAction())
    dispatch(
      getAllAuthorsAction({
        limit: '1000',
        search: '',
        status: EStatus.ACTIVE,
        types: ETeacherRole.AUTHORIZED,
      })
    )
    dispatch(getUserChat({ page: '1', limit: '1000', search: '' }))
  }, [dispatch])

  const { data: courseRequestDetailData } = useAppSelector(
    (state) => state.courseRequestManagement.courseRequestDetail
  )

  const responseStatusTitle = useMemo(() => {
    const courseRequestStatus = courseRequestDetailData?.status
    const name = courseRequestDetailData?.responseData?.updatedBy?.name || ''

    if (
      [ECourseRequestStatus.ACCEPTED, ECourseRequestStatus.REJECTED].includes(
        courseRequestStatus
      )
    )
      return `${
        RESPONSE_STATUS_CONTENT?.[courseRequestStatus]?.responseTitle || ''
      } ${name}`

    return RESPONSE_STATUS_CONTENT?.[courseRequestStatus]?.responseTitle || ''
  }, [courseRequestDetailData])

  const renderResponseStatus = useCallback(
    (courseRequestStatus) => {
      if (courseRequestStatus === ECourseRequestStatus.PENDING) return

      return (
        <div className="p-0">
          <Typography
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color:
                RESPONSE_STATUS_CONTENT?.[
                  courseRequestStatus as ECourseRequestStatus
                ]?.color,
            }}
          >
            {responseStatusTitle}
          </Typography>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 'bold',
            }}
          >
            {courseRequestDetailData?.responseData?.reason &&
            ![ECourseRequestStatus.ACCEPTED].includes(
              courseRequestDetailData?.status
            )
              ? `Reason: ${courseRequestDetailData?.responseData?.reason}`
              : ''}
          </Typography>
        </div>
      )
    },
    [
      courseRequestDetailData?.responseData?.reason,
      courseRequestDetailData?.status,
      responseStatusTitle,
    ]
  )

  useEffect(() => {
    dispatch(getListPackageAction({ page: 1, limit: 99999 }))
  }, [dispatch])

  useEffect(() => {
    if (!courseId) return
    setIsLoading(true)

    dispatch(getCourseRequestDetail(String(courseId)))
  }, [courseId, dispatch])

  useEffect(() => {
    if (!Object.keys(courseDetailData || {})?.length) return
    setIsLoading(false)

    const { course, user: teacher } = courseDetailData || {}

    const {
      title,
      titleInDutch,
      subTitle,
      description,
      descriptionInDutch,
      type,
      eurPrice,
      usdPrice,
      difficulty,
      categories,
      subCategories,
      hasFinalTest,
      targetUser,
      nationalLanguage,
      isActived,
      isDrafted,
      thumbnail,
      demoVideo,
      seoConfig,
      packages,
      userCreationFrom,
      userCreationTo,
    } = course || {}
    const { slug } = seoConfig || {}

    reset({
      [FORM_FIELDS.TITLE]: title || '',
      [FORM_FIELDS.TITLE_IN_DUTCH]: titleInDutch || '',
      [FORM_FIELDS.SUB_TITLE]: subTitle || '',
      [FORM_FIELDS.SLUG]: slug || '',
      [FORM_FIELDS.DESCRIPTION]: description || '',
      [FORM_FIELDS.DESCRIPTION_IN_DUTCH]: descriptionInDutch || '',
      [FORM_FIELDS.CATEGORY_IDS]: categories?.map((item: any) => item.id) || [],
      [FORM_FIELDS.TYPE]: type || ECourseType.FREE,
      [FORM_FIELDS.SUB_CATEGORY_IDS]:
        subCategories?.map((item: any) => item.id) || [],
      [FORM_FIELDS.DIFFICULTY]: difficulty || ECourseDifficulty.BEGINNER,
      [FORM_FIELDS.EURO_PRICE]: eurPrice || 0,
      [FORM_FIELDS.USD_PRICE]: usdPrice || 0,
      [FORM_FIELDS.USER_ID]: teacher?.name || '',
      [FORM_FIELDS.NATIONAL_LANGUAGE]:
        nationalLanguage || ENationalLanguage.ENGLISH,
      [FORM_FIELDS.HAS_FINAL_TEST]: hasFinalTest || false,
      [FORM_FIELDS.TARGET_USER]: targetUser || false,
      [FORM_FIELDS.IS_ACTIVED]: isActived || false,
      [FORM_FIELDS.THUMBNAIL_ID]: thumbnail?.cloudfrontUrl || '',
      [FORM_FIELDS.DEMO_VIDEO_ID]: demoVideo?.cloudfrontUrl || '',
      [FORM_FIELDS.IS_DRAFTED]: isDrafted || false,
      [FORM_FIELDS.PACKAGE_IDS]: packages?.map((item: any) => item.id) || [],
      [FORM_FIELDS.USER_CREATION_FROM]: userCreationFrom || '',
      [FORM_FIELDS.USER_CREATION_TO]: userCreationTo || '',
    })

    return () => {
      reset()
      dispatch(clearCourseRequestDetail())
    }
  }, [courseDetailData, dispatch, reset])

  useEffect(() => {
    if (error?.code !== EErrorCode.NOT_FOUND) return

    navigate('/404')

    return () => {
      dispatch(clearCourseRequestDetailError())
    }
  }, [dispatch, error?.code, navigate, reset])

  return (
    <>
      {isLoadingCourseDetail || isLoading ? (
        <Skeleton paragraph={{ rows: 4 }} className="p-5" />
      ) : (
        <CreateCourseStyled>
          <div className="blog-content-m-right col-span-12 lg:col-span-4 rounded-lg">
            <div className="intro-y box p-5 bg-white rounded-lg">
              {renderResponseStatus(courseRequestDetailData?.status)}

              <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-center mt-4">
                Course overview
              </h2>

              <div className="blog-content-main intro-y mt-5">
                <div className="grid grid-cols-12 gap-4">
                  <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
                    <div className="col-span-6">
                      <Controller
                        name="title"
                        defaultValue=""
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <TextArea
                              label={t('common:title')}
                              onChange={onChange}
                              value={value}
                              name="title"
                              style={{ width: '100%' }}
                              errors={error?.message}
                              labelClassName="inline-block"
                              disabled={isViewMode}
                              autoSize={{ minRows: 4, maxRows: 16 }}
                              required
                            />
                          )
                        }}
                      />
                    </div>

                    <div className="col-span-6">
                      <Controller
                        name="titleInDutch"
                        defaultValue=""
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <TextArea
                              label={t('common:title_in_nl')}
                              onChange={onChange}
                              value={value}
                              name="titleInDutch"
                              style={{ width: '100%' }}
                              errors={error?.message}
                              labelClassName="inline-block"
                              disabled={isViewMode}
                              autoSize={{ minRows: 4, maxRows: 16 }}
                              required
                            />
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="blog-content-m-left intro-y col-span-12">
                    <Controller
                      name="slug"
                      defaultValue=""
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <TextArea
                            label="Slug"
                            onChange={onChange}
                            value={value}
                            name="slug"
                            style={{ width: '100%' }}
                            errors={error?.message}
                            labelClassName="inline-block"
                            disabled={isViewMode}
                            autoSize={{ minRows: 4, maxRows: 16 }}
                            required
                          />
                        )
                      }}
                    />
                  </div>
                </div>

                <div className="blog-content-main intro-y mt-5">
                  <label>
                    Description{' '}
                    <span className="required text-[#B91C1C] font-bold">*</span>
                  </label>
                  <div className="blog-content-m-left intro-y col-span-12 lg:col-span-8 mb-4">
                    <Controller
                      name="description"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <CourseDescriptionEditor
                            name="description"
                            value={value}
                            clearErrors={clearErrors}
                            setError={setError}
                            setValue={setValue}
                            error={error}
                            disabled={isViewMode}
                          />
                        )
                      }}
                    />
                  </div>

                  <label>
                    Description in Netherlands{' '}
                    <span className="required text-[#B91C1C] font-bold">*</span>
                  </label>
                  <div className="blog-content-m-left intro-y col-span-12 lg:col-span-8">
                    <Controller
                      name="descriptionInDutch"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <CourseDescriptionEditor
                            name="descriptionInDutch"
                            value={value}
                            clearErrors={clearErrors}
                            setError={setError}
                            setValue={setValue}
                            error={error}
                            disabled={isViewMode}
                          />
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-5">
                  <div className="blog-written-by">
                    <Controller
                      name="userId"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            data={authorOptions}
                            value={value}
                            label="Author"
                            placeholder="Select an author"
                            onChange={onChange}
                            errors={error?.message}
                            required
                            disabled={isViewMode}
                            showArrow
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="blog-categories">
                    <Controller
                      name="categoriesIds"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            data={categoryOptions}
                            label="Categories"
                            mode="multiple"
                            placeholder="Select categories"
                            value={value}
                            errors={error?.message}
                            showArrow
                            required
                            disabled={isViewMode}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="blog-tags">
                    <Controller
                      name="subCategoriesIds"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            options={subCategoryOptions as any}
                            label="Sub Category"
                            mode="multiple"
                            placeholder="Select Sub categories"
                            onChange={(e) => {
                              onChange(e)
                            }}
                            value={value}
                            errors={error?.message}
                            showArrow
                            disabled={isViewMode}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="blog-type">
                    <Controller
                      name="type"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            defaultValue={'Blog value'}
                            data={COURSE_TYPE_OPTIONS}
                            label="Type"
                            onChange={(selectedType) => {
                              onChange(selectedType)
                            }}
                            value={value}
                            required
                            disabled={isViewMode}
                            showArrow
                          />
                        )
                      }}
                    />
                  </div>

                  {type === ECourseType.PREMIUM && (
                    <div className="blog-published">
                      <label className="blog-published-label mb-2">
                        Price in EUR
                      </label>
                      <span className="required text-[#B91C1C] font-bold">
                        {' '}
                        *
                      </span>
                      <Controller
                        name="eurPrice"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              name="eurPrice"
                              type="number"
                              onChange={(e) => {
                                e.target.value
                                  ? onChange(parseFloat(e.target.value))
                                  : onChange(0)
                              }}
                              value={value}
                              errors={error?.message}
                              min={0}
                              required
                              disabled={isViewMode}
                            />
                          )
                        }}
                      />
                    </div>
                  )}
                  {type === ECourseType.PREMIUM && (
                    <div className="blog-published">
                      <label className="blog-published-label mb-2">
                        Price in USD
                      </label>
                      <span className="required text-[#B91C1C] font-bold">
                        {' '}
                        *
                      </span>
                      <Controller
                        name="usdPrice"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              name="usdPrice"
                              type="number"
                              onChange={(e) => {
                                e.target.value
                                  ? onChange(parseFloat(e.target.value))
                                  : onChange(0)
                              }}
                              value={value}
                              errors={error?.message}
                              min={0}
                              required
                              disabled={isViewMode}
                            />
                          )
                        }}
                      />
                    </div>
                  )}

                  <div className="blog-type">
                    <Controller
                      name="difficulty"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            defaultValue={'Blog value'}
                            data={[
                              {
                                value: ECourseDifficulty.BEGINNER,
                                label: ECourseDifficulty.BEGINNER,
                              },
                              {
                                value: ECourseDifficulty.MODERATE,
                                label: ECourseDifficulty.MODERATE,
                              },
                              {
                                value: ECourseDifficulty.INTERMEDIATE,
                                label: ECourseDifficulty.INTERMEDIATE,
                              },
                              {
                                value: ECourseDifficulty.ADVANCED,
                                label: ECourseDifficulty.ADVANCED,
                              },
                            ]}
                            label="Difficulty"
                            onChange={onChange}
                            value={value}
                            required
                            disabled={isViewMode}
                            showArrow
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="blog-type">
                    <Controller
                      name="nationalLanguage"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            defaultValue={'English'}
                            data={[
                              {
                                value: ENationalLanguage.ENGLISH,
                                label: t('common:english'),
                              },
                              {
                                value: ENationalLanguage.NETHERLAND,
                                label: t('common:netherland'),
                              },
                            ]}
                            label="National language"
                            onChange={onChange}
                            value={value}
                            required
                            disabled={isViewMode}
                            showArrow
                          />
                        )
                      }}
                    />
                  </div>

                  <div className="blog-type">
                    <Controller
                      name="subTitle"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            name="subTitle"
                            defaultValue={''}
                            data={[
                              {
                                value: '',
                                label: '',
                              },
                              {
                                value: ENationalLanguage.ENGLISH,
                                label: t('common:english'),
                              },
                              {
                                value: ENationalLanguage.NETHERLAND,
                                label: t('common:netherland'),
                              },
                            ]}
                            showArrow
                            label="Subtitle"
                            onChange={(value) =>
                              !!value ? onChange(value) : onChange(null)
                            }
                            value={value ?? ''}
                            disabled={isViewMode}
                          />
                        )
                      }}
                    />
                  </div>

                  <div className="blog-type"></div>

                  {courseId && (
                    <div className="blog-published">
                      <div>
                        <label className="blog-show-author-label mb-2">
                          Inactive / Active
                        </label>
                      </div>
                      <Controller
                        name="isActived"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <SwitchButton
                              size="default"
                              onChange={onChange}
                              checked={value}
                              disabled={isViewMode}
                            />
                          )
                        }}
                      />
                    </div>
                  )}
                  {courseId && (
                    <div className="blog-published">
                      <div>
                        <label className="blog-show-author-label mb-2">
                          Publish
                        </label>
                      </div>
                      <Controller
                        name="isDrafted"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <SwitchButton
                              size="default"
                              onChange={(value) => onChange(!value)}
                              checked={!value}
                              disabled={isViewMode}
                            />
                          )
                        }}
                      />
                    </div>
                  )}

                  <div className="blog-published !h-fit">
                    <span className="flex items-center gap-4">
                      <Controller
                        name="hasFinalTest"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Checkbox
                              name="hasFinalTest"
                              onChange={(e) => {
                                onChange(e.target.checked)
                              }}
                              checked={value}
                              className="!w-fit !h-fit !font-normal"
                              disabled={isViewMode}
                            >
                              {t('section:have_final_test')}
                            </Checkbox>
                          )
                        }}
                      />
                    </span>
                    <div>
                      <span className="flex items-center gap-4">
                        <Controller
                          name="targetUser"
                          control={control}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <Checkbox
                                name="targetUser"
                                onChange={(e) => {
                                  onChange(e.target.checked)
                                }}
                                checked={value}
                                className="!w-fit !h-fit !font-normal"
                                disabled={isViewMode}
                              >
                                Target user
                              </Checkbox>
                            )
                          }}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="blog-published"></div>

                  {watchTargetUser && (
                    <div className="blog-published col-span-2 grid grid-cols-2 gap-4 mb-4">
                      <div className="blog-type">
                        <Controller
                          name="packageIds"
                          control={control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => {
                            return (
                              <ShareSelectInput
                                data={packageOptions}
                                label="Packages"
                                mode="multiple"
                                placeholder="Select packages"
                                value={value}
                                errors={error?.message}
                                showArrow
                                required={watchTargetUser}
                                disabled={isViewMode}
                              />
                            )
                          }}
                        />
                      </div>
                      <div className="flex items-start justify-between gap-4">
                        <div className="w-full">
                          <Controller
                            name="userCreationFrom"
                            control={control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  containerClassName="w-full !flex !flex-col items-start !gap-[0px]"
                                  type="date"
                                  label="User creation from"
                                  alignment="col"
                                  errors={error?.message}
                                  onChangeDate={onChange}
                                  value={value}
                                  disabled={isViewMode}
                                />
                              )
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <Controller
                            name="userCreationTo"
                            control={control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  containerClassName="w-full !flex !flex-col items-start !gap-[0px]"
                                  type="date"
                                  label="User creation to"
                                  alignment="col"
                                  errors={error?.message}
                                  onChangeDate={onChange}
                                  value={value}
                                  disabled={isViewMode}
                                />
                              )
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="blog-published">
                    <div className="wrapper-thumbnail-section mb-2 flex justify-between items-center">
                      <label className="blog-add-thumbnail-label">
                        Thumbnail
                        <span className="required text-[#B91C1C] font-bold">
                          {' '}
                          *
                        </span>
                      </label>
                    </div>

                    <Controller
                      name={'thumbnailId'}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <>
                            <CustomDragger
                              containerClassName="mt-[10px]"
                              name="thumbnailId"
                              id="thumbnailId"
                              required
                              errors={error?.message}
                              multiple={false}
                              initResource={String(watchThumbnailUrl)}
                              allowFileTypes={[
                                'image/png',
                                'image/jpeg',
                                'image/jpg',
                              ]}
                              uploadType={EMediaType.IMAGE as any}
                              uploadCategory={EMediaCategory.COURSE}
                              disabled={isViewMode}
                            />
                          </>
                        )
                      }}
                    />
                  </div>
                  <div className="blog-published">
                    <div className="wrapper-thumbnail-section mb-2 flex justify-between items-center">
                      <label className="blog-add-thumbnail-label">
                        Demo Video
                        <span className="required text-[#B91C1C] font-bold">
                          {' '}
                          *
                        </span>
                      </label>
                    </div>

                    <Controller
                      name={'demoVideoId'}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <>
                            <CustomDragger
                              containerClassName="mt-[10px]"
                              name="demoVideoId"
                              id="demoVideoId"
                              required
                              errors={error?.message}
                              multiple={false}
                              initResource={String(watchVideoUrl)}
                              allowFileTypes={[
                                'video/mp4',
                                'video/webm',
                                'video/m4v',
                                'video/mov',
                                'video/qt',
                              ]}
                              uploadType={EMediaType.VIDEO as any}
                              uploadCategory={EMediaCategory.COURSE}
                              disabled={isViewMode}
                            />
                          </>
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CreateCourseStyled>
      )}
    </>
  )
}

const CreateCourseStyled = styled('div')(() => {
  return {
    label: {
      fontWeight: 'bold',
    },
    '.label': {
      fontWeight: 'bold',
    },
    '.ant-picker-disabled': {
      background: '#fafafa !important',
    },
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },

    '.ant-upload.ant-upload-select-picture-card': {
      width: '100%',
      height: 'auto',
    },
  }
})
