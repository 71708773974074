import { ECourseType, PATH_COURSE_MANAGEMENT } from '@configs'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { message, Skeleton } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ELessonType,
  ICourseSections,
  ILesson,
  ITrailLesson,
} from 'src/interfaces/course'
import { t } from 'i18next'
import {
  getCourseDetailAction,
  getCourseSectionDetailAction,
  updateSectionTrialLessonsAction,
} from 'src/redux/actions/course'
import { EmptyLesson } from './EmptyLesson'
import ModalAddSection from './ModalAddSection'

import Section from 'src/pages/course-management/components/sections/Section'
import { SectionProvider } from 'src/pages/course-management/provider/section/SectionProvider'
import ModalFinalTest from 'src/pages/course-management/form/final-test/ModalFinalTest'
import ModalMedia from './media/MediaModal'
import { Button } from 'src/common'

interface ILessonManagementProps {
  activeTab?: string
}

export function LessonManagement({ activeTab }: ILessonManagementProps) {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { selectedCourse } = useAppSelector((state: RootState) => state.course)

  const [listSections, setListSections] = useState<ICourseSections[]>([])
  const [reset, setReset] = useState(false)
  const [sectionId, setSectionId] = useState<number | undefined>(undefined)
  const [isActive, setIsActive] = useState(true)
  const [section, setSection] = useState<ICourseSections | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [openEditSection, setOpenEditSectionModal] = useState<boolean>(false)
  const [courseType, setCourseType] = useState<ECourseType>(ECourseType.FREE)
  const [trialLessonList, setTrialLessonList] = useState<ITrailLesson[]>([])
  const [hasFinalTest, setHasFinalTest] = useState(false)

  const onCloseEditSection = () => {
    setOpenEditSectionModal(false)
    setSection(undefined)
  }

  const [openEditLesson, setOpenEditLessonModal] = useState<boolean>(false)
  const [lesson, setLesson] = useState<any>(undefined)
  const onCloseEditLesson = () => {
    setOpenEditLessonModal(false)
    setSection(undefined)
  }

  const onReset = () => {
    setReset(!reset)
    setLesson(undefined)
  }
  const getSections = useCallback(
    async (id: number) => {
      try {
        setIsLoading(true)
        const response = await dispatch(
          getCourseSectionDetailAction(id)
        ).unwrap()
        setListSections(response.data)
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch]
  )

  const listLessonRender = useMemo(() => {
    if (!hasFinalTest) {
      const renderList = listSections.filter((item) => !item.isFinal)
      return renderList
    }
    return listSections
  }, [hasFinalTest, listSections])

  const getCourseDetail = useCallback(async () => {
    if (!selectedCourse || !Object.keys(selectedCourse)?.length) {
      navigate('/course-management')
      return
    }

    setIsActive(selectedCourse?.isDrafted)
    setCourseType(selectedCourse?.type as ECourseType)
    setHasFinalTest(selectedCourse?.hasFinalTest)
  }, [navigate, selectedCourse])

  // function hasFinalTestLesson(array: ICourseSections[]) {
  //   for (let i = 0; i < array.length; i++) {
  //     const lessons = array[i].lessons
  //     for (let j = 0; j < lessons.length; j++) {
  //       if (lessons[j].type === ELessonType.FINAL_TEST) {
  //         return true
  //       }
  //     }
  //   }
  //   return false
  // }

  const handleChangeTrial = async () => {
    if (!id) return
    const data = {
      body: trialLessonList,
      sectionId: listSections[0].id,
    }
    try {
      const response = await dispatch(
        updateSectionTrialLessonsAction(data)
      ).unwrap()
      if (response.data) {
        message.success(t('common:set_trail_success'))
      }
    } catch (error: any) {
      message.error(error.message)
    }
  }

  const canShowBtnSaveTrial = useMemo(() => {
    const firstSection = listSections?.filter((it) => !it?.isFinal)?.[0]

    return firstSection?.lessons?.length > 0 && courseType !== ECourseType.FREE
  }, [courseType, listSections])

  useEffect(() => {
    if (!id) return

    getSections(Number(id))
    getCourseDetail()
  }, [getCourseDetail, getSections, id, reset])

  return (
    <>
      <div
        className={`w-full min-h-[82vh] justify-start flex  border-1 flex-col  rounded-lg bg-white ${
          !listLessonRender.length && 'bg-white'
        }`}
      >
        {isLoading ? (
          <Skeleton paragraph={{ rows: 4 }} className="p-5" />
        ) : (
          <>
            {!listLessonRender.length && (
              <div className="flex justify-center m-auto">
                <EmptyLesson onReset={onReset} />
              </div>
            )}
            {!!listLessonRender.length && (
              <div className="">
                {isActive && (
                  <div className="algin-baseline m-5">
                    <EmptyLesson
                      isNotEmpty
                      isActive={isActive}
                      onReset={onReset}
                    />
                  </div>
                )}
                {listLessonRender.map((item, index) => (
                  <SectionProvider
                    key={item.id}
                    listSectionsLength={listLessonRender.length}
                    sectionIndex={index}
                    sectionId={item.id}
                    onReset={onReset}
                  >
                    <Section
                      item={item}
                      index={index}
                      courseType={courseType}
                      onSwitchTrial={setTrialLessonList}
                      onCloseEditSection={onCloseEditSection}
                      onOpenEditSection={(item: ICourseSections) => {
                        setSection(item)
                        setOpenEditSectionModal(true)
                      }}
                      isActive={isActive}
                      onReset={onReset}
                      onEditLesson={(item: ILesson, sectionId: number) => {
                        setSectionId(sectionId)
                        setOpenEditLessonModal(true)
                        setLesson({
                          type: item.type,
                          id: item.id,
                        })
                      }}
                      hasTrialLesson={index === 0}
                    />
                  </SectionProvider>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      {canShowBtnSaveTrial ? (
        <div className="w-full flex items-center mt-5 mb-5 justify-center gap-3">
          <Button
            type="default"
            size="middle"
            className="submit__btn login-btn"
            loading={false}
            onClick={() => navigate(PATH_COURSE_MANAGEMENT)}
          >
            {t('common:cancel')}
          </Button>

          <Button
            htmlType="submit"
            type="primary"
            size="middle"
            className="submit__btn login-btn"
            loading={false}
            onClick={handleChangeTrial}
          >
            {t('common:save')}
          </Button>
        </div>
      ) : null}

      <ModalAddSection
        open={openEditSection}
        onClose={onCloseEditSection}
        isLoading={true}
        section={section}
        onSave={onReset}
        isActive={isActive}
      />

      {lesson && (
        <>
          {lesson?.type === ELessonType.VIDEO && (
            <ModalMedia
              open={openEditLesson}
              onClose={onCloseEditLesson}
              assignmentId={lesson.id}
              selectSection={sectionId}
              onSave={onReset}
              isActive={isActive}
            />
          )}
          {/* {lesson?.type === ELessonType.AUDIO && (
            <ModalMedia
              open={openEditLesson}
              onClose={onCloseEditLesson}
              assignmentId={lesson.id}
              selectSection={sectionId}
              onSave={onReset}
              type={ELessonType.AUDIO}
              isActive={isActive}
            />
          )} */}
          {/* {(lesson?.type === ELessonType.ASSIGNMENT_EXERCISE ||
            lesson?.type === ELessonType.ASSIGNMENT_TEST) && (
            <ModalAssignment
              open={openEditLesson}
              onClose={onCloseEditLesson}
              assignmentId={lesson.id}
              selectSection={sectionId}
              onSave={onReset}
              isActive={isActive}
            />
          )} */}
          {lesson?.type === ELessonType.FINAL_TEST && (
            <ModalFinalTest
              open={openEditLesson}
              onClose={onCloseEditLesson}
              finalTestId={lesson.id}
              selectSection={sectionId}
              onSave={onReset}
              isActive={isActive}
            />
          )}
        </>
      )}
    </>
  )
}
