/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { message } from 'antd'
import Cookies from 'js-cookie'

import { store, authActions } from 'src/redux'
import { LogApp } from '@utils'
import { PATH_LOGIN } from '../routes/navigation'
import {
  CLIENT_HEADER_ADMIN,
  CLIENT_HEADER_TEACHER,
  CLIENT_IN_HEADER_AXIOS,
  LLC_ACCESS_TOKEN,
} from '@configs'
import { BaseResponseProps } from '../interfaces'

const queryString = require('query-string')

const CancelToken = axios.CancelToken
const source = CancelToken.source()
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,

  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    charset: 'UTF-8',
    'Access-Control-Allow-Origin': '*',
  },
  paramsSerializer: (params) => queryString.stringify(params),
})

axiosClient.interceptors.request.use((config: any) => {

  store.dispatch(authActions.setIsCallingApi(true))
  const token = Cookies.get(LLC_ACCESS_TOKEN)
  config.headers['Authorization'] = `Bearer ${token}`
  // config.headers["Accept-Encoding"] = `gzip, deflate, br`;
  // config.headers["x-csrf-token"] = token;

  // temporarily set Client = 'admin,teacher_llc'
  config.headers['Client'] = !!config.headers['Client']
    ? config.headers['Client']
    : `${CLIENT_HEADER_ADMIN},${CLIENT_HEADER_TEACHER}`

  delete axios.defaults.headers.common['Accept-Encoding']
  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    store.dispatch(authActions.setIsCallingApi(false))
    if (response && response.data && response.request.responseType !== 'blob') {
      return response.data
    }
    return response
  },
  (error: AxiosError<any, any>) => {
    store.dispatch(authActions.setIsCallingApi(false))
    if (error.response && error.response.status === 401) {
      //logout
      Cookies.remove(LLC_ACCESS_TOKEN)
      sessionStorage.clear()
      store.dispatch(authActions.logout())
      window.location.replace(PATH_LOGIN)
    }
    if (error.response) {
      LogApp('aewr', error.response.data)
      // Request made and server responded
      throw error.response.data
    } else if (error.request) {
      // The request was made but no response was received
      LogApp(error.request)
      message.error({
        content: 'Oops, something went wrong',
      })
    } else {
      // Something happened in setting up the request that triggered an Error
      LogApp('Error', error.message)
    }
    throw error
  }
)

export const ApiClient = {
  get: <T>(url: string, config?: AxiosRequestConfig<any>) =>
    axiosClient.get<T, BaseResponseProps<T>>(url, config),
  post: <T, D = any>(
    url: string,
    payload?: D,
    config?: AxiosRequestConfig<any>
  ) => axiosClient.post<T, BaseResponseProps<T>, D>(url, payload, config),
  put: <T, D = any>(url: string, payload?: D) =>
    axiosClient.put<T, BaseResponseProps<T>, D>(url, payload),
  delete: <T, D = any>(url: string, payload?: D) =>
    axiosClient.delete<T, BaseResponseProps<T>, D>(url, { data: payload }),
  patch: <T, D = any>(url: string, payload?: D) =>
    axiosClient.patch<T, BaseResponseProps<T>, D>(url, payload),
}

export default axiosClient
